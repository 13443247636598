export default function PageRestaurante() {
    return (
      <div className="bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <div className="sm:ml-6 text-center">
                <h1 className="text-3xl font-extrabold text-primaryFont tracking-tight sm:text-5xl mt-28">Restaurante hacienda del pedregal</h1>
            </div>
          </main>
        </div>
      </div>
    )
  }