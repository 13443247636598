import { types } from "../types/types";

export const inputChangeBuscar = (state = { search:{ value:'', fetch: false, min: 3, clean: true}}, action) => {
    switch (action.type) {
        case types.ChangeBuscar:
            return {
                search: action.payload,
            }
        default:
            return state;
    }
}
