import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ListIcons, IconBed, IconCalendar } from '../common/icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { store } from "../../app/redux/store/store";
import { menuActive } from "../../app/redux/actions/menu";
import {
  ChartBarIcon,
  MenuIcon,
  XIcon,
  CogIcon,
  UserAddIcon,
  UserGroupIcon,
  LockClosedIcon,
  CurrencyDollarIcon,
  CreditCardIcon,
  CashIcon
} from '@heroicons/react/outline';
import storage from "redux-persist/lib/storage";
import Button from "../common/Button";
import WithPermission from '../../app/WithPermission';

const menu = [
  {
    name: 'Reservas',
    href: '/reservaciones',
    icon: ListIcons,
    permiso: 'reserva-list'
  },
  {
    name: 'Calendario',
    href: '/calendario',
    icon: IconCalendar,
    permiso: 'reserva-list'
  },
  {
    name: 'Pagos',
    href: '/pagos',
    icon: CurrencyDollarIcon,
    permiso: 'reserva-list'
  },
  {
    name: 'Reportes',
    href: '/reportes',
    icon: ChartBarIcon,
    permiso: 'report-list'
  },
  {
    name: 'Cortes',
    href: '/cortes-hotel',
    icon: CreditCardIcon,
    permiso: 'reserva-list'
  },
  {
    name: 'Gastos',
    href: '/gastos-hotel?hotel=true',
    icon: CashIcon,
    permiso: 'reserva-list'
  },
]

const menuAdmin = [
  {
    name: 'Clientes',
    href: '/clientes',
    icon: UserGroupIcon,
    permiso: 'customer-list'
  },
  {
    name: 'Habitaciones',
    href: '/habitaciones',
    icon: IconBed,
    permiso: 'room-list'
  },
  {
    name: 'Usuarios',
    href: '/usuarios',
    icon: UserAddIcon,
    permiso: 'user-list'
  },
  {
    name: 'Roles',
    href: '/roles',
    icon: LockClosedIcon,
    permiso: 'role-list'
  },
]

export default function MenuHotel() {
  const loginState = useSelector((state) => state?.loginReducer?.data);
  //const searchText = useSelector((state) => state?.inputChangeBuscar?.search);
  const [hidden, setHidden] = useState(true);
  //const query = new URLSearchParams(window.location.search);

  const cerrarSesion = () => {
    storage.removeItem("persist:root");
    storage.removeItem("token");
    window.location = "/";
  };

  // const KeyDownHandler = (e) => {
  //   if (e.key === 'Enter') {
  //     if (searchText?.value === '') {
  //       store.dispatch(ChangeBuscar({ ...searchText, fetch: false, clean: true }));
  //     }
  //     else {
  //       store.dispatch(ChangeBuscar({ ...searchText, fetch: true, clean: false }));
  //     }
  //   }
  // }

  // const ChangeHandler = (e) => {
  //   let value = {
  //     value: e.target.value,
  //     min: 3,
  //     fetch: false,
  //     clean: false
  //   };

  //   store.dispatch(ChangeBuscar(value));
  // }

  return (
    <div>
      <Popover className="relative ">
        <div className="bg-primary">
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between max-w-7xl mx-auto justify-between items-center px-4 py-3">
            <a href="/" className="text-base text-white font-medium" onClick={() => {
              store.dispatch(menuActive(''));
            }}>
              Hotel hacienda del pedregal
            </a>
            <div className="flex items-center md:ml-12">
              {/* <input
                  id="search-field"
                  className="bg-blueSecond  placeholder rounded-lg text-xs py-2 px-3"
                  placeholder="Buscar"
                  type="search"
                  onChange={ChangeHandler}
                  onKeyDown={KeyDownHandler}
                  name="search"
                  autoComplete={"Off"}
                  value={searchText?.value}
                /> */}
              <div className="ml-3 flex flex-row items-center bg-blueSecond py-1 px-5 rounded-lg hover:bg-secondary">
                <span className="inline-flex items-center justify-center h-6 w-6 ring-2 ring-white rounded-full bg-blueSecond">
                  <span className="text-sm font-medium leading-none text-white">
                    {loginState?.userData?.email.substring(0, 2).toUpperCase()}
                  </span>
                </span>
                <div className="flex flex-col items-start  justify-center ml-2">
                  <h1 className="text-xs font-bold text-white">
                    {`${loginState?.userData?.email} `}
                  </h1>
                  <span className="text-xs font-bold text-white cursor-pointer" onClick={() => { cerrarSesion() }}>
                    cerrar sesion
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-0 shadow z-30 pointer-events-none" />
        <div className="px-4 xl:px-44 lg:px-40 md:px-32 flex justify-between xl:justify-center lg:justify-center md:justify-center items-center py-4 bg-blueSecond">
          <div>
            <span></span>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center
             text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:ring-2 focus:ring-primary">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" />
            </Popover.Button>
          </div>
          <div className="hidden xl:flex lg:flex md:flex md:items-center md:justify-between -my-3">

            {menu.map((item) => (
              <WithPermission permiso={item.permiso} key={item.name} >
                <Link to={item.href} className="rounded-lg">
                  <div className="flex flex-col justify-center items-center hover:bg-secondary cursor-pointer py-1 px-12 rounded-lg">
                    <div className="flex flex-col">
                      <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-primary text-white">
                        <item.icon className="h-6 w-6" />
                      </span>
                    </div>
                    <p className="text-sm font-medium text-white">{item.name}</p>
                  </div>
                </Link>
              </WithPermission>
            ))}
            <Popover>
              {() => (
                <>
                  <Popover.Button onClick={() => { setHidden(false) }}>
                    <div className="flex flex-col justify-center items-center hover:bg-secondary cursor-pointer py-1 px-5 rounded-lg">
                      <div className="flex flex-col">
                        <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-primary text-white">
                          <CogIcon className="h-6 w-6" />
                        </span>
                      </div>
                      <p className="text-sm font-medium text-white">Administracion</p>
                    </div>
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 -translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-1"
                  >
                    <Popover.Panel className={`${hidden ? 'hidden' : 'absolute z-50 top-full inset-x-0 transform shadow-lg'}`}>
                      <div className="grid grid-cols-1">
                        <nav className="py-2 bg-white flex flex-row justify-center">
                          {menuAdmin.map((item) => (
                            <WithPermission permiso={item.permiso} key={item.name}>
                              <Link to={item.href} className="rounded-lg" onClick={() => { setHidden(true) }}>
                                <div className="flex flex-col justify-center items-center hover:bg-gray-100 cursor-pointer py-2 px-12 rounded-lg">
                                  <div className="flex flex-col">
                                    <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-primary text-white">
                                      <item.icon className="h-6 w-6" />
                                    </span>
                                  </div>
                                  <p className="text-base font-medium text-gray-900">{item.name}</p>
                                </div>
                              </Link>
                            </WithPermission>
                          ))}
                        </nav>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5 sm:pb-8">
                <div className="flex items-center justify-between">
                  <span className='font-medium text-primary'>Hacienda del pedregal</span>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-400">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6 sm:mt-8">
                  <nav>
                    <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                      {menu.map((item) => (
                        <WithPermission permiso={item.permiso} key={item.name}>
                          <Link to={item.href} className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50"  >
                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-primary text-white sm:h-12 sm:w-12">
                              <item.icon className="h-6 w-6" />
                            </div>
                            <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                          </Link>
                        </WithPermission>
                      ))}
                    </div>
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5">
                <Button
                  text="Cerrar sesion"
                  onClick={() => { cerrarSesion() }}
                />
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}