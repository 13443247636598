const Contenedor = (props) => {
  return (
    <div className="lg:pt-2 sm:pt-2 xl:pb-20">
      <div className="mx-0 xl:mx-2 lg:mx-2 md:mx-2 sm:mx-2">
        <div className="bg-white shadow-md hover:shadow-xl border">
          <div className="p-3 xl:p-5 lg:p-5 md:p-4">
            <h1 className="text-xl xl:text-2xl lg:text-xl md:text-lg font-extrabold tracking-tight text-primary ">{props.title}</h1>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contenedor;