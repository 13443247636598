import { Formik } from "formik";
import { Reservar } from "../../../hotel/calendario/reservar";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
});

export const Detalle = (props) => {
  const registro_reserva = {
    reserva: {
      cliente: {
        id: props.registro.id
      }
    }
  };

  console.log(props.registro)

  return (
    <Formik
      initialValues={{
        estado: "",
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {

      }}
    >

      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <div className="">
          <div className="bg-white border rounded-md mb-2">
            <div className=" border-gray-200 p-0">
              <div className="sm:divide-y sm:divide-gray-200 ">
                <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                  <div className="text-xs font-medium text-gray-500">
                    Nombre
                  </div>
                  <div className="text-xs text-gray-900 sm:col-span-2">
                    {props?.registro?.nombre} {props?.registro?.apellido}
                  </div>
                </div>
                <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                  <div className="text-xs font-medium text-gray-500">DPI</div>
                  <div className="text-xs text-gray-900 sm:col-span-2">
                    {props?.registro?.dpi}
                  </div>
                </div>
                <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                  <div className="text-xs font-medium text-gray-500">
                    Celular
                  </div>
                  <div className="text-xs text-gray-900 sm:col-span-2">
                    {props?.registro?.celular}
                  </div>
                </div>
                <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                  <div className="text-xs font-medium text-gray-500">
                    Correo
                  </div>
                  <div className="text-xs text-gray-900 sm:col-span-2">
                    {props?.registro?.correo}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Reservar
            registro={registro_reserva}
            idHabitacion={props.registro.id}
            editar={true}
          />
        </div>
      )}
    </Formik>
  );
};
