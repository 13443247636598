import { ErrorMessage } from "formik";
import Label from "./Label";

export default function TextArea(props) {
    return(
     <div>
       <Label text={props.label} />
        <textarea
        name={props.name}
        id={props.id}
        autoComplete="off"
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        required={props.required || false}
        disabled={props.disabled || false}
        placeholder={props.placeholder}
        rows={props.rows}
        cols={props.cols}
        className={`mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
        focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
         text-xs border-maivi-gray-10 rounded-md text-maivi-primaryFont ${props.className}`}
      ></textarea>
       {props.validacion && (
        <ErrorMessage
          name={props.name}
          component="span"
          className="text-xs text-red-600"
        />
      )}
     </div>
    )
}