import Label from "./Label";
import { ErrorMessage } from "formik";

export default function Select(props) {
  return (
    <div>
      <Label text={props.label} />
      <select
        autoFocus={props.autoFocus || false}
        name={props.name}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        disabled={props.disabled || false}
        onBlur={props?.onBlur}
        className={`mt-1 shadow-sm border cursor-pointer
         focus:border-transparent block w-full text-xs rounded-md bg-white text-primaryFont ${props.className}`}
      >
      <option value={props.valueInit}>{props.selectInit}</option>  
      {props.list}
      </select>
      
      {props.validacion && (
        <ErrorMessage
          name={props.name}
          component="span"
          className="text-xs text-red-500"
        />
      )}
    </div>
  );
};
