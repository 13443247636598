/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customGet, customPost } from "../../../utils/services";
import { Tab } from "@headlessui/react";
import { PlusCircleIcon, MinusCircleIcon, TrashIcon, ClipboardCheckIcon } from "@heroicons/react/solid";
import { Formik } from "formik";
import ModalSmall from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import Button from "../../../components/common/Button";
import toast from "react-hot-toast";
import WithPermission from "../../../app/WithPermission";
import Input from "../../../components/common/Input";
import Table from "../../../components/common/Table";
import Check from "../../../components/common/Check";
import Select from "../../../components/common/Select";
import * as Yup from "yup";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const validaciones = Yup.object().shape({
  vendedor: Yup.string().required("vendedor es requerido"),
});

export const TableRestauranteVentas = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [menus, setMenus] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [categoriaActiva, setCategoriaActiva] = useState("1");
  const [detalleVenta, setDetalleVenta] = useState([]);
  const [registro, setRegistro] = useState("");
  const [showImagen, setShowImagen] = useState(false);
  const [showOpciones, setShowOpciones] = useState(false);
  const [listadoOpciones, setListado] = useState(false);
  const [vendedores, setVendedores] = useState([]);

  useEffect(() => {
    customGet(undefined, "menus/create", token).then((data) => {
      setCategorias(data.categorias);
    });
  }, []);

  useEffect(() => {
    customGet(undefined, "catalogos/vendedores", token).then((data) => {
      setVendedores(data);
    });
  }, []);

  useEffect(() => {
    customPost(undefined, "catalogos/menus", token).then((data) => {
      data.data.forEach(element => {
        element.cantidad = 1;
      });
      setMenus(data.data);
    });
  }, []);

  return (
    <div className="grid grid-cols-12 gap-1 xl:gap-3 lg:gap-3 md:gap-2 p-0 xl:p-2 lg:p-2 md:p-0">
      <div className="col-span-12 xl:col-span-8 lg:col-span-9 md:col-span-7 border rounded-lg p-3 hover:shadow-md">
        <WithPermission permiso="venta-list">
          <Tab.Group>
            <Tab.List className="flex rounded-xl bg-primaryOpacity p-1">
              {categorias.map((category) => (
                <Tab
                  key={category.nombre}
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-primaryFont",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white shadow"
                        : "text-primaryFont hover:bg-white/[0.12] "
                    )
                  }
                  onClick={() => {
                    setCategoriaActiva(category.id);
                  }}
                >
                  {category.nombre}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-1 altura-menus">
              {categorias.map((cat) => (
                <Tab.Panel
                  key={cat.nombre}
                  className={classNames(
                    "grid grid-cols-12 gap-4 rounded-lg bg-white p-0 xl:p-2 lg:p-2 md:p-1",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                  )}
                >
                  {menus
                    .filter((x) => x.categoria_id === Number(categoriaActiva))
                    .map((item) => (
                      <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6 rounded-lg shadow-sm border hover:shadow-xl
                    cursor-pointer">
                        <div className="bg-blue-50 rounded-lg" onClick={() => {
                          setRegistro(item);
                          setShowImagen(true);
                        }}>
                          <img
                            className="h-48 w-full object-contain rounded-lg "
                            src={item.imagen}
                            alt=""
                          />
                        </div>
                        <div className="flex flex-1 flex-col justify-between bg-white p-3">
                          <div>
                            <p className="text-base font-semibold text-primaryFont">
                              {item.nombre}
                            </p>
                            <p className="text-sm font-medium text-indigo-600">
                              <a className="hover:underline">Q{item.precio}</a>
                            </p>
                            <a className="block">
                              <p className="mb-2 text-xs text-gray-500">
                                {item.descripcion}
                              </p>
                            </a>
                          </div>
                          <div className="flex flex-row justify-between items-center">
                            <div className="flex flex-row">
                              <button
                                type="button"
                                className="tooltip"
                                onClick={() => {
                                  menus.forEach(element => {
                                    if (element.id === item.id) {
                                      element.cantidad = element.cantidad - 1;
                                    }
                                  });
                                  setMenus([...menus]);
                                }}
                              >
                                <MinusCircleIcon className="h-7 w-7 text-secondary hover:text-secondary cursor-pointer" />
                                <span className="tooltiptext">Reducir</span>
                              </button>
                              <span className="mx-1 font-bold text-lg text-primaryFont"> {item.cantidad}</span>
                              <button
                                type="button"
                                className="tooltip"
                                onClick={() => {
                                  menus.forEach(element => {
                                    if (element.id === item.id) {
                                      element.cantidad = element.cantidad + 1;
                                    }
                                  });

                                  setMenus([...menus]);
                                }}
                              >
                                <PlusCircleIcon className="h-7 w-7 text-secondary hover:text-secondary cursor-pointer" />
                                <span className="tooltiptext">Agregar</span>
                              </button>
                            </div>
                            <div className="flex flex-row">
                              {/* <button
                              type="button"
                              onClick={() => {
                                setRegistro(item);
                                let arr = item.opciones.split("\"");
                                setListado(arr.filter(x => x.length > 1));
                                setShowOpciones(true);
                              }}
                            >
                              <ClipboardCheckIcon className="h-7 w-7 text-secondary hover:text-secondary cursor-pointer" />
                            </button> */}
                            </div>

                          </div>
                        </div>
                        <div className="flex-row px-3 mb-3">
                          <Button
                            text="Agregar"
                            className=""
                            onClick={() => {
                              let row = {
                                id: item.id,
                                cantidad: item.cantidad,
                                nombre: item.nombre,
                                precio: item.precio,
                                opciones: item.opciones
                              }
                              if (item.cantidad > 0) {
                                setDetalleVenta([...detalleVenta, row]);
                              } else {
                                toast.error("debe de agregar por lo menos un plato")
                              }
                            }}
                          />

                        </div>
                      </div>
                    ))}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </WithPermission>

      </div>
      <div className="col-span-12 xl:col-span-4 lg:col-span-3 md:col-span-5 border rounded-lg p-3 hover:shadow-md">
        <Formik
          initialValues={{
            observacion: "",
            mesa: "",
            habitacion: "",
            vendedor: "",
            vendedor_id: 0
          }}
          validationSchema={validaciones}
          onSubmit={async (values, { resetForm }) => {
            let detalle = [];

            if (detalleVenta.length === 0) {
              toast.error("Debe de agregar platos a la orden.")
            } else if (values.habitacion === "" && values.mesa === "" && values.descripcion === "") {
              toast.error("Debe de indicar una observacion, una mesa o una habitacion");
            }

            else {
              detalleVenta.forEach(element => {
                let det = {
                  menu_id: element.id,
                  cantidad: element.cantidad,
                  opciones: element.opciones
                }

                detalle.push(det);
              });

              values.detalle = detalle;

              await customPost(values, 'ventas', token)
                .then(async (data) => {
                  if (data?.status >= 200 && data?.status <= 210) {
                    toast.success(data?.data?.msg);
                    resetForm({});
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  }
                })
                .catch(function (error) {
                  if (error.response.status >= 400 && error.response.status <= 450) {
                    toast.error(error.response.data.error);
                  } else if (error.response.status >= 500) {
                    toast.error("Error interno comuniquese con el administrador");
                  }
                });
            }
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <div className="grid grid-cols-12 gap-2 mb-14">
              <div className="col-span-12">
                <Input
                  type="text"
                  name="observacion"
                  value={values.observacion}
                  onChange={handleChange}
                  validacion={true}
                  label="Observacion"
                />
              </div>
              <div className="col-span-12">
                <Input
                  type="text"
                  name="mesa"
                  value={values.mesa}
                  onChange={handleChange}
                  validacion={true}
                  label="Mesa"
                />
              </div>
              <div className="col-span-12">
                <Input
                  type="number"
                  name="habitacion"
                  value={values.habitacion}
                  onChange={handleChange}
                  validacion={true}
                  label="Habitacion"
                />
              </div>
              <div className="col-span-12">
                <Select
                  id="vendedor_id"
                  name="vendedor_id"
                  value={values.vendedor_id}
                  onChange={ (e) => {
                    setFieldValue("vendedor", e.target.selectedOptions[0].label);
                    handleChange(e);
                  }}
                  validacion={true}
                  label="Vendedor"
                  valueInit=""
                  selectInit="seleccionar vendedor"
                  list={[
                    vendedores && vendedores.map((vendedor, index) => {
                      return (<option key={`${vendedor}-${index}`} value={vendedor.id}>{vendedor.nombre}</option>)
                    })
                  ]}
                />
              </div>

              <div className="col-span-12 overflow-x-auto">
                <Table
                  headers={[
                    'Menu',
                    'Precio',
                    'Total',
                    'Eliminar'
                  ]}
                  body={detalleVenta?.map((item, index) => (
                    <tr
                      key={item.nombre + index}
                    >
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.cantidad} {item.nombre}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        Q{item.precio}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        Q{(item.cantidad) * item.precio}
                      </td>
                      <td className="px-2 text-xs text-primaryFont">
                        <button
                          type="button"
                          onClick={() => {
                            let newArray = [];

                            detalleVenta.forEach((element, index2) => {
                              if (index !== index2) {
                                newArray.push(element);
                              }
                            });

                            setDetalleVenta(newArray)
                          }}
                          className={`hover:bg-red-200 hover:rounded-lg p-1 tooltip`}
                        >
                          <TrashIcon className="h-6 w-6  text-red-600 hover:text-red-600" />
                          <span className="tooltiptext">Eliminar</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                />
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-8 md:col-span-8">
                {isSubmitting && <Spinner />}
                <div className="flex flex-row">
                  <WithPermission permiso="venta-create">
                    <Button type="submit" onClick={handleSubmit} text="Crear orden" />
                  </WithPermission>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>

      {showImagen && (
        <ModalSmall
          title={`${registro.nombre}`}
          open={showImagen}
          closeModal={() => { }}
          cerrar={() => {
            setShowImagen(false);
          }}
          children={
            <div>
              <img className="" src={registro.imagen} alt={registro.imagen} />
            </div>
          }
        />
      )}

      {showOpciones && (
        <ModalSmall
          title={`Opciones del menu ${registro.nombre}`}
          open={showOpciones}
          closeModal={() => { }}
          cerrar={() => {
            setShowOpciones(false);
          }}
          children={
            <div>
              {
                listadoOpciones.map((item, index) => (
                  <div className="mb-4 block mx-5">
                    <Check
                      onChange={(e) => {
                        if (e.target.checked) {
                          listadoOpciones[index] = `Sin ${item}`;
                        }
                      }}
                    />
                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                      Sin {item}
                    </label>
                  </div>
                ))
              }
              <Button
                text="Agregar orden"
                onClick={() => {
                  let add = registro;

                  if (add.cantidad > 0) {
                    let opciones = "";
                    listadoOpciones.forEach((element, index) => {
                      opciones += `${(index === 0) ? `${element}` : ` \n ${element}`}`
                    });

                    add.opciones = opciones;
                    setDetalleVenta([...detalleVenta, add]);
                    setShowOpciones(false);
                  } else {
                    toast.error("debe de agregar por lo menos un plato")
                  }
                }}
              />
            </div>
          }
        />
      )}
    </div>
  );
};
