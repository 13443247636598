import { types } from "../types/types";

export const inputChange = (state = { search:{ value:'', fetch: false, min: 3, clean: true}}, action) => {
    switch (action.type) {
        case types.ChangeSearch:
            return {
                search: action.payload,
            } 
        default:
            return state;
    }
}

