/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { customGet, customGetServicios, customPost, customPut } from "../../../../utils/services";
import { useSelector } from "react-redux";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { AddEmpresa } from "./empresa";
import Select from "../../../../components/common/Select";
import Spinner from "../../../../components/common/Spinner";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import toast from 'react-hot-toast';
import ModalSmall from "../../../../components/common/Modal";
import Alert from "../../../../components/common/Alert";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("nombre requerido")
    .max(255, "Máximo 100 caracteres"),
  apellido: Yup.string().required('apellido es requerido'),
  municipio: Yup.string().required('municipio es requerido')
});

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [municipios, setMunicipios] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [empresas,setEmpresas] = useState([]);
  const [addEmpresa, setAddEmpresa] = useState(false);

  useEffect(() => {
    customGetServicios(undefined, 'municipios', token).then((data) => {
      setMunicipios(data);
    })
  }, []);

  useEffect(() => {
    customGetServicios(undefined, 'departamentos', token).then((data) => {
      setDepartamentos(data);
    })
  }, []);

  useEffect(() => {
    customGet(undefined, 'clientes/create', token).then((data) => {
      setEmpresas(data);
    })
  }, [addEmpresa]);


  return (
    <Formik
      initialValues={{
        nombre: props.editar ? props.value.nombre : "",
        apellido: props.editar ? props.value.apellido : "",
        dpi: props.editar ? props.value.dpi : "",
        celular: props.editar ? props.value.celular : "",
        correo: props.editar ? props.value.correo : "",
        empresa: props.editar ? props.value.empresa_id : "1",
        municipio: props.editar ? props.value.municipio_id : "",
        departamento: props.editar ? props.value.municipio.departamento.id : "",
        nit: props.editar ? props?.value?.nit : "CF"
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        if (props.editar) {
          await customPut(props.value.id, values, 'clientes', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setEdit(false);
                props.setCrear(false);
                props.setRefetch(true);
                setTimeout(() => {
                  props.setRefetch(false);
                }, 300);
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        } else {
          await customPost(values, 'clientes', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setCrear(false);
                props.setRefetch(true);
                setTimeout(() => {
                  props.setRefetch(false);
                }, 300);
                resetForm({});
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        }
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <div className="mx-5">
          <div className="mb-3">
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-6">
                <Input
                  type="text"
                  name="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  validacion={true}
                  label="Nombre"
                />
              </div>
              <div className="col-span-6">
                <Input
                  type="text"
                  name="apellido"
                  value={values.apellido}
                  onChange={handleChange}
                  validacion={true}
                  label="Apellido"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-3 mb-2">
            <div className="col-span-6">
              <Input
                type="text"
                name="celular"
                value={values.celular}
                onChange={handleChange}
                validacion={true}
                label="Celular"
              />
            </div>
            <div className="col-span-6">
              <Input
                type="text"
                name="correo"
                value={values.correo}
                onChange={handleChange}
                validacion={true}
                label="Correo electronico"
              />
            </div>
            <div className="col-span-6">
              <Input
                type="text"
                name="dpi"
                value={values.dpi}
                onChange={handleChange}
                validacion={true}
                label="Numero dpi"
              />
            </div>
            <div className="col-span-6">
              <Input
                type="text"
                name="nit"
                value={values.nit}
                onChange={handleChange}
                validacion={true}
                label="Nit"
              />
            </div>
            <div className="col-span-12 flex flex-row items-end w-full">
              <div className="grid grid-cols-12 gap-2 items-end">
                <div className="col-span-11">
                  <Select
                    name="empresa"
                    value={values.empresa}
                    onChange={handleChange}
                    validacion={true}
                    label="Empresa"
                    valueInit=""
                    selectInit="seleccionar empresa"
                    list={
                      empresas?.empresas?.map((item) => {
                        return (
                          <option value={item.id} key={item.nombre}>{item.nombre}</option>
                        );
                      })
                    }
                  />

                </div>
                <div className="col-span-1">
                  <button
                    type="button"
                    className="tooltip"
                    onClick={() => {
                      setAddEmpresa(true)
                    }}
                  >
                    <PlusCircleIcon className="h-7 w-7 text-primary hover:text-secondary cursor-pointer" />
                    <span className="tooltiptext">agregar empresa</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Select
              name="departamento"
              value={values.departamento}
              onChange={handleChange}
              validacion={true}
              label="Departamento"
              valueInit=""
              selectInit="seleccionar departamento"
              list={
                departamentos?.map((item, index) => {
                  return (
                    <option value={item?.id} key={item?.nombre + index}>{item?.nombre}</option>
                  );
                })
              }
            />
          </div>
          <div className="mb-3">
            <Select
              name="municipio"
              value={values.municipio}
              onChange={handleChange}
              validacion={true}
              label="Municipio"
              valueInit=""
              selectInit="seleccionar municipio"
              list={
                municipios?.filter(x => x?.departamento_id === Number(values.departamento))?.map((item, index) => {
                  return (
                    <option value={item?.id} key={item?.nombre + index}>{item?.nombre}</option>
                  );
                })
              }
            />
          </div>
          <div>
            {
              (props?.editar && values?.dpi?.length <=10) &&
              <Alert type="warning" mensaje="El DPI aun no ha sido completado." />
            }
          </div>
          <div className="py-3 text-right">
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={(isSubmitting || props.mostrar)}
              text={props.editar ? "Actualizar" : "Guardar"}
            />
          </div>
          {isSubmitting && <Spinner />}
          {addEmpresa && (
            <ModalSmall
              title={`Crear empresa`}
              open={addEmpresa}
              closeModal={() => { }}
              cerrar={() => {
                setAddEmpresa(false);
              }}
              children={
                <AddEmpresa setAddEmpresa={setAddEmpresa}/>
              }
            />
          )}
        </div>
      )}
    </Formik>
  );
};
