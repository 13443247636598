/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customGet, customDelete } from "../../../../utils/services";
import { Form } from "./Form";
import { Detalle } from "./detalle";
import ModalSmall from "../../../../components/common/Modal";
import Spinner from "../../../../components/common/Spinner";
import CustomTable from "../../../../components/common/CustomTable";
import Button from "../../../../components/common/Button";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import BotonesConfirmar from "../../../../components/common/BotonesConfirmar";
import toast from 'react-hot-toast';
import { Editar } from "./editar";
import ModalMedium from "../../../../components/common/Modal_medium";
import WithPermission from "../../../../app/WithPermission";

const tableColumns = [
  { title: "id", value: "id", tipo: "text" },
  { title: "Nombre", value: "name", tipo: "text" },
  { title: "Fecha creacion", value: "created_at", tipo: "datetime" },
];

export const TableRoles = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [catalogosCrear, setCatalogosCrear] = useState({});
  const [order, setOrder] = useState("");
  const [crear, setCrear] = useState(false);
  const [edit, setEdit] = useState(false);
  const [detalle, setDetalle] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  const [registro, setRegistro] = useState(false);
  const [editar, setEditar] = useState(false);

  const handleEliminar = (registro) => {
    setEliminar(true);
    setRegistro(registro);
  };

  const handleEditar = (registro) => {
    setEditar(true);
    setRegistro(registro);
  };

  const handleVer = (registro) => {
    setDetalle(true);
    setRegistro(registro);
  };

  useEffect(() => {
    setTableLoading(true);
    customGet(urlFetch, 'roles', token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    })
  }, [urlFetch, crear, edit, eliminar]);

  useEffect(() => {
    customGet(urlFetch, 'roles/create', token).then((data) => {
      setCatalogosCrear(data);
    })
  }, []);

  const pages = [
    { name: "Listado de roles", href: "/", current: false },
  ];

  return (
    <div>
      <div className="flex flex-row justify-between items-end sm:flex mx-5 mt-2">
        <BreadCrumb navegacion={pages} />
        <div>
        <WithPermission permiso="role-create">
          <Button
              text="Crear"
              type="button"
              onClick={() => {
                setCrear(true);
              }}
            />
        </WithPermission>
        </div>
      </div>
      <div className="shadow border border-gray-300 rounded-lg overflow-x-auto mx-5 mt-2">
        <CustomTable
          dataTable={dataTable}
          tableColumns={tableColumns}
          handleEditar={handleEditar}
          handleEliminar={handleEliminar}
          handleVer={handleVer}
          fetchPage={setUrlFech}
          pagination={true}
          showVer={true}
          showEditar={true}
          showEliminar={true}
          setOrder={setOrder}
          order={order}
          permiso="role"
        />
        {tableLoading && (
          <div className="mt-10 mb-10">
            <Spinner />
          </div>
        )}
        {crear && (
          <ModalSmall
            title={"Crear rol"}
            open={crear}
            closeModal={() => { }}
            cerrar={() => {
              setCrear(false);
              setEdit(false);
            }}
            children={
              <Form
                setCrear={setCrear}
                catalogos={catalogosCrear}
                value={registro}
                editar={edit}
                setEdit={setEdit}
              />
            }
          />
        )}
        {detalle && (
          <ModalSmall
            title={`Detalle del rol`}
            open={detalle}
            closeModal={() => { }}
            cerrar={() => {
              setDetalle(false);
            }}
            children={
              <Detalle registro={registro} />
            }
          />
        )}
        {eliminar && (
          <ModalSmall
            title={`Eliminar registro ${registro.name}`}
            open={eliminar}
            closeModal={() => { }}
            cerrar={() => {
              setEliminar(false);
            }}
            children={
              <BotonesConfirmar
                aceptar={async () => {
                  await customDelete(registro.id, 'roles', token)
                    .then(async (data) => {
                      if (data?.status >= 200 && data?.status <= 210) {
                        toast.success(data?.data?.msg);
                        setEliminar(false);
                      }
                    })
                    .catch(function (error) {
                      if (error.response.status >= 400 && error.response.status <= 450) {
                        toast.error(error.response.data.error);
                      } else if (error.response.status >= 500) {
                        toast.error("Error interno comuniquese con el administrador");
                      }
                    });
                }}
                cancelar={() => {
                  setEliminar(false);
                }}
              />
            }
          />
        )}

        {editar && (
          <ModalMedium
            title={`Editar ${registro.name}`}
            open={editar}
            closeModal={() => { }}
            cerrar={() => {
              setEditar(false);
            }}
            children={
              <Editar
                registro={registro}
                catalogos={catalogosCrear}
              />
            }
          />
        )}
      </div>
    </div>
  );
};
