/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { customGet, customPost } from "../../../utils/services";
import { useSelector } from "react-redux";
import { ChatIcon, EyeIcon, SearchCircleIcon, UserAddIcon } from "@heroicons/react/outline";
import { CrearCliente } from "./crear_cliente";
import { AplicarPagos } from "./aplicar_pagos";
import { AddComentario } from "./agregar_comentario";
import makeAnimated from "react-select/animated";
import SelectAC from "react-select";
import NumberFormat from 'react-number-format';
import Table from "../../../components/common/Table";
import Modal from "../../../components/common/Modal";
import Alert from "../../../components/common/Alert";
import Select from "../../../components/common/Select";
import Spinner from "../../../components/common/Spinner";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import toast from 'react-hot-toast';
import Label from "../../../components/common/Label";
import moment from "moment";
import TextArea from "../../../components/common/TextArea";
import WithPermission from "../../../app/WithPermission";
import * as Yup from "yup";
import { DetalleQuienReserva } from "./detalle_quien_reserva";

const validaciones = Yup.object().shape({
  dias: Yup.string()
    .required("cantidad dias es requerido")
    .max(255, "Máximo 100 caracteres"),
  personas: Yup.string().required('cantidad personas es requerido'),
  estado: Yup.string().required('estado es requerido'),
});

export const Reservar = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const animatedComponents = makeAnimated();
  const [catalogosReservas, setCatalogos] = useState({});
  const [personas, setPersonas] = useState([]);
  const [tipo, setTipo] = useState("warning");
  const [cargandoBusqueda, setCargandoBusqueda] = useState(false);
  const [mensaje, setMensaje] = useState("debe buscar un cliente o crear uno nuevo");
  const [cliente, setCliente] = useState(null);
  const [costo, setCosto] = useState("0.00");
  const [crearCliente, setCrearCliente] = useState(false);
  const [editar, setEditar] = useState(false);
  const [reservaciones, setReservaciones] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [cargandoActualizacion, setCargandoActualizacion] = useState(false);
  const [agrupaciones, setAgrupaciones] = useState([]);
  const [arregloPagos, setArregloPagos] = useState([]);
  const [pagos, setPagos] = useState(false);
  const [buscar, setBuscar] = useState("");
  const [listadoClientes, setListadoDeClientes] = useState([]);
  const [search, setSearch] = useState("")
  const [comentarioReserva, setComentarioReserva] = useState("");
  const [clienteCreado,setClienteCreado] = useState({});
  const [clienteReserva,setClienteReserva] = useState({});
  const [comentario,setComentario]= useState(false);
  const [registro,setRegistro] = useState(false);
  const [verDetalle,setVerDetalle] = useState(false);

  let tipo_pagos = [
    {
      id: 1,
      pagos: [],
      nombre: "Efectivo",
    },
    {
      id: 2,
      pagos: [],
      nombre: "Deposito/Transferencia",
    },
    {
      id: 3,
      pagos: [],
      nombre: "Tarjeta",
    },
    {
      id: 4,
      pagos: [],
      nombre: "Visa Link",
    }
  ]
  const tipoEstadosFilter = (estado) => {
    if (estado === 2) {
      return [
        <option value="2">Reservado</option>,
        <option value="3">Confirmado</option>,
        <option value="4">Ocupado</option>,
        <option value="5">Cancelado</option>,
      ]
    }
    if (estado === 3) {
      return [
        <option value="3">Confirmado</option>,
        <option value="4">Ocupado</option>,
        <option value="5">Cancelado</option>,
      ]
    }
    if (estado === 4) {
      return [
        <option value="4">Ocupado</option>,
        <WithPermission permiso='reserva-delete'><option value="5">Cancelado</option></WithPermission>,
      ]
    }
  };

  useEffect(() => {
    customGet(undefined, `reservacion/create?habitacion_id=${props?.idHabitacion}`, token).then((data) => {
      setCatalogos(data);
      setCosto(data.habitacion.precio_p1);
      let person = [];
      for (let index = 1; index <= data.habitacion.capacidad; index++) {
        person.push(index);
      }
      setPersonas(person);
    });
  }, []);

  useEffect(() => {
    let list = [];
    setCargando(true);
    let url = props.reservaOld ? 'buscar-old': 'buscar';
    customPost({
      cliente_id: props?.registro?.reserva?.cliente?.id,
      fecha: props?.registro?.reserva?.entrada
    }, `reservacion/cliente/${url}`, token).then(async (data) => {
      await data?.data?.reservas.forEach(element => {
        element.estado_actualizado = undefined
      });

      await data?.data?.reservas.forEach((element) => {
        let existe = false;
        if (element?.comentarios) {
          setComentarioReserva(element?.comentarios)
        }

        list.forEach(item => {
          if (item === element?.habitacion?.nombre) {
            existe = true;
          }
        });

        if (!existe) {
          list.push(element.habitacion.nombre)
        }
      });

      setAgrupaciones(list);
      setReservaciones(data?.data?.reservas);
      setClienteReserva(data?.data?.cliente)
      setCargando(false);

    });
  }, [refetch]);

  useEffect(() => {
    reservaciones?.forEach((element) => {
      element?.pagos?.forEach((item) => {
        if (item?.tipo_pago_id === 1) {
          tipo_pagos[0].pagos.push(item);
        } else if (item?.tipo_pago_id === 2) {
          tipo_pagos[1].pagos.push(item);
        } else if (item?.tipo_pago_id === 3) {
          tipo_pagos[2].pagos.push(item);
        } else if (item?.tipo_pago_id === 4) {
          tipo_pagos[3].pagos.push(item);
        }
      });
    })

    setArregloPagos(tipo_pagos);

  }, [refetch, reservaciones]);

  const buscarCliente = async (buscar) => {
    setCargandoBusqueda(true);
    await customPost({ buscar }, `clientes/buscar`, token)
      .then(async (data) => {
        if (data?.status >= 200 && data?.status <= 210) {
          setTipo("success");
          setMensaje(`Cliente encontrado con exito`);
          setListadoDeClientes(data.data.data);
          setBuscar("");
          setCargandoBusqueda(false);
        }
      })
      .catch(function (error) {
        if (error.response.status >= 400 && error.response.status <= 450) {
          toast.error(error.response.data.error);
          setTipo("error");
          setMensaje(`No se encontro ningun cliente`);
          setCargandoBusqueda(false);
        } else if (error.response.status >= 500) {
          setCargandoBusqueda(false);
          toast.error("Error interno comuniquese con el administrador");
        }
      });
  }

  useEffect(()=>{
    if(clienteCreado?.data?.data?.id){
      buscarCliente(clienteCreado?.data?.data?.nombre)
    }
  },[clienteCreado])

  const actualizarReservaciones = async (listado) => {
    setCargandoActualizacion(true);
    const body = [];
    listado.forEach(element => {
      if (element.estado_actualizado !== undefined) {
        let item = {
          reservacion: element.id,
          estado: element.estado_actualizado
        };

        body.push(item);
      }
    });

    await customPost(body, 'reservacion/cliente/actualizar', token)
      .then(async (data) => {
        if (data?.status >= 200 && data?.status <= 210) {
          setRefetch(true);
          props.setRefetch(true);
          setCargandoActualizacion(false)
          toast.success(data?.data?.msg);

          setTimeout(() => {
            setRefetch(false);
            props.setRefetch(false);
          }, 500);
        }
      })
      .catch(function (error) {
        if (error.response.status >= 400 && error.response.status <= 450) {
          toast.error(error.response.data.error);
          setCargandoActualizacion(false)
        } else if (error.response.status >= 500) {
          setCargandoActualizacion(false)
          toast.error("Error interno comuniquese con el administrador");
        }
      });
  }

  const sumatoriaSaldo = (item) => {
    let total = 0;
    reservaciones.forEach(element => {
      if (element.habitacion.nombre === item) {
        total = total + Number(element.saldo);
      }
    });

    return total;
  }

  const sumatoriaTotal = (item) => {
    let total = 0;
    reservaciones.forEach(element => {

      if (element.habitacion.nombre === item) {
        total = total + Number(element.costo);
      }
    });

    return total;
  }

  const sumatoriaPagos = (item) => {
    let total = 0;
    item.pagos.forEach(element => {
      total = total + Number(element?.monto);
    });

    return total;
  }

  const totalSaldo = () => {
    let total = 0;
    reservaciones.forEach(element => {
      total = total + Number(element.saldo);

    });

    return total;
  }

  const totalCosto = () => {
    let total = 0;
    reservaciones.forEach(element => {
      total = total + Number(element.costo);

    });

    return total;
  }

  const totalPagos = () => {
    let total = 0;
    reservaciones?.forEach((element) => {
      element?.pagos?.forEach((item) => {
        total = total + Number(item.monto);
      });
    })

    return total;
  }

  const changeSelectCliente = (e) => {
    setCliente(e);
  };

  return (
    <Formik
      initialValues={{
        cliente: null,
        habitacion: props?.idHabitacion,
        fecha: props?.registro?.fecha,
        comentarios: "",
        dias: "1",
        personas: "1",
        estado: "",
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        if(cliente?.value){
          values.cliente = cliente.value;
          //values.cliente = cliente?.id;
          values.costo = costo;
          await customPost(values, 'reservacion', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props?.setRefetch(true);
                props?.setReservar(false);

                setTimeout(() => {
                  props?.setRefetch(false);
                }, 500);
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        } else {
          toast.error("debe seleccionar o buscar un cliente")
        }
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
        <div className="grid grid-cols-12 gap-2 mb-3">
          {
            !props.editar &&
            <div className="col-span-12 xl:col-span-9 lg:col-span-9 md:col-span-8">
              <div className="grid grid-cols-12 gap-3 border rounded-lg hover:shadow-lg p-3 items-end">
                <div className={`col-span-11 xl:col-span-10 lg:col-span-10 md:col-span-10 ${props.editar ? 'hidden' : ''}`}>
                  <SelectAC
                    isMulti={false}
                    placeholder="Seleccionar"
                    components={animatedComponents}
                    name="servicios"
                    defaultValue={buscar}
                    value={cliente}
                    className="basic-multi-select text-sm w-full"
                    classNamePrefix="seleccione"
                    options={listadoClientes}
                    onChange={changeSelectCliente}
                    inputValue={buscar}
                    onInputChange={(e) => { setBuscar(e); }}
                    onKeyDown={(e) => {
                      let search = e.target.value;
                      setSearch(search);
                      setCliente([]);
                      if (search?.length >= 3 && e.key === 'Enter') {
                        setListadoDeClientes([]);
                        buscarCliente(search);
                      }

                    }}
                    maxMenuHeight={140}
                  />
                </div>
                <div className={`col-span-1 ${props.editar ? 'hidden' : ''}`}>
                  <div className="flex flex-row">
                    <button
                      type="button"
                      className="tooltip"
                      onClick={() => {
                        if (search !== "") {
                          buscarCliente(search);
                        }
                      }}
                    >
                      <SearchCircleIcon className="h-7 w-7 text-primary hover:text-secondary cursor-pointer" />
                      <span className="tooltiptext">Buscar cliente</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => { setCrearCliente(true) }}
                      className="tooltip"
                    >
                      <UserAddIcon className="h-7 w-7 text-primary hover:text-secondary cursor-pointer" />
                      <span className="tooltiptext">Crear cliente</span>
                    </button>
                  </div>
                </div>
                <div className="col-span-12">
                  {
                    (props.editar) ?
                      <Alert type="success" mensaje={`Cliente con reserva ${props.registro.reserva.cliente?.nombre} ${props.registro.reserva.cliente?.apellido}`} />
                      :
                      <Alert type={tipo} mensaje={mensaje} />
                  }

                </div>
                {
                  cargandoBusqueda && <div className="col-span-12"><Spinner /></div>
                }
                <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4">
                  <Input
                    type="number"
                    name="dias"
                    value={values.dias}
                    onChange={handleChange}
                    validacion={true}
                    label="Cantidad dias"
                  />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4">
                  <Select
                    name="personas"
                    value={values.personas}
                    onChange={(e) => {
                      setFieldValue("personas", e.target.value);
                      if (e.target.value === "1") {
                        setCosto(catalogosReservas?.habitacion.precio_p1);
                      } else if (e.target.value === "2") {
                        setCosto(catalogosReservas?.habitacion.precio_p2);
                      } else if (e.target.value === "3") {
                        setCosto(catalogosReservas?.habitacion.precio_p3);
                      } else if (e.target.value === "4") {
                        setCosto(catalogosReservas?.habitacion.precio_p4);
                      }
                    }}
                    validacion={true}
                    label="Cantidad personas"
                    valueInit=""
                    selectInit="seleccione cantidad de personas"
                    list={
                      personas?.map((item, index) => {
                        return (
                          <option value={item} key={'per-' + index}>{item}</option>
                        );
                      })
                    }
                  />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4">
                  <Select
                    name="estado"
                    value={values.estado}
                    onChange={handleChange}
                    validacion={true}
                    label="Estado"
                    valueInit=""
                    selectInit="seleccionar estado"
                    list={
                      catalogosReservas?.estados?.map((item, index) => {
                        return (
                          <option value={item?.id} key={'cat-res-status-' + index}>{item?.nombre}</option>
                        );
                      })
                    }
                  />
                </div>
                <div className="col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12">
                  <TextArea
                    name="comentarios"
                    value={values.comentarios}
                    onChange={handleChange}
                    label="Comentarios"
                  />
                </div>
              </div>
            </div>
          }
          {
            !props.editar ?
              <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 border p-3 rounded-lg hover:shadow-lg">
                <Label text="Resumen de reservacion" />
                <hr />
                <ul>
                  {
                    props.editar ?
                      <li >
                        <span className="text-xs text-gray-800">Fecha entrada: {moment(props.registro.reserva.entrada).format("DD-MM-YYYY")}</span>
                      </li> :
                      <li >
                        <span className="text-xs text-gray-800">Fecha entrada: {moment(props.registro.fecha).format("DD-MM-YYYY")}</span>
                      </li>
                  }
                  {
                    props.editar ?
                      <li >
                        <span className="text-xs text-gray-800">Fecha salida: {moment(props.registro.reserva.salida).format("DD-MM-YYYY")}</span>
                      </li> :
                      <li >
                        <span className="text-xs text-gray-800">Fecha salida: {moment(props.registro.fecha).add(Number(values.dias), 'd').format("DD-MM-YYYY")}</span>
                      </li>
                  }
                  <li >
                    <span className="text-xs text-gray-800">Cantidad de dias: {values.dias}</span>
                  </li>
                  <li>
                    <span className="text-xs text-gray-800">Cantidad de personas: {values.personas}</span>
                  </li>
                  <li>
                    <span className="text-xs text-gray-800">Costo habitacion: Q{costo}</span>
                  </li>
                  <li>
                    <span className="text-xs text-gray-800">Total: Q{(Number(values.dias) * Number(costo))}</span>
                  </li>
                </ul>
              </div> :
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 border p-3 rounded-lg hover:shadow-lg">
                <div className="flex flex-row justify-between items-end">
                  <Label text="Resumen de reservaciones" />
                  <button
                      type="button"
                      onClick={() => {
                        setCrearCliente(true);
                        setEditar(true);
                      }}
                      className="tooltip"
                    >
                      <UserAddIcon className="h-7 w-7 text-primary hover:text-secondary cursor-pointer" />
                      <span className="tooltiptext">editar cliente</span>
                    </button>
                </div>
                <Table
                  classHeader="text-right "
                  headers={[
                    'Habitacion',
                    'Costo',
                    'Saldo'
                  ]}
                  body={[
                    agrupaciones?.map((item, index) => (
                      <tr key={'group-' + index} className="hover:bg-gray-100 cursor-pointer">
                        <td className="px-2 py-1 text-xs text-primaryFont">
                          {item}
                        </td>
                        <td className="px-2 py-1 text-xs text-primaryFont text-right">
                          <NumberFormat value={sumatoriaTotal(item)} displayType={'text'} thousandSeparator={true} prefix={'Q'} />
                        </td>
                        <td className="px-2 py-1 text-xs text-primaryFont text-right">
                          <NumberFormat value={sumatoriaSaldo(item)} displayType={'text'} thousandSeparator={true} prefix={'Q'} />
                        </td>
                      </tr>
                    )),
                    <tr className="hover:bg-gray-100 cursor-pointer">
                      <td className="px-2 py-1 text-xs text-primaryFont">
                        Total
                      </td>
                      <td className="px-2 py-1 text-xs text-primaryFont text-right">
                        <NumberFormat value={totalCosto()} displayType={'text'} thousandSeparator={true} prefix={'Q'} />
                      </td>
                      <td className="px-2 py-1 text-xs text-primaryFont text-right">
                        <NumberFormat value={totalSaldo()} displayType={'text'} thousandSeparator={true} prefix={'Q'} />
                      </td>
                    </tr>
                  ]}
                />

                <br />
                <Label text="Resumen de pagos" />
                <Table
                  classHeader="text-right pr-20"
                  headers={[
                    'Tipo pago',
                    'Total',
                  ]}
                  body={[
                    arregloPagos?.filter(x => x?.pagos?.length > 0)?.map((item, index) => (
                      <tr key={'pagos-' + index} className="hover:bg-gray-100 cursor-pointer">
                        <td className="px-2 py-1 text-xs text-primaryFont">
                          {item?.nombre}
                        </td>
                        <td className="px-2 py-1 text-xs text-primaryFont text-right pr-20">
                          <NumberFormat value={sumatoriaPagos(item)} displayType={'text'} thousandSeparator={true} prefix={'Q'} />
                        </td>
                      </tr>
                    )),
                    <tr className="hover:bg-gray-100 cursor-pointer">
                      <td className="px-2 py-1 text-xs text-primaryFont">
                        Total
                      </td>
                      <td className="px-2 py-1 text-xs text-primaryFont text-right pr-20">
                        <NumberFormat value={totalPagos()} displayType={'text'} thousandSeparator={true} prefix={'Q'} />
                      </td>
                    </tr>
                  ]}
                />

                <br />
                <Label text="Comentarios" />
                <TextArea
                  disabled={true}
                  value={comentarioReserva}
                  rows={6}
                />
              </div>
          }

          {
            props.editar &&
            <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-8 border p-3 rounded-lg hover:shadow-lg">
              <Label text="Listado de reservaciones" />
              <Table
                cargando={cargando}
                headers={[
                  'Seleccionar',
                  'Habitacion',
                  'Estado',
                  'Personas',
                  'Entrada',
                  'Salida',
                  'Costo',
                  'Saldo',
                  ''
                ]}
                body={reservaciones?.map((item, index) => (
                  <tr key={`res-${item.id}-${index}`}
                    className="hover:bg-gray-100 cursor-pointer"
                  >
                    <td className="px-1 py-1 text-xs">
                      <WithPermission permiso="reserva-edit">
                        <Select
                          name="estado"
                          defaultValue={item.estado.id}
                          valueInit=""
                          selectInit="Seleccionar estado"
                          validacion={true}
                          className="w-32"
                          onChange={(e) => {
                            reservaciones[index].estado_actualizado = e.target.value
                          }}
                          list={tipoEstadosFilter(item?.estado?.id)}
                        />
                      </WithPermission>
                    </td>
                    <td className="px-1 text-xs text-primaryFont">
                      {item?.habitacion?.nombre}
                    </td>
                    <td className="px-1 text-xs text-primaryFont">
                      {item?.estado?.nombre}
                    </td>
                    <td className="px-1 text-xs text-primaryFont">
                      {item?.personas}
                    </td>
                    <td className="px-1 text-xs text-primaryFont">
                      {moment(item?.entrada).format("DD-MM-YYYY")}
                    </td>
                    <td className="px-1 text-xs text-primaryFont">
                      {moment(item?.salida).format("DD-MM-YYYY")}
                    </td>
                    <td className="px-1 text-xs text-primaryFont">
                      <NumberFormat value={item?.costo} displayType={'text'} thousandSeparator={true} prefix={'Q'} />
                    </td>
                    <td className="px-1 text-xs text-primaryFont">
                      <NumberFormat value={item?.saldo} displayType={'text'} thousandSeparator={true} prefix={'Q'} />
                    </td>
                    <td className="px-1 text-xs text-primaryFont">
                    <button
                      type="button"
                      className={`hover:bg-primary inline-flex items-center p-1 border border-transparent rounded-md text-gray-50 hover:text-white  focus:ring-primary tooltip`}
                      onClick={() => {
                        setRegistro(item);
                        setVerDetalle(true);
                      }}
                    >
                      <EyeIcon className="h-6 w-6 text-primaryFont hover:text-white" />
                      <span className="tooltiptext">Ver detalle</span>
                    </button>
                    <button
                      type="button"
                      className={`hover:bg-primary inline-flex items-center p-1 border border-transparent rounded-md text-gray-50 hover:text-white  focus:ring-primary tooltip`}
                      onClick={() => {
                        setRegistro(item);
                        setComentario(true);
                      }}
                    >
                      <ChatIcon className="h-6 w-6 text-primaryFont hover:text-white" />
                      <span className="tooltiptext">editar comentario</span>
                    </button>
                    </td>
                  </tr>
                ))}
              />
            </div>
          }
          {
            !props.editar && 
            <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-3">
            <WithPermission permiso="reserva-create">
                <Button
                type="submit"
                disabled={(isSubmitting || props.mostrar)}
                text={'Crear reservacion'}
                onClick={handleSubmit}
              />
            </WithPermission>
          </div>
          }
          {props.editar &&
            <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-3">
              {
                cargandoActualizacion ?
                  <Spinner />
                  :
                  <WithPermission permiso="reserva-edit">
                    <Button
                      type="submit"
                      disabled={(isSubmitting || props.mostrar)}
                      text="Actualizar estados"
                      onClick={() => {
                        actualizarReservaciones(reservaciones)
                      }}
                    />
                  </WithPermission>
              }
            </div>
          }
          {
            (props?.registro?.reserva?.cliente?.dpi === null || props?.registro?.reserva?.cliente?.dpi?.length <= 10) &&
            <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-5">
              <Alert type="warning" mensaje="Este cliente no tiene completado el DPI favor completarlo" />
            </div>
          }
          <div className="col-span-12">
            {isSubmitting && <Spinner />}
          </div>
          {crearCliente && (
            <Modal
              title={editar ? 'Editar cliente' : 'Crear nuevo cliente'}
              open={crearCliente}
              closeModal={() => { }}
              cerrar={() => {
                setCrearCliente(false);
                setEditar(false);
              }}
              children={
                <CrearCliente
                  setCrear={setCrearCliente}
                  setClienteCreado={setClienteCreado}
                  editar={editar}
                  value={clienteReserva}
                />
              }
            />
          )}

          {pagos && (
            <Modal
              title='Aplicar pagos'
              open={pagos}
              closeModal={() => { }}
              cerrar={() => { setPagos(false) }}
              children={
                <AplicarPagos
                  setPagos={setPagos}
                  cliente={props?.registro?.reserva?.cliente?.id}
                  setRefetch={setRefetch}
                />
              }
            />
          )}

          {comentario && (
            <Modal
              title='Agregar comentario'
              open={comentario}
              closeModal={() => { }}
              cerrar={() => { setComentario(false) }}
              children={
               <AddComentario
                  registro={registro}
                  token={token}
                  setRefetch={setRefetch}
                  setComentario={setComentario}
               />
              }
            />
          )}

{verDetalle && (
            <Modal
              title='Detalle'
              open={verDetalle}
              closeModal={() => { }}
              cerrar={() => { setVerDetalle(false) }}
              children={
               <DetalleQuienReserva
                registro={registro}
               />
              }
            />
          )}
        </div>
      )}
    </Formik>
  );
};
