export default function PageHotel() {
    return (
      <div className="bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <div className="sm:ml-6 text-center">
                <h1 className="text-3xl xl:text-4xl lg:text-2xl font-extrabold text-primaryFont tracking-tight mt-28">Hotel hacienda del pedregal</h1>
            </div>
          </main>
        </div>
      </div>
    )
  }