import { React, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { customPost } from "../../../../utils/services";
import Button from "../../../../components/common/Button";
import Input from "../../../../components/common/Input";
import * as Yup from "yup";
import WithPermission from "../../../../app/WithPermission";
import toast from "react-hot-toast";
import Select from "../../../../components/common/Select";

const tdClass = "whitespace-nowrap px-3 py-4 text-sm text-gray-500";

const validaciones = Yup.object().shape({
  monto: Yup.number()
    .required("Monto requerido")
    .moreThan(0, 'Ingrese monto mayor a 0'),
  user_id: Yup.number()
    .required("Usuario es requerido")
    .moreThan(0, 'Seleccione un usuario')
});
export const CajaHotel = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [movimientosActuales, setMovimientosActuales] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [realizarIngreso, setRealizarIngreso] = useState(false);
  const [usuarioFiltro, setUsuarioFiltro] = useState(0);

  const getMovimientos = () => {
    customPost({}, `cortes/caja/hotel`, token).then(
      (data) => {
        setMovimientosActuales(data.data);
      }
    );

    customPost({}, `catalogos/usuarios`, token).then(
      (data) => {
        setUsuarios(data.data);
      }
    );
  };

  const handleChangeSelect = (e) => {
    setUsuarioFiltro(e.target.value);
    customPost({ user_id: e.target.value }, `cortes/caja/hotel`, token).then(
      (data) => {
        setMovimientosActuales(data.data);
      }
    );
  };



  useState(() => {
    getMovimientos();
  }, [])

  return (
    <Formik
      initialValues={{
        monto: 0,
        user_id: 0
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        setRealizarIngreso(false);
        await customPost(values, 'cortes/caja/hotel/crear', token)
          .then(async (data) => {
            if (data?.status >= 200 && data?.status <= 210) {
              toast.success(data?.data?.msg);
              resetForm({});
              getMovimientos();
            }
          })
          .catch(function (error) {
            if (error.response.status >= 400 && error.response.status <= 450) {
              toast.error(error.response.data.error);
              setRealizarIngreso(true);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
            }
          });
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <div className="mt-3 mx-2 xl:mx-4 lg:mx-4 justify-center flex">
          {
            realizarIngreso ?
              <div className="border rounded-lg hover:shadow-md p-4" style={{ width: "500px" }}>
                <div className="flex flex-row justify-center items-center text-primaryFont">
                  <span className="">Ingreso de dinero a caja</span>
                </div>
                <hr className="mb-3" />
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <div className="px-5 mt-5 mb-5">
                    <Input
                      type="number"
                      name="monto"
                      value={values.monto}
                      onChange={handleChange}
                      validacion={true}
                      label="Monto"
                    />

                    <Select
                      name="user_id"
                      value={values.user_id}
                      onChange={handleChange}
                      validacion={true}
                      label="Usuario"
                      valueInit=""
                      selectInit="seleccionar usuario"
                      list={
                        usuarios?.map((item) => {
                          return (
                            <option value={item.id} key={item.name}>{item.name}</option>
                          );
                        })
                      }
                    />
                  </div>
                  <div className="px-5 mt-5 mb-5">
                    <WithPermission permiso={`hotel-ingreso-caja`}>
                      <Button text="Guardar" onClick={handleSubmit} type="submit" />
                    </WithPermission>
                  </div>
                </div>
              </div>
              :
              <div className="border rounded-lg hover:shadow-md p-4" style={{ width: "500px" }}>
                <div className="flex flex-row justify-center items-center text-primaryFont">
                  <span className="">MOVIMIENTOS CAJA</span>
                </div>
                <WithPermission permiso={`hotel-ingreso-caja`}>
                  <Select
                    name="user_id"
                    value={usuarioFiltro}
                    onChange={handleChangeSelect}
                    validacion={true}
                    label=""
                    valueInit=""
                    selectInit="seleccionar usuario"
                    list={
                      usuarios?.map((item) => {
                        return (
                          <option value={item.id} key={item.name}>{item.name}</option>
                        );
                      })
                    }
                  />
                </WithPermission>
                <hr className="mb-3" />
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                          Monto
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Fecha
                        </th>
                      </tr>
                    </thead>
                    {movimientosActuales.map(caja => <tr>
                      <td className={tdClass}>{caja.monto}</td>
                      <td className={tdClass}>{caja.created_at.substring(0, 19).replace('T', ' ')}</td>
                    </tr>)}
                  </table>

                  <div className="px-5 mt-5 mb-5">
                    <WithPermission permiso={`hotel-ingreso-caja`}>
                      <Button text="Ingresar Dinero A Caja" onClick={() => { setRealizarIngreso(true) }} />
                    </WithPermission>
                  </div>
                </div>
              </div>
          }

        </div>
      )}
    </Formik>
  );
};
