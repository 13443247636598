/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { customPost, customPut, customGet } from "../../../../utils/services";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/common/Spinner";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import toast from "react-hot-toast";
import makeAnimated from "react-select/animated";
import SelectAC from "react-select";
import Label from "../../../../components/common/Label";
import Toggle from "../../../../components/common/Toggle";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  name: Yup.string().required("nombre requerido"),
  email: Yup.string().required("correo electronico es requerido"),
  //password: Yup.string().required("contraseña es requerida"),
  //confirm_password: Yup.string().required("confirmar contraseña es requerida"),
});

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const animatedComponents = makeAnimated();
  const [roles, setRoles] = useState([]);
  const [listadoRolesSelected, setRolesSelected] = useState([]);
  const listadoRoles = [];
  console.log('Roles:', props.value.roles);
  useEffect(() => {
    customGet(undefined, "roles", token).then((data) => {
      setRoles(data);

      data?.data?.forEach((item) => {
        let reg = {
          value: item.id,
          label: item?.name,
        };

        if (props.editar) {
          let rolesAsignados = props.value.roles;

          rolesAsignados.forEach((element) => {
            if (element.id === item.id) {
              listadoRolesSelected.push(reg);
            }
          });
        }

        listadoRoles.push(reg);
      });
    });
  }, []);

  roles?.data?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item?.name,
    };

    listadoRoles.push(reg);
  });

  const changeSelectRoles = (e) => {
    setRolesSeleccionados(e);
  };

  const [rolesSeleccionados, setRolesSeleccionados] = useState(listadoRolesSelected);

  return (
    <Formik
      initialValues={{
        name: props.editar ? props.value.name : "",
        email: props.editar ? props.value.email : "",
        password: props.editar ? props.value.password : "",
        confirm_password: props.editar ? props.value.confirm_password : "",
        roles: props.editar ? (props.value.roles.legth ? props.value.roles[0].id : 0) : 0,
        rol: props.editar ? (props.value.roles.legth ? props.value.roles[0].id : 0) : 0,
        reset_password: props.editar ? false : true,
        status: props.editar ? props.value.status : true
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        let roles = [];

        rolesSeleccionados.forEach((element) => {
          roles.push(element.value);
        });

        values.roles = roles;

        if (props.editar) {
          await customPut(props.value.id, values, "users", token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setEdit(false);
                props.setCrear(false);
              }
            })
            .catch(function (error) {
              if (
                error.response.status >= 400 &&
                error.response.status <= 450
              ) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        } else {
          if (values.password === '') {
            toast.error('contraseña es requerida')
          } else if (values.password !== values.confirm_password) {
            toast.error('las contraseñas no coinciden')
          } else {
            await customPost(values, "users", token)
              .then(async (data) => {
                if (data?.status >= 200 && data?.status <= 210) {
                  toast.success(data?.data?.msg);
                  props.setCrear(false);
                  resetForm({});
                }
              })
              .catch(function (error) {
                if (
                  error.response.status >= 400 &&
                  error.response.status <= 450
                ) {
                  toast.error(error.response.data.error);
                } else if (error.response.status >= 500) {
                  toast.error("Error interno comuniquese con el administrador");
                }
              });
          }

        }
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <div className="mx-5">
          <div className="mb-3">
            <Input
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              validacion={true}
              label="Nombre"
            />
          </div>
          <div className="mb-3">
            <Input
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              validacion={true}
              label="Correo electronico"
            />
          </div>
          <div className="mb-3">
            <Label text="Roles" />
            <SelectAC
              defaultValue={listadoRolesSelected}
              isMulti={true}
              placeholder="Seleccionar"
              components={animatedComponents}
              name="extension_permitida"
              className="basic-multi-select text-sm"
              classNamePrefix="seleccione"
              options={listadoRoles}
              onChange={changeSelectRoles}
              maxMenuHeight={160}
              autocomplete="off"
            />
          </div>
          {
            props.editar &&
            <div className="flex items-center justify-around">
              <div>
              <Label text="Reset password" />
              <Toggle enabled={values.reset_password} name="reset_password" />
              </div>
              <div>
              <Label text="Estado" />
              <Toggle enabled={values.status} name="status" />
              </div>
            </div>
          }

          {
            values.reset_password &&
            <div className="mb-3">
              <Input
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                validacion={true}
                label="Contraseña"
              />
            </div>
          }
          {
            values.reset_password &&
            <div className="mb-3">
              <Input
                type="password"
                name="confirm_password"
                value={values.confirm_password}
                onChange={handleChange}
                validacion={true}
                label="Confirmar contraseña"
              />
            </div>
          }

          <div className="py-3 text-right">
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitting || props.mostrar}
              text={props.editar ? "Actualizar" : "Guardar"}
            />
          </div>
          {isSubmitting && <Spinner />}
        </div>
      )}
    </Formik>
  );
};
