import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from '@heroicons/react/outline'

export default function ModalLarge(props) {
  const [open] = useState(props.open);

  return (
    <Transition.Root show={open} as={Fragment} >
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-y-auto"
        onClose={props.closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform  transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full xl:max-w-7xl sm:p-6">
              <div className="sm:block absolute top-0 right-0 pt-4 pr-4">
                <div
                  className="bg-white rounded-md text-red-300 hover:text-maivi-secondaryDarkRed cursor-pointer"
                  onClick={props.cerrar}
                >
                  <XIcon className="h-6 w-6" />
                </div>
              </div>
              <div>
                {props.icon &&   
                  props.icon}
              
                <Dialog.Title
                  as="h3"
                  className="text-lg text-center font-medium text-maivi-primaryFont mt-2"
                >
                  {props.title}
                </Dialog.Title>
                {props.children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
