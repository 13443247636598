/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState } from "react";
import { Formik } from "formik";
import { customPost, customPut } from "../../../../utils/services";
import { useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Spinner from "../../../../components/common/Spinner";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  name: Yup.string()
    .required("nombre requerido")
    .max(255, "Máximo 100 caracteres"),
});

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const animatedComponents = makeAnimated();
  const [rolesSeleccionados,setRolesSeleccionados] = useState([]);
  let listadoRoles= [];

  props.catalogos.permission?.forEach((item) => {
    let reg = {
        value: item.id,
        label: item?.name,
    };
    listadoRoles.push(reg);
});

const changeSelectRoles = (e) => {
  let roles = [];

  e.forEach(element => {
      roles.push(element.value);
  });
   setRolesSeleccionados(roles);
};

  return (
    <Formik
      initialValues={{
        name: props.editar ? props.value.name : "",
        permission: []
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        if (props.editar) {
          await customPut(props.value.id, values, 'roles', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setEdit(false);
                props.setCrear(false);
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        } else {
          values.permission = rolesSeleccionados;
          await customPost(values, 'roles', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setCrear(false);
                resetForm({});
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        }
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <div className="mx-5 mb-20">
          <div className="mb-3">
            <Input
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              validacion={true}
              label="Nombre"
            />
          </div>
          <Select
            isMulti={true}
            placeholder="Seleccionar"
            components={animatedComponents}
            name="servicios"
            className="basic-multi-select text-sm w-full"
            classNamePrefix="seleccione"
            options={listadoRoles}
            onChange={changeSelectRoles}
            maxMenuHeight={140}
          />
          <div className="py-3 text-right">
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={(isSubmitting || props.mostrar)}
              text={props.editar ? "Actualizar" : "Guardar"}
            />
          </div>
          {isSubmitting && <Spinner />}
        </div>
      )}
    </Formik>
  );
};
