import { useSelector } from "react-redux";

const WithPermission = (props) => {
  const { permiso, children } = props;
  const permisos = useSelector((state) => state?.loginReducer?.data?.userData?.permisos);

  const checkPermission = () => {
    let isOk = false;
    permisos.forEach(perm => {
      if (perm.name === permiso)
        isOk = true;
    });
    return isOk;
  }



  return checkPermission() ? children : <></>;
};

export default WithPermission;