export default function NotFound() {
  return (
    <div className="bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <div className="sm:ml-6">
              <h1 className="text-4xl font-extrabold text-maivi-primaryLightBlue tracking-tight sm:text-5xl">Ruta no encontrada</h1>
          </div>
        </main>
      </div>
    </div>
  )
}