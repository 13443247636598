export default function Button(props) {
    return(
        <button
            className={`w-full flex justify-center py-1.5 px-4 border border-transparent rounded-md 
            shadow-sm text-sm font-medium text-white bg-secondary hover:bg-primary 
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${props.className}`}
            onClick={props.onClick}
            type={props.type}
            disabled={props.disabled || false}
        >
        {props.icon}
        {props.text}
      </button>
    )
}