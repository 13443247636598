/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect, useCallback } from "react";
import { Formik } from "formik";
import { customPost } from "../../../utils/services";
import { useSelector } from "react-redux";
import { ErrorMessage } from "formik";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/solid";
import Dropzone from 'react-dropzone'
import Spinner from "../../../components/common/Spinner";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import Table from "../../../components/common/Table";
import toast from 'react-hot-toast';
import makeAnimated from "react-select/animated";
import SelectAC from "react-select";
import Label from "../../../components/common/Label";
import Toggle from "../../../components/common/Toggle";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  nombre: Yup.string().required("nombre es requerido"),
  descripcion: Yup.string().required("descripcion es requerida"),
  precio: Yup.string().required("precio es requerido"),
  categoria_id: Yup.string().required("categoria es requerida"),
});

export const Form = (props) => {
  const animatedComponents = makeAnimated();
  const token = useSelector((state) => state.loginReducer.data.token);
  const [detalleProductos, setDetalleProductos] = useState([]);
  const [listadoProductos, setListadoProductos] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState("");
  const [listadoCategorias, setListadoCategorias] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const [loadingImage, setLoadingImage] = useState(false);
  const [opciones, setOpciones] = useState([]);


  const { REACT_APP_API_URL } = process.env;

  const subirImagen = (data) => {
    setLoadingImage(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("imagen", data[0]);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${REACT_APP_API_URL}/guardar-imagen`, requestOptions)
      .then(response => response.text())
      .then(result => {
        setUrlImage(result);
        setLoadingImage(false);
        toast.success("imagen subida con exito.")
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    customPost({}, `catalogos/productos`, token).then((data) => {
      let datos = [];
      data.data.forEach(element => {
        let row = {
          value: element.id,
          label: `${element.codigo}  /  ${element.descripcion} / ${element?.unidad?.nombre}  /  ${element.presentacion}`
        }

        datos.push(row);
      });
      setListadoProductos(datos);
    });
  }, []);

  useEffect(() => {
    let datos = [];
    props.catalogos.categorias.forEach(element => {
      let row = {
        value: element.id,
        label: `${element.nombre}`
      }

      datos.push(row);
    });
    setListadoCategorias(datos);
  }, [])

  const changeSelectProducto = (e) => {
    setProductoSeleccionado(e);
  };

  const changeSelectTipoPago = (e) => {
    setCategoriaSeleccionada(e);
  };

  return (
    <Formik
      initialValues={{
        nombre: "",
        descripcion: "",
        precio: "",
        producto_directo: false,
        categoria_id: "",
        producto: "",
        cantidad: "",
        imagen: "imagen/imagen.png"
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        let body = {};
        values.imagen = urlImage;

        if (values.producto_directo) {
          body = {
            nombre: values.nombre,
            descripcion: values.descripcion,
            imagen: values.imagen,
            precio: Number(values.precio),
            producto_directo: values.producto_directo,
            categoria_id: values.categoria_id,
            producto_id: productoSeleccionado.value,
            opciones: opciones
          }
        } else {
          body = {
            nombre: values.nombre,
            descripcion: values.descripcion,
            imagen: values.imagen,
            precio: Number(values.precio),
            producto_directo: values.producto_directo,
            categoria_id: values.categoria_id,
            insumos: detalleProductos,
            opciones: opciones
          }
        }

        if (values.producto_directo && productoSeleccionado === "") {
          toast.error("producto es requerido")
        } else if (!values.producto_directo && detalleProductos.length === 0) {
          toast.error("detalle de productos son requeridos")
        } else {
          await customPost(body, 'menus', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setCrear(false);
                resetForm({});
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        }
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
        <div className="grid grid-cols-12 gap-2 p-3 mb-14">
          <div className="col-span-12 xl:col-span-9 lg:col-span-8 md:col-span-8">
            <div className="grid grid-cols-12 gap-2 border rounded-lg p-3 items-end hover:shadow-md hover:shadow-blue-100">
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6">
                <Input
                  type="text"
                  name="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  validacion={true}
                  label="Nombre menu"
                />
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6">
                <Input
                  type="text"
                  name="descripcion"
                  value={values.descripcion}
                  onChange={handleChange}
                  validacion={true}
                  label="Descripcion"
                />
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6">
                <Input
                  type="number"
                  name="precio"
                  value={values.precio}
                  onChange={handleChange}
                  validacion={true}
                  label="Precio"
                />
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6">
                <Label text="Categoria" />
                <SelectAC
                  isMulti={false}
                  placeholder="Buscar y seleccionar"
                  components={animatedComponents}
                  name="categoria"
                  value={categoriaSeleccionada}
                  className="basic-multi-select text-xs w-full"
                  options={listadoCategorias}
                  onChange={(e) => {
                    changeSelectTipoPago(e);
                    setFieldValue('categoria_id', e.value);
                  }}
                  maxMenuHeight={140}
                />
                <ErrorMessage
                  name="categoria_id"
                  component="span"
                  className="text-xs text-red-600"
                />
              </div>

              {
                (values.producto_directo) &&
                <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6">
                  <Label text="Producto" />
                  <SelectAC
                    isMulti={false}
                    placeholder="Buscar y seleccionar"
                    components={animatedComponents}
                    name="producto_id"
                    value={productoSeleccionado}
                    className="basic-multi-select text-xs w-full"
                    options={listadoProductos}
                    onChange={changeSelectProducto}
                    maxMenuHeight={140}
                  />
                </div>
              }
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6 flex flex-col items-center justify-center">
                <Label text="Producto directo" />
                <Toggle enabled={values.producto_directo} name="producto_directo" />
              </div>
              {
                (!values.producto_directo) &&
                <div className="col-span-12 mt-4">
                  <div className="grid grid-cols-12 gap-2 items-end">
                    <div className="col-span-8">
                      <Label text="Producto" />
                      <SelectAC
                        isMulti={false}
                        placeholder="Buscar y seleccionar"
                        components={animatedComponents}
                        name="producto_id"
                        value={productoSeleccionado}
                        className="basic-multi-select text-xs w-full"
                        options={listadoProductos}
                        onChange={changeSelectProducto}
                        maxMenuHeight={140}
                      />
                    </div>
                    <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-6">
                      <Input
                        type="number"
                        name="cantidad"
                        value={values.cantidad}
                        onChange={handleChange}
                        validacion={true}
                        label="Cantidad"
                      />
                    </div>
                    <div className="col-span-1">
                      <Button
                        type="button"
                        onClick={() => {
                          if (productoSeleccionado === "") {
                            toast.error("producto es requerido")
                          } else if (values.cantidad === "") {
                            toast.error("cantidad es requeridoa")
                          }

                          else {
                            let row = {
                              descripcion: productoSeleccionado.label,
                              producto_id: productoSeleccionado.value,
                              cantidad: values.cantidad,
                            }
                            setDetalleProductos([...detalleProductos, row]);
                            values.cantidad = "";
                          }
                        }}
                        text="Agregar"
                      />
                    </div>
                  </div>
                  <Label text="Listado de productos" className="mt-3" />
                  <Table
                    headers={[
                      'Descripcion',
                      'Cantidad',
                      'Eliminar'
                    ]}
                    body={detalleProductos?.map((item, index) => (
                      <tr
                        key={index}
                      >
                        <td className="px-2 py-2 text-xs text-primaryFont">
                          {item?.descripcion}
                        </td>
                        <td className="px-2 text-xs text-primaryFont">
                          {item?.cantidad}
                        </td>

                        <td className="px-2 text-xs text-primaryFont">
                          <button
                            type="button"
                            onClick={() => {
                              let filter = detalleProductos.filter(x => x.producto_id !== item.producto_id)
                              setDetalleProductos(filter);
                            }}
                            className={`hover:bg-red-200 hover:rounded-lg p-1 tooltip`}
                          >
                            <TrashIcon className="h-6 w-6  text-red-600 hover:text-red-600" />
                            <span className="tooltiptext">Eliminar</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  />
                </div>
              }
              <div className="col-span-2">
                {isSubmitting && <Spinner />}
                <div className="flex flex-row">
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={(isSubmitting || props.mostrar)}
                    text="Guardar"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-4 border rounded-lg hover:shadow-md p-3">
            <div className="border rounded-lg p-2 cursor-pointer bg-blue-100">
              <Dropzone onDrop={acceptedFiles => {
                subirImagen(acceptedFiles);
              }}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className="text-center">
                      <input {...getInputProps()} />
                      <p className="text-sm text-primaryFont">Seleccionar imagen para el menu</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              {
                loadingImage && <Spinner />
              }
            </div>
            <br />
            <div className="flex flex-row justify-center bg-blue-50 rounded-lg mb-2 -mt-4">
              <img src={urlImage} alt="" className="w-24" />
            </div>
            <div className="flex flex-row items-end">
              <Input
                type="text"
                name="opcion"
                value={values.opcion}
                onChange={handleChange}
                validacion={true}
                label="Opcion"
              />
              <button
                type="button"
                className="tooltip"
                onClick={() => {
                  if (values.opcion === "") {
                    toast.error("opcion es requerida")
                  } else {
                    setOpciones([...opciones, values.opcion]);
                    values.opcion = "";
                  }
                }}
              >
                <PlusCircleIcon className="h-7 w-7 text-primary hover:text-secondary cursor-pointer" />
                <span className="tooltiptext">Agregar opcion</span>
              </button>
            </div>
            <Label text="Opciones" className="mt-3" />
            <Table
              headers={[
                'Opcion',
                'Eliminar'
              ]}
              body={opciones?.map((item, index) => (
                <tr
                  key={index}
                >
                  <td className="px-2 py-2 text-xs text-primaryFont">
                    {item}
                  </td>
                  <td className="px-2 text-xs text-primaryFont">
                    <button
                      type="button"
                      onClick={() => {
                        let filter = opciones.filter(x => x !== item)
                        setOpciones(filter);
                      }}
                      className={`hover:bg-red-200 hover:rounded-lg p-1 tooltip`}
                    >
                      <TrashIcon className="h-6 w-6  text-red-600 hover:text-red-600" />
                      <span className="tooltiptext">Eliminar</span>
                    </button>
                  </td>
                </tr>
              ))}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
