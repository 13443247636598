import { React, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { customPost } from "../../../utils/services";
import { Barras } from "../charts/barras";
import NumberFormat from "react-number-format";
import Label from "../../../components/common/Label";
import Table from "../../../components/common/Table";
import { backgroundColor, borderColor, nombreDia, nombreMes } from "../../../utils/utils";

export const ReporteReservacionse = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [cargandoPorYear, setCargandoPorYear] = useState(false);
  const [cargandoPorMes, setCargandoPorMes] = useState(false);
  const [cargandoPorDia, setCargandoPorDia] = useState(false);
  const [cargandoData, setCargandoData] = useState(false);
  const [reservacionesYear, setReservacionesPorYear] = useState([]);
  const [ventasPorMes, setVentasPorMes] = useState([]);
  const [ventasPorDia, setVentasPorDia] = useState([]);
  const [datos, setDatos] = useState([]);
  const [labels, setLabels] = useState([]);
  const [graficaTitulo, setGraficaTitulo] = useState([]);
  const [dataDia, setDataDia] = useState({});

  const getReservacionesPorYear = () => {
    setDataDia({});
    setCargandoPorYear(true);
    customPost({}, `reportes/reservas/anio`, token).then(
      (data) => {
        setReservacionesPorYear(data.data);
        setCargandoPorYear(false);
        setGraficaTitulo("Total de reservaciones por año");
        let reg = [];
        let reg2 = [];
        data?.data?.forEach(element => {
          reg.push(Number(element.total));
          reg2.push(`${element.anio}`);
        });
        setDatos(reg);
        setLabels(reg2);
      }
    );
  };

  const getReservacionesPorMes = async (year) => {
    setCargandoPorMes(true);
    setDataDia({});
    await customPost(
      {},
      `reportes/reservas/mes?anio=${year}`,
      token
    ).then((data) => {
      setVentasPorMes(data.data);
      setCargandoPorMes(false);

      setGraficaTitulo(`Total de reservaciones por mes - ${year}`);
      let reg = [];
      let reg2 = [];
      data?.data?.forEach(element => {
        reg.push(Number(element.total));
        reg2.push(`${nombreMes(element.mes)}`);
      });
      setDatos(reg);
      setLabels(reg2);

    });
  };

  const getReservacionesPordia = async (mes, year) => {
    setCargandoPorDia(true);
    setDataDia({});
    await customPost(
      {},
      `reportes/reservas/dia?mes=${mes}&anio=${year}`,
      token
    ).then((data) => {

      setVentasPorDia(data.data);
      setCargandoPorDia(false);
      setGraficaTitulo(`Total de reservaciones por dia - ${nombreMes(mes)} / ${year}`);
      let reg = [];
      let reg2 = [];
      data?.data?.forEach(element => {

        reg.push(Number(element.total));
        reg2.push(`${nombreDia(year, mes, element?.dia)}-${element?.dia}`);
      });

      setDatos(reg);
      setLabels(reg2);
    });
  };

  const getReservacionesData = async (dia, mes, anio) => {
    setCargandoData(true);
    await customPost(
      { dia, mes, anio },
      `reportes/reservas/data`,
      token
    ).then((data) => {
      setCargandoData(false);
      setDataDia({
        titulo: `Datos de ${nombreDia(anio, mes, dia)}-${dia}-${nombreMes(mes)}`,
        data: data.data
      });
    });
  };

  useState(() => {
    getReservacionesPorYear();
  }, [])
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Total",
        data: datos,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      },
    ],
  };

  return (
    <Formik
      initialValues={{
        asesor: "",
      }}
      onSubmit={async (values) => { }}
    >
      {({ values }) => (
        <div className="grid grid-cols-12 gap-2 mt-3 mx-2 xl:mx-4 lg:mx-4">

          <div className="col-span-12 xl:col-span-5 lg:col-span-5 border rounded-lg hover:shadow-md p-4">
            <div className="flex flex-row justify-center items-center text-primaryFont">
              <span className="">REPORTE DE RESERVACIONES</span>
            </div>
            <hr className="mb-3" />
            <div className="mt-3">
              <Label text="Reservaciones por año" />
              <Table
                cargando={cargandoPorYear}
                headers={["Año", "Total"]}
                body={reservacionesYear?.map((item, index) => (
                  <tr
                    key={`ventas-${index}`}
                    id={`ventas-${index}`}
                    className="hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      let row;
                      reservacionesYear.forEach((element, indice) => {
                        let row = document.getElementById(`ventas-${indice}`);
                        row.classList.remove("bg-green-100");
                      });

                      row = document.getElementById(`ventas-${index}`);
                      row.classList.add("bg-green-100");
                      getReservacionesPorMes(item.anio);
                    }}
                  >
                    <td className="px-2 py-2 text-xs text-primaryFont">
                      {item?.anio}
                    </td>
                    <td className="px-2 py-2 text-xs text-primaryFont">
                      <NumberFormat
                        value={item?.total}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Q"}
                      />
                    </td>
                  </tr>
                ))}
              />
            </div>
            <div className="grid grid-cols-12 gap-2 mt-3">
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6">
                <Label text="Reservaciones por mes" />
                <div className="max-h-96 overflow-y-auto">
                  <Table
                    headers={["Mes", "Total"]}
                    cargando={cargandoPorMes}
                    body={ventasPorMes?.map((item, index) => (
                      <tr
                        key={`mes-${index}`}
                        id={`mes-${index}`}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          let row;
                          ventasPorMes.forEach((element, indice) => {
                            let row = document.getElementById(`mes-${indice}`);
                            row.classList.remove("bg-green-100");
                          });

                          row = document.getElementById(`mes-${index}`);
                          row.classList.add("bg-green-100");
                          getReservacionesPordia(item.mes, item.anio);
                        }}
                      >
                        <td className="px-2 py-2 text-xs text-primaryFont">
                          {nombreMes(Number(item?.mes))} / {item.anio}
                        </td>
                        <td className="px-2 py-2 text-xs text-primaryFont">
                          <NumberFormat
                            value={item?.total}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Q"}
                          />
                        </td>
                      </tr>
                    ))}
                  />
                </div>

              </div>
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6">
                <Label text="Reservaciones por dia" />
                <div className=" max-h-96 overflow-y-auto">
                  <Table
                    cargando={cargandoPorDia}
                    headers={["Dia", "Total"]}
                    body={ventasPorDia?.map((item, index) => (
                      <tr
                        key={`orden-${index}`}
                        id={`orden-${index}`}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          getReservacionesData(item.dia, item.mes, item.anio);
                        }}
                      >
                        <td className="px-2 py-2 text-xs text-primaryFont">
                          {item?.dia} / {nombreMes(item.mes)} / {item?.anio}
                        </td>
                        <td className="px-2 py-2 text-xs text-primaryFont">
                          <NumberFormat
                            value={item?.total}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Q"}
                          />
                        </td>
                      </tr>
                    ))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 xl:col-span-7 lg:col-span-7 border rounded-lg hover:shadow-md p-4">
            <div className="flex flex-row justify-center items-center text-primaryFont">
              <span className="text-center mx-3 text-xs xl:text-base lg:text-base md:text-sm">{graficaTitulo}</span>
            </div>
            <hr className="mb-3" />
            <div className="h-96  w-full">
              <Barras data={data} />
            </div>
          </div>

          {dataDia?.data && <div className="col-span-12 xl:col-span-12 lg:col-span-12 border rounded-lg hover:shadow-md p-4">
            <div className="flex flex-row justify-center items-center text-primaryFont">
              <span className="text-center mx-3 text-xs xl:text-base lg:text-base md:text-sm">{dataDia.titulo}</span>
            </div>
            <hr className="mb-3" />
            <div className="h-96  w-full">
              <Table
                cargando={cargandoData}
                headers={["Entrada", "Costo", "Habitacion", "Personas", "Cliente"]}
                body={dataDia?.data.map((item, index) => (
                  <tr
                    key={`diaData-${index}`}
                    id={`diaData-${index}`}
                    className="hover:bg-gray-100 cursor-pointer"
                  >
                    <td className="px-2 py-2 text-xs text-primaryFont">
                      {item?.entrada}
                    </td>
                    <td className="px-2 py-2 text-xs text-primaryFont">
                      <NumberFormat
                        value={item?.costo}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Q"}
                      />
                    </td>
                    <td className="px-2 py-2 text-xs text-primaryFont">
                      {item?.habitacion}
                    </td>
                    <td className="px-2 py-2 text-xs text-primaryFont">
                      {item?.personas}
                    </td>
                    <td className="px-2 py-2 text-xs text-primaryFont">
                      {item?.nombre} {item?.apellido}
                    </td>
                  </tr>
                ))}
              />
            </div>
          </div>}
        </div>
      )}
    </Formik>
  );
};
