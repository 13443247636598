
export const types = {
    login:'Login',
    checkStatus:'CheckStatus',
    municipios: 'Municipios',
    departamentos:'Departamentos',
    ChangeSearch:'ChangeSearch',
    ChangeBuscar:'ChangeBuscar',
    menuActive:'MenuActive'
}
