import { useState } from 'react'
import { Switch } from '@headlessui/react'
import { useField } from 'formik';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Toggle(props) {
  const [enabled, setEnabled] = useState(props.enabled)
  const [field, meta, helpers] = useField(props.name);
  const { setValue } = helpers;
  const handleChange = (e) => {
    setValue(e);
    setEnabled(e);
    props?.onChange();
  }

  console.warn(enabled, field, meta);

  return (
    <Switch
      disabled={props.disabled}
      checked={props.enabled}
      onChange={handleChange}
      className={classNames(
        props.enabled ? 'bg-secondary' : 'bg-gray-300',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary'
      )}
    >
      <span className="sr-only">Use setting True</span>
      <span
        aria-hidden="true"
        className={classNames(
          props.enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-primary shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
    </Switch>
  )
}