/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { Formik } from "formik";
import { customPost } from "../../../utils/services";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/Spinner";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("nombre requerido")
        .max(255, "Máximo 100 caracteres"),
});

export const AddEmpresa = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);

    return (
        <Formik
            initialValues={{
                nombre: "",
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                await customPost(values, 'clientes/empresa/store', token)
                    .then(async (data) => {
                        if (data?.status >= 200 && data?.status <= 210) {
                            toast.success(data?.data?.msg);
                            props?.setAddEmpresa(false);
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status >= 400 && error.response.status <= 450) {
                            toast.error(error.response.data.error);
                        } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                        }
                    });
            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <div className="mx-5">
                    <Input
                        type="text"
                        name="nombre"
                        value={values.nombre}
                        onChange={handleChange}
                        validacion={true}
                        label="Nombre empresa"
                        className="mb-2"
                    />
                    <Button 
                        text="Guardar"
                        onClick={handleSubmit}
                        className="mb-4"
                    />
                    {isSubmitting && <Spinner />}
                </div>
            )}
        </Formik>
    );
};
