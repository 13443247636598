import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { TableClientes } from "../modulos/hotel/clientes/listar/clientes";
import { TableHabitaciones } from "../modulos/hotel/habitaciones/listar/habitaciones";
import { TableUsuarios } from "../modulos/seguridad/usuarios/listar/usuarios";
import { TableRoles } from "../modulos/seguridad/roles/listar/roles";
import { TableReservaciones } from "../modulos/hotel/home_reservaciones/listar";
import { ReservacionesPendientes } from "../modulos/hotel/reservaciones/pendientes";
import { Reservaciones } from "../modulos/hotel/reservaciones/listar";
import { ModuloReportes } from "../modulos/reportes";
import { ModuloReportesBi } from "../modulos/reportes-bi";
import { ReporteReservacionse } from "../modulos/reportes/reservaciones";
import { ReporteCancelaciones } from "../modulos/reportes/cancelaciones";
import { ModuloCatalogosRestaurante } from "../modulos/restaurante/catalogos";
import { TableCatalogoRestauranteCategoria } from '../modulos/restaurante/catalogos/categorias';
import { TableCatalogoRestauranteUnidadesMedida } from '../modulos/restaurante/catalogos/unidades-medida';
import { TableCatalogoRestauranteMarca } from '../modulos/restaurante/catalogos/marcas';
import { TableRestauranteProductos } from '../modulos/restaurante/productos';
import { TableRestauranteProveedores } from '../modulos/restaurante/proveedores';
import { TableRestauranteCompras } from '../modulos/restaurante/compras';
import { TableRestauranteMenus } from '../modulos/restaurante/menu';
import NotFound from '../pages/not_found';
import PageHotel from "../pages/hotel";
import PageRestaurante from "../pages/restaurante";
import Calendario from "../modulos/hotel/calendario";
import PrivateRoute from "./privateRoute";
import Home from "../modulos/home";
import { TableRestauranteVentas } from "../modulos/restaurante/ventas";
import { TableRestauranteOrdenes } from "../modulos/restaurante/ordenes";
import { TableOrdenesDelDia } from "../modulos/restaurante/ordenes-del-dia";
import { ModuloCortesHotel } from "../modulos/cortes/hotel";
import { MovimientosHotel } from "../modulos/cortes/hotel/movimientos";
import { CajaHotel } from "../modulos/cortes/hotel/caja";
import { ModuloCortesRestaurante } from "../modulos/cortes/restaurante";
import { MovimientosRestaurante } from "../modulos/cortes/restaurante/movimientos";
import { CajaRestaurante } from "../modulos/cortes/restaurante/caja";
import { ReportePorcentajeOcupacional } from "../modulos/reportes/porcentaje-ocupacional";
import { TableGastos } from "../modulos/gastos";

class AppRouter extends React.Component {
  render() {
    return (
      <Switch>
        <PrivateRoute
          path={`/`}
          exact
          permiso="reserva-list"
          component={Home}
        />
        <PrivateRoute
          path={`/hotel`}
          exact
          permiso="reserva-list"
          component={PageHotel}
        />

        <PrivateRoute
          path={`/cortes-hotel`}
          exact
          permiso="reserva-list"
          component={ModuloCortesHotel}
        />
        <PrivateRoute
          path={`/cortes-hotel/movimientos`}
          exact
          permiso="reserva-list"
          component={MovimientosHotel}
        />
        <PrivateRoute
          path={`/cortes-hotel/caja`}
          exact
          permiso="reserva-list"
          component={CajaHotel}
        />


        <PrivateRoute
          path={`/cortes-restaurante`}
          exact
          permiso="reserva-list"
          component={ModuloCortesRestaurante}
        />
        <PrivateRoute
          path={`/cortes-restaurante/movimientos`}
          exact
          permiso="reserva-list"
          component={MovimientosRestaurante}
        />
        <PrivateRoute
          path={`/cortes-restaurante/caja`}
          exact
          permiso="reserva-list"
          component={CajaRestaurante}
        />

        <PrivateRoute
          path={`/pagos`}
          exact
          permiso="reserva-list"
          component={ReservacionesPendientes}
        />
        <PrivateRoute
          path={`/restaurante`}
          exact
          permiso="reserva-list"
          component={PageRestaurante}
        />
        <PrivateRoute
          path={`/listado-reservaciones`}
          exact
          permiso="reserva-list"
          component={TableReservaciones}
        />
        <PrivateRoute
          path={`/reservaciones`}
          exact
          permiso="reserva-list"
          component={Reservaciones}
        />
        <PrivateRoute
          path={`/calendario`}
          exact
          permiso="reserva-list"
          component={Calendario}
        />
        <PrivateRoute
          path={`/clientes`}
          exact
          permiso="customer-list"
          component={TableClientes}
        />
        <PrivateRoute
          path={`/habitaciones`}
          exact
          permiso="room-list"
          component={TableHabitaciones}
        />
        <PrivateRoute
          path={`/usuarios`}
          exact
          permiso="user-list"
          component={TableUsuarios}
        />
        <PrivateRoute
          path={`/roles`}
          exact
          permiso="role-list"
          component={TableRoles}
        />
        <PrivateRoute
          path={`/reportes`}
          exact
          permiso="room-list"
          component={ModuloReportes}
        />

        <PrivateRoute
          path={`/reportes-bi`}
          exact
          permiso="report-earnings"
          component={ModuloReportesBi}
        />

        <PrivateRoute
          path={`/reportes/reservaciones`}
          exact
          permiso="room-list"
          component={ReporteReservacionse}
        />
        <PrivateRoute
          path={`/reportes/cancelaciones`}
          exact
          permiso="room-list"
          component={ReporteCancelaciones}
        />
        <PrivateRoute
          path={`/reportes/porcentaje-ocupacional`}
          exact
          permiso="room-list"
          component={ReportePorcentajeOcupacional}
        />
        <PrivateRoute
          path={`/restaurante/catalogos`}
          exact
          permiso="room-list"
          component={ModuloCatalogosRestaurante}
        />
        <PrivateRoute
          path={`/restaurante/catalogos/categoria`}
          exact
          permiso="categoria-list"
          component={TableCatalogoRestauranteCategoria}
        />
        <PrivateRoute
          path={`/restaurante/catalogos/unidades-medida`}
          exact
          permiso="unidad-medida-list"
          component={TableCatalogoRestauranteUnidadesMedida}
        />
        <PrivateRoute
          path={`/restaurante/catalogos/marcas`}
          exact
          permiso="marca-list"
          component={TableCatalogoRestauranteMarca}
        />
        <PrivateRoute
          path={`/restaurante/productos`}
          exact
          permiso="producto-list"
          component={TableRestauranteProductos}
        />
        <PrivateRoute
          path={`/restaurante/proveedores`}
          exact
          permiso="room-list"
          component={TableRestauranteProveedores}
        />
        <PrivateRoute
          path={`/restaurante/menus`}
          exact
          permiso="menu-list"
          component={TableRestauranteMenus}
        />
        <PrivateRoute
          path={`/restaurante/compras`}
          exact
          permiso="compra-list"
          component={TableRestauranteCompras}
        />
        <PrivateRoute
          path={`/restaurante/ventas`}
          exact
          permiso="venta-list"
          component={TableRestauranteVentas}
        />
        <PrivateRoute
          path={`/restaurante/ordenes`}
          exact
          permiso="venta-edit"
          component={TableRestauranteOrdenes}
        />
        <PrivateRoute
          path={`/restaurante/ordenes-del-dia`}
          exact
          permiso="venta-edit"
          component={TableOrdenesDelDia}
        />
        <PrivateRoute
          path={`/gastos-hotel`}
          exact
          permiso="venta-edit"
          component={TableGastos}
        />
        <PrivateRoute
          path={`/restaurante/gastos-restaurante`}
          exact
          permiso="venta-edit"
          component={TableGastos}
        />
        <Route
          path="*"
          component={NotFound}
        />
      </Switch>
    );
  }
}

export default withRouter(AppRouter);
