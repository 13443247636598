/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { Formik } from "formik";
import { customPost, customPut } from "../../../../utils/services";
import { useSelector } from "react-redux";
import Select from "../../../../components/common/Select";
import Spinner from "../../../../components/common/Spinner";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("nombre requerido"),
  detalle: Yup.string().required('detalle es requerido')
});

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);

  return (
    <Formik
      initialValues={{
        nombre: props.editar ? props.value.nombre : "",
        detalle: props.editar ? props.value.detalle : "",
        capacidad: props.editar ? props.value.capacidad : 0,
        precio_p1: props.editar ? props.value.precio_p1 : "",
        precio_p2: props.editar ? props.value.precio_p2 : "",
        precio_p3: props.editar ? props.value.precio_p3 : "",
        precio_p4: props.editar ? props.value.precio_p4 : "",
        estado: props.editar ? props.value.estado_id : 1,
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        if (props.editar) {
          await customPut(props.value.id, values, 'habitaciones', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setEdit(false);
                props.setCrear(false);
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        } else {
          await customPost(values, 'habitaciones', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setCrear(false);
                resetForm({});
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        }
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <div className="mx-5">
          <div className="mb-3">
            <Input
              type="text"
              name="nombre"
              value={values.nombre}
              onChange={handleChange}
              validacion={true}
              label="Nombre"
            />
          </div>
          <div className="mb-3">
            <Input
              type="text"
              name="detalle"
              value={values.detalle}
              onChange={handleChange}
              validacion={true}
              label="Detalle"
            />
          </div>
          <div className="mb-3">
            <Input
              type="text"
              name="capacidad"
              value={values.capacidad}
              onChange={handleChange}
              validacion={true}
              label="Capacidad"
            />
          </div>
          <div className="grid grid-cols-12 gap-2">
          <div className="col-span-6 mb-3">
                <Input
                  type="number"
                  name="precio_p1"
                  value={values.precio_p1}
                  onChange={handleChange}
                  validacion={true}
                  label="Precio P1"
                />
              </div>
              <div className="col-span-6 mb-3">
                <Input
                  type="number"
                  name="precio_p2"
                  value={values.precio_p2}
                  onChange={handleChange}
                  validacion={true}
                  label="Precio P2"
                />
              </div>
              <div className="col-span-6 mb-3">
                <Input
                  type="number"
                  name="precio_p3"
                  value={values.precio_p3}
                  onChange={handleChange}
                  validacion={true}
                  label="Precio P3"
                />
              </div>
              <div className="col-span-6 mb-3">
                <Input
                  type="number"
                  name="precio_p4"
                  value={values.precio_p4}
                  onChange={handleChange}
                  validacion={true}
                  label="Precio P4"
                />
              </div>
          
          </div>
          <div className="mb-3">
            <Select
              name="estado"
              value={values.estado}
              onChange={handleChange}
              validacion={true}
              label="Estado"
              valueInit=""
              selectInit="seleccionar estado"
              list={
                props?.catalogos?.estados?.map((item) => {
                  return (
                    <option value={item.id} key={item.nombre}>{item.nombre}</option>
                  );
                })
              }
            />
          </div>
          <div className="py-3 text-right">
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={(isSubmitting || props.mostrar)}
              text={props.editar ? "Actualizar" : "Guardar"}
            />
          </div>
          {isSubmitting && <Spinner />}
        </div>
      )}
    </Formik>
  );
};
