/* eslint-disable import/no-anonymous-default-export */
import { loginReducer } from "./login";
import { statusReducer } from "./auth/statusReducer";
import { inputChange } from "./search";
import { inputChangeBuscar } from "./buscar";
import { municipiosReducer } from "./muncipios";
import { departamentosReducer } from "./departamentos";
import { menuActiveReducer } from "./menuActive";

export default {
    loginReducer,
    statusReducer,
    inputChange,
    inputChangeBuscar,
    municipiosReducer,
    departamentosReducer,
    menuActiveReducer
};
