import { CheckCircleIcon } from "@heroicons/react/solid";
import { XCircleIcon } from "@heroicons/react/solid";

export default function Alert(props) {
  if (props.type === 'error') {
    return (
      <div className="rounded-md bg-red-50 p-3 border">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-xs font-medium text-red-800">
             {props.mensaje}
            </h3>
          </div>
        </div>
      </div>
    );
  } else if (props.type === 'success') {
    return (
      <div className="rounded-md bg-green-50 p-3 border">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-xs font-medium text-green-800">
              {props.mensaje}
            </p>
          </div>
        </div>
      </div>
    );
  }
  else if (props.type === 'info') {
    return (
      <div className="rounded-md bg-blue-50 p-3 border">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-xs font-medium text-blue-800">
              {props.mensaje}
            </p>
          </div>
        </div>
      </div>
    );
  }

  else if (props.type === 'warning') {
    return (
      <div className="rounded-md bg-yellow-50 p-3 border">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-xs font-medium text-yellow-800">
              {props.mensaje}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
