import { React, useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { store } from './redux/store/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Login from '../modulos/seguridad/login';
import Home from '../components/dashboard';
import '../index.css';

export const App = () => {
  const [loginState, setLoginState] = useState({});

  useEffect(() => {
    const state = store.getState()?.loginReducer?.data;
    setLoginState(state);
  }, []);

  const islogin = (loginState?.token === undefined) ? false : true;
 
  return (
    <Provider store={store}>
      <BrowserRouter>
        {(islogin) ? <Home /> : <Login />}
      </BrowserRouter>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: '#32aace',
            color: '#fff',
          },
          success: {
            duration: 5000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
            style: {
              background: '#5888cc',
              color: '#fff',
            },
          },
          error: {
            duration: 5000,
            theme: {
              primary: 'white',
              secondary: 'black',
            },
            style: {
              background: '#cc3838',
              color: '#fff',
            },
          },
        }}
      />
    </Provider>
  )
}

