/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { customGet, customPost } from "../../../utils/services";
import Spinner from "../../../components/common/Spinner";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import toast from "react-hot-toast";
import makeAnimated from "react-select/animated";
import SelectAC from "react-select";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    cantidad: Yup.string().required("cantidad es requerida"),
});

export const AgregarMenu = (props) => {
    const animatedComponents = makeAnimated();
    const [menus, setMenus] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState([]);
    const [menuSeleccionado, setMenuSeleccionado] = useState("");

    useEffect(() => {

        customGet(undefined, "menus/create", props.token).then((data) => {
            let datos = [];
            data.categorias.forEach(element => {
                let row = {
                    value: element.id,
                    label: `${element.nombre}`
                }
                datos.push(row);
            });

            setCategorias(datos);
        });

        customPost(undefined, "catalogos/menus", props.token).then((data) => {
            setMenus(data.data);
        });
    }, []);

    const changeSelectProducto = (e) => {
        setMenuSeleccionado(e);
    };

    const changeSelectCategoria = (e) => {
        setCategoriaSeleccionada(e);
    };

    const getMenuItems = () => {
        let datos = [];

        menus.forEach(element => {
            if (categoriaSeleccionada?.value === element.categoria.id) {
                let row = {
                    value: element.id,
                    label: `${element?.categoria?.nombre}  / ${element?.nombre} / Q${element.precio}`
                }
                datos.push(row);
            }
        });

        return datos;
    }

    return (
        <Formik
            initialValues={{
                cantidad: 1,
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {

                let body = {
                    orden_id: props.registro.id,
                    menu_id: menuSeleccionado.value,
                    cantidad: values.cantidad,
                    opciones: ""
                }

                if (menuSeleccionado === '') {
                    toast.error('debe seleccionar un menu');
                } else {
                    await customPost(body, "ventas/detalle/agregar", props.token)
                        .then(async (data) => {
                            if (data?.status >= 200 && data?.status <= 210) {
                                toast.success('menu agregado con exito');
                                props.setRefetch(true);
                                props.setAgregarMenu(false);

                                setTimeout(() => {
                                    props.setRefetch(false);
                                }, 600);
                            }
                        })
                        .catch(function (error) {
                            if (error.response.status >= 400 && error.response.status <= 450) {
                                toast.error(error.response.data.error);
                            } else if (error.response.status >= 500) {
                                toast.error("Error interno comuniquese con el administrador");
                            }
                        });
                }
            }}
        >
            {({
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
            }) => (
                <div className="grid grid-cols-12 gap-2 p-3">
                    <div className="col-span-12">
                        <SelectAC
                            isMulti={false}
                            placeholder="Categoria"
                            components={animatedComponents}
                            name="categoria"
                            value={categoriaSeleccionada}
                            className="basic-multi-select text-xs w-full"
                            options={categorias}
                            onChange={changeSelectCategoria}
                            maxMenuHeight={140}
                        />
                    </div>

                    <div className="col-span-12">
                        <SelectAC
                            isMulti={false}
                            placeholder="Buscar y seleccionar"
                            components={animatedComponents}
                            name="producto_id"
                            value={menuSeleccionado}
                            className="basic-multi-select text-xs w-full"
                            options={getMenuItems()}
                            onChange={changeSelectProducto}
                            maxMenuHeight={140}
                        />
                    </div>
                    <div className="col-span-12">
                        <Input
                            type="number"
                            name="cantidad"
                            value={values.cantidad}
                            onChange={handleChange}
                            validacion={true}
                            label="Cantidad"
                        />
                    </div>
                    <div className="col-span-12">
                        {isSubmitting ? (
                            <Spinner />
                        ) : (
                            <Button text="Actualizar" onClick={handleSubmit} />
                        )}
                    </div>
                </div>
            )}
        </Formik>
    );
};
