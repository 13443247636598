/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { Formik } from "formik";
import { customPost, customPut } from "../../../../utils/services";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/common/Spinner";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  nombre: Yup.string().required("nombre requerido")
});

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);

  return (
    <Formik
      initialValues={{
        nombre: props.editar ? props.value.nombre : "",
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        if (props.editar) {
          await customPut(props.value.id, values, 'marcas', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setEdit(false);
                props.setCrear(false);
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el adminimedidastrador");
              }
            });
        } else {
          await customPost(values, 'marcas', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setCrear(false);
                resetForm({});
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        }
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <div className="mx-5">
          <div className="mb-3">
            <Input
              type="text"
              name="nombre"
              value={values.nombre}
              onChange={handleChange}
              validacion={true}
              label="Nombre"
            />
          </div>
          <div className="py-3 text-right">
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={(isSubmitting || props.mostrar)}
              text={props.editar ? "Actualizar" : "Guardar"}
            />
          </div>
          {isSubmitting && <Spinner />}
        </div>
      )}
    </Formik>
  );
};
