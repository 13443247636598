import Table from "../../../components/common/Table";
import Label from "../../../components/common/Label";
import moment from "moment";

export const Detalle = (props) => {
  const totalProductos = (productos) => {
    let total = 0;
    productos.forEach((element) => {
      total += Number(element.precio);
    });

    return total.toFixed(2);
  };

  const totalPagos = (pagos) => {
    let total = 0;
    pagos.forEach((element) => {
      total += Number(element.monto);
    });

    return total.toFixed(2);
  };

  return (
    <div className="grid grid-cols-12 gap-2">
      <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 ">
        <div className="bg-white border rounded-md hover:shadow-lg">
          <div className=" border-gray-200 p-0">
            <div className="sm:divide-y sm:divide-gray-200 ">
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-sm font-medium text-gray-500">
                  Numero factura
                </div>
                <div className="text-sm text-gray-900 sm:col-span-2">
                  {props?.registro?.no_factura}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-sm font-medium text-gray-500">
                  Fecha factura
                </div>
                <div className="text-sm text-gray-900 sm:col-span-2">
                  {props?.registro?.fecha_factura}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-sm font-medium text-gray-500">
                  Proveedor
                </div>
                <div className="text-sm text-gray-900 sm:col-span-2">
                  {props?.registro?.proveedor?.nombre_comercial}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-sm font-medium text-gray-500">Total</div>
                <div className="text-sm text-gray-900 sm:col-span-2">
                  {props?.registro?.total}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-sm font-medium text-gray-500">Saldo</div>
                <div className="text-sm text-gray-900 sm:col-span-2">
                  {props?.registro?.saldo}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-sm font-medium text-gray-500">
                  Fecha creacion
                </div>
                <div className="text-sm text-gray-900 sm:col-span-2">
                  {moment(props?.registro?.created_at).format(
                    "DD-MM-YYYY HH:mm:ss"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-6 border rounded-lg hover:shadow-lg p-3">
        <Label text="Listado de productos" />
        <Table
          headers={["Cantidad", "Precio", "Producto"]}
          body={props?.registro?.detalle.map((item, index) => (
            <tr key={index}>
              <td className="px-2 py-2 text-xs text-primaryFont">
                {item?.cantidad}
              </td>
              <td className="px-2 py-2 text-xs text-primaryFont">
                {item?.precio}
              </td>
              <td className="px-2 text-xs text-primaryFont">
                {item?.producto?.descripcion}
              </td>
            </tr>
          ))}
        />
        <div className="mt-3">
          <label className="bg-green-100 text-green-600 p-2 rounded-lg font-bold">
            Total: Q{totalProductos(props?.registro?.detalle)}
          </label>
        </div>
        <br />
        <Label text="Listado de pagos" />
        <Table
          headers={["Tipo pago", "Monto", "Fecha registro"]}
          body={props?.registro?.pagos.map((item, index) => (
            <tr key={index}>
              <td className="px-2 py-2 text-xs text-primaryFont">
                {item?.tipo_pago?.nombre}
              </td>
              <td className="px-2 text-xs text-primaryFont">{item?.monto}</td>
              <td className="px-2 text-xs text-primaryFont">
                {moment(item?.created_at).format("DD-MM-YYYY HH:mm:ss")}
              </td>
            </tr>
          ))}
        />
        <div className="mt-3">
          <label className="bg-green-100 text-green-600 p-2 rounded-lg font-bold">
            Total: Q{totalPagos(props?.registro?.pagos)}
          </label>
        </div>
      </div>
    </div>
  );
};
