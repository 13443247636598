/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { customPost } from "../../../utils/services";
import { useSelector } from "react-redux";
import { TrashIcon } from "@heroicons/react/solid";
import Spinner from "../../../components/common/Spinner";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import Table from "../../../components/common/Table";
import toast from 'react-hot-toast';
import makeAnimated from "react-select/animated";
import SelectAC from "react-select";
import Label from "../../../components/common/Label";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  no_factura: Yup.string().required("campo requerido"),
  fecha_factura: Yup.string().required("fecha es requerida"),
});

export const Form = (props) => {
  const animatedComponents = makeAnimated();
  const token = useSelector((state) => state.loginReducer.data.token);
  const [detalleProductos, setDetalleProductos] = useState([]);
  const [detallePagos, setDetallePagos] = useState([]);
  const [listadoProductos, setListadoProductos] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState("");
  const [listadoProveedores, setListadoProveedores] = useState([]);
  const [proveedorSeleccionado, setProveedorSeleccionado] = useState("");
  const [listadoTipoPagos, setListadoTipoPagos] = useState([]);
  const [tipoPagoSeleccionado, setTipoPagoSeleccionado] = useState("");

  useEffect(() => {
    customPost({}, `catalogos/productos`, token).then((data) => {
      let datos = [];
      data.data.forEach(element => {
        let row = {
          value: element.id,
          label: `${element?.codigo} / ${element?.descripcion} / ${element?.unidad?.nombre} / ${element?.presentacion} / ${element?.cantidad_presentacion}`
        }

        datos.push(row);
      });
      setListadoProductos(datos);
    });
  }, []);

  useEffect(() => {
    customPost({}, `catalogos/proveedores`, token).then((data) => {
      let datos = [];
      data.data.forEach(element => {
        let row = {
          value: element.id,
          label: `${element.nombre_comercial}`
        }

        datos.push(row);
      });
      setListadoProveedores(datos);
    });
  }, []);

  useEffect(() => {
    let datos = [];
    props.catalogos.tipo_pagos.forEach(element => {
      let row = {
        value: element.id,
        label: `${element.nombre}`
      }

      datos.push(row);
    });
    setListadoTipoPagos(datos);
  }, [])

  const changeSelectProducto = (e) => {
    setProductoSeleccionado(e);
  };

  const changeSelectProveedor = (e) => {
    setProveedorSeleccionado(e);
  };

  const changeSelectTipoPago = (e) => {
    setTipoPagoSeleccionado(e);
  };

  const totalProductos = (productos) =>{
    let total = 0
    productos.forEach(element => {
      total += Number(element.precio)
    });

    return total.toFixed(2);
  }

  const totalPagos = (pagos) =>{
    let total = 0
    pagos.forEach(element => {
      total += Number(element.monto)
    });

    return total.toFixed(2);
  }

  return (
    <Formik
      initialValues={{
        no_factura:  "",
        fecha_factura: "",
        proveedor_id: "",
        producto_id: "",
        cantidad: "",
        precio: "",
        monto: ""
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        if(proveedorSeleccionado === ""){
          toast.error("Proveedor es requerido");
        } 

        else if(detalleProductos.length === 0 ){
          toast.error("Detalle de productos son requeridos")
        } 
        
        else if(detallePagos.length === 0){
          toast.error("Pagos son requeridos")
        }  else {
          let body = {
            no_factura: values.no_factura,
            fecha_factura : values.fecha_factura,
            proveedor_id: proveedorSeleccionado.value,
            detalle: detalleProductos,
            pagos: detallePagos
          }
  
          await customPost(body, 'compras', token)
          .then(async (data) => {
            if (data?.status >= 200 && data?.status <= 210) {
              toast.success(data?.data?.msg);
              props.setCrear(false);
              resetForm({});
            }
          })
          .catch(function (error) {
            if (error.response.status >= 400 && error.response.status <= 450) {
              toast.error(error.response.data.error);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
            }
          });
        }
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-3 border rounded-lg hover:shadow-lg hover:shadow-blue-100 p-3">
            <div className="mb-3">
              <Input
                type="text"
                name="no_factura"
                value={values.no_factura}
                onChange={handleChange}
                validacion={true}
                label="Numero factura"
              />
            </div>
            <div className="mb-3">
              <Input
                type="date"
                name="fecha_factura"
                value={values.fecha_factura}
                onChange={handleChange}
                validacion={true}
                label="Fecha factura"
              />
            </div>
            <div className="mb-3">
              <Label text="Proveedor" />
              <SelectAC
                isMulti={false}
                placeholder="Buscar y seleccionar"
                components={animatedComponents}
                name="proveedor"
                value={proveedorSeleccionado}
                className="basic-multi-select text-xs w-full"
                options={listadoProveedores}
                onChange={changeSelectProveedor}
                maxMenuHeight={140}
              />
            </div>

          </div>
          <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-9 border rounded-lg hover:shadow-lg hover:shadow-blue-100 p-3">
            <div className="grid grid-cols-12 gap-1 items-end">
              <div className="col-span-12 xl:col-span-6 lg:col-span-4 md:col-span-4 mb-3">
                <Label text="Producto" />
                <SelectAC
                  isMulti={false}
                  placeholder="Buscar y seleccionar"
                  components={animatedComponents}
                  name="producto_id"
                  value={productoSeleccionado}
                  className="basic-multi-select text-xs w-full"
                  options={listadoProductos}
                  onChange={changeSelectProducto}
                  maxMenuHeight={140}
                />
              </div>
              <div className="col-span-12 xl:col-span-2 lg:col-span-3 md:col-span-4 mb-3">
                <Input
                  type="number"
                  name="cantidad"
                  value={values.cantidad}
                  onChange={handleChange}
                  validacion={true}
                  label="Cantidad"
                />
              </div>
              <div className="col-span-12 xl:col-span-2 lg:col-span-3 md:col-span-4 mb-3">
                <Input
                  type="number"
                  name="precio"
                  value={values.precio}
                  onChange={handleChange}
                  validacion={true}
                  label="Precio"
                />
              </div>
              <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-2 mb-3">
                <Button
                  type="button"
                  onClick={() => {
                    if (productoSeleccionado === "") {
                      toast.error("producto es requerido")
                    } else if (values.cantidad === "") {
                      toast.error("cantidad es requerida")
                    } else if (values.precio === "") {
                      toast.error("precio es requerido")
                    }

                    else {
                      let row = {
                        descripcion: productoSeleccionado.label,
                        producto_id: productoSeleccionado.value,
                        cantidad: values.cantidad,
                        precio: values.precio
                      }
                      setDetalleProductos([...detalleProductos, row]);
                      values.producto_id = "";
                      values.cantidad = "";
                      values.precio = "";
                    }
                  }}
                  text="Agregar"
                />
              </div>

            </div>
            <Table
              headers={[
                'Descripcion',
                'Cantidad',
                'Precio',
                'Eliminar'
              ]}
              body={detalleProductos?.map((item, index) => (
                <tr
                  key={index}
                >
                  <td className="px-2 text-xs text-primaryFont">
                    {item?.descripcion}
                  </td>
                  <td className="px-2 text-xs text-primaryFont">
                    {item?.cantidad}
                  </td>
                  <td className="px-2 text-xs text-primaryFont">
                    {item?.precio}
                  </td>
                  <td className="px-2 text-xs text-primaryFont">
                  <button
                      type="button"
                      onClick={() => {
                        let filter = detalleProductos.filter(x => x.producto_id !== item.producto_id)
                        setDetalleProductos(filter);
                      }}
                      className={`hover:bg-red-200 hover:rounded-lg p-1 tooltip`}
                    >
                      <TrashIcon className="h-6 w-6  text-red-600 hover:text-red-600" />
                      <span className="tooltiptext">Eliminar</span>
                    </button>
                  </td>
                </tr>
              ))}
            />
            <div className="mt-3">
              <label className="bg-green-100 text-green-600 p-2 rounded-lg font-bold">Total: Q{ totalProductos(detalleProductos)}</label>
            </div>
      
          </div>
          <div className="col-span-12 border rounded-lg bg-blue-50 p-2"><h3>Sección de pagos</h3></div>
          <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-3 border rounded-lg hover:shadow-lg hover:shadow-blue-100 p-3">
            <div className="mb-3">
              <Label text="Tipo pago" />
              <SelectAC
                isMulti={false}
                placeholder="Buscar y seleccionar"
                components={animatedComponents}
                name="tipo_pago"
                value={tipoPagoSeleccionado}
                className="basic-multi-select text-xs w-full"
                options={listadoTipoPagos}
                onChange={changeSelectTipoPago}
                maxMenuHeight={140}
              />
            </div>
            <div className="mb-3">
              <Input
                type="number"
                name="monto"
                value={values.monto}
                onChange={handleChange}
                validacion={true}
                label="Monto"
              />
            </div>
            <Button
              type="button"
              onClick={() => {
                if (tipoPagoSeleccionado === "") {
                  toast.error("tipo pago es requerido")
                } else if (values.monto === "") {
                  toast.error("monto es requerido")
                }

                else {
                  let row = {
                    descripcion: tipoPagoSeleccionado.label,
                    tipo_pago_id: tipoPagoSeleccionado.value,
                    monto: values.monto,
                  }
                  setDetallePagos([...detallePagos, row]);
                  values.monto = "";
                }
              }}
              text="Agregar pago"
            />
          </div>
          <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-9 border rounded-lg hover:shadow-lg hover:shadow-blue-100 p-3">
            <div className="grid grid-cols-12 gap-1 items-end">
            </div>
            <Label text="Listado de pagos" />
            <Table
              className="mb-3"
              headers={[
                'Descripcion',
                'Monto',
                'Eliminar'
              ]}
              body={detallePagos?.map((item, index) => (
                <tr
                  key={index}
                >
                  <td className="px-2 text-xs text-primaryFont">
                    {item?.descripcion}
                  </td>
                  <td className="px-2 text-xs text-primaryFont">
                    {item?.monto}
                  </td>

                  <td className="px-2 text-xs text-primaryFont">
                  <button
                      type="button"
                      onClick={() => {
                        let filter = detallePagos.filter(x => x.tipo_pago_id !== item.tipo_pago_id)
                        setDetallePagos(filter);
                      }}
                      className={`hover:bg-red-200 hover:rounded-lg p-1 tooltip`}
                    >
                      <TrashIcon className="h-6 w-6  text-red-600 hover:text-red-600" />
                      <span className="tooltiptext">Eliminar</span>
                    </button>
                  </td>
                </tr>
              ))}
            />
           <div className="mt-3">
           <label className="bg-green-100 text-green-600 p-2 rounded-lg font-bold">Total: Q{ totalPagos(detallePagos)}</label>
           </div>
          </div>
          {isSubmitting && <Spinner />}
          <div className="flex flex-row">
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={(isSubmitting || props.mostrar)}
              text="Guardar"
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
