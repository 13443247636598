/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { healthCheck } from "../modulos/seguridad/login/services";
import { store } from "../app/redux/store/store";
import { checkStatus } from "../app/redux/actions/auth/checkStatus";
import { useSelector } from "react-redux";
import { saveLogin } from "./redux/actions/login";
import WithPermission from "./WithPermission";

const PrivateRoute = ({permiso, children, component: Component,...rest }) => {
  const sesionState = useSelector((state) => state?.statusReducer?.data);
  const isOk = sesionState?.success === true ? true : false;
  const token = useSelector((state) => state?.loginReducer?.data?.token);

  const authCheck = async (token) => {
    let estado = await healthCheck(token);

    if (estado?.data?.success === false) {
      store.dispatch(saveLogin({}));
      window.location = "/";
    } else {
      store.dispatch(checkStatus(estado));
    }
  };

  useEffect(() => {
    authCheck(token);
  }, [children]);

  const Componente = () => <WithPermission permiso={permiso}><Component/></WithPermission>;

  return (
    <Route
      {...rest}
      component={Componente}
      render={() => isOk
        ? children
        : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;