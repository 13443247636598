export const Detalle = (props) => {
    return (
        <div className="bg-white border rounded-md">
            <div className=" border-gray-200 p-0">
                <div className="sm:divide-y sm:divide-gray-200 ">
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Nombre
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.nombre}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Fecha creacion
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                        {props?.registro?.created_at} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
