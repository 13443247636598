import { useEffect, useState } from 'react'
import { customGet } from '../../../utils/services'
import { useSelector } from "react-redux";
import { CheckCircleIcon, FilterIcon, LockClosedIcon, PlusCircleIcon, CurrencyDollarIcon  } from '@heroicons/react/outline';
import { IconBed } from "../../../components/common/icons";
import { Reservar } from './reservar';
import { Filtros } from './filtrar';
import Spinner from '../../../components/common/Spinner';
import moment from "moment";
import ModalLarge from '../../../components/common/ModalLarge';
import ModalSmall from '../../../components/common/Modal';
import WithPermission from '../../../app/WithPermission';

export default function Reservaciones() {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [reservaciones, setReservaciones] = useState({});
  const [registro, setRegistro] = useState({});
  const [reservaOld, setReservaOld] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [reservar, setReservar] = useState(false);
  const [habitacion, setHabitacion] = useState({});
  const [idHabitacion, setIdHabitacion] = useState(0);
  const [refetch, setRefetch] = useState(false);
  const [editar, setEditar] = useState(false);
  const [filtrar, setFiltrar] = useState(false);
  const [filtros, setFiltros] = useState(()=> {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (params?.fecha) {
      return params.fecha;
    }

    return moment().format("YYYY-MM-DD");
  });

  useEffect(() => {
    customGet(undefined, `reservacion?fecha=${filtros}`, token).then((data) => {
      setCargando(false)
      setReservaciones(data);
    });
  }, [token, refetch, filtros]);

  const tipoEstado = (element, habitacion) => {
    let day = 1000 * 60 * 60 * 48;
    let today = (new Date().getTime()) - day;
    let dayCompare = new Date(element.fecha).getTime();

    if (element?.reserva === null) {
      return (
        <td key={element.fecha} className={`hover:shadow-lg ${(element.reserva === null) ? 'hover:bg-green-200' : 'hover:bg-primaryOpacity'} ${(element?.reserva !== null) ? 'bg-green-200' : ''}`}>
          <div className="flex flex-row items-center justify-center">
            {
              (dayCompare < today && (habitacion.id < 78 || habitacion.id > 94)) ?
                `` :
                <WithPermission permiso="reserva-create">
                  <button
                    type="button"
                    onClick={() => {
                      setReservaOld(dayCompare < today ? true : false);
                      setIdHabitacion(habitacion.id);
                      setRegistro(element);
                      setHabitacion(habitacion);
                      setEditar(false);
                      setReservar(true);
                    }}
                    className="tooltip"
                  >
                    <PlusCircleIcon className="h-6 w-6 text-primary" />
                    <span className="tooltiptext">hacer una reservacion</span>
                  </button>
                </WithPermission>
            }

          </div>
        </td>
      )
    } else if (element?.reserva?.estado?.nombre === "Reservado") {
      return (
        <td key={element.fecha} className={`hover:shadow-lg ${(element.reserva !== null) ? 'hover:bg-yellow-200' : 'hover:bg-primaryOpacity'}
          ${(element?.reserva !== null) ? 'bg-yellow-100' : ''}`} onClick={() => {
            setReservaOld(dayCompare < today ? true : false);
            setIdHabitacion(habitacion.id);
            setRegistro(element);
            setHabitacion(habitacion);
            setEditar(true);
            setReservar(true);
          }}>
          <div className='flex flex-row justify-between mx-2'>
            <span className="text-xs font-extrabold text-yellow-800 cursor-pointer">{element?.reserva?.estado?.nombre}
            </span>
            <div className='flex justify-center flex-row'>
            <IconBed className="h-5 w-5 text-yellow-800" />
          {
            Number(element?.reserva?.saldo) > 0 ?
            <span className='tooltip'>
            <CurrencyDollarIcon className="h-5 w-5 text-red-800 ml-1" />
            <span className="tooltiptext">saldo pendiente</span>
          </span> : ''
          }
            </div>
          </div>
          <div className="flex flex-col items-start justify-start pl-2">
            <span className="items-center text-xs font-medium text-yellow-700 cursor-pointer">
              {element?.reserva?.cliente?.nombre} {element?.reserva?.cliente?.apellido}
            </span>
            <span className="items-center text-xs font-medium text-yellow-700 cursor-pointer">
              Telefono: {element?.reserva?.cliente?.celular}
            </span>
          </div>
        </td>
      )
    } else if (element?.reserva?.estado?.nombre === "Confirmado") {
      return (
        <td key={element.fecha} className={`hover:shadow-lg ${(element.reserva !== null) ? 'hover:bg-blue-200' : 'hover:bg-primaryOpacity'}
          ${(element?.reserva !== null) ? 'bg-blue-100' : ''}`} onClick={() => {
            setIdHabitacion(habitacion.id);
            setRegistro(element);
            setHabitacion(habitacion);
            setEditar(true);
            setReservar(true);
          }}>
          <div className='flex flex-row justify-between mx-2'>
            <span className="text-xs font-extrabold text-blue-800 cursor-pointer" >{element?.reserva?.estado?.nombre}
            </span>
            <div className="flex flex-row">
            <CheckCircleIcon className="h-6 w-6 text-primary" />
            {
            Number(element?.reserva?.saldo) > 0 ?
            <span className='tooltip'>
            <CurrencyDollarIcon className="h-6 w-6 text-red-800 ml-1" />
            <span className="tooltiptext">saldo pendiente</span>
          </span> : ''
          }
            </div>

          </div>
          <div className="flex flex-col items-start justify-start pl-2">

            <span className="items-center text-xs font-medium text-blue-700 cursor-pointer">
              {element?.reserva?.cliente?.nombre} {element?.reserva?.cliente?.apellido}
            </span>
            <span className="items-center text-xs font-medium text-blue-700 cursor-pointer">
              Telefono: {element?.reserva?.cliente?.celular}
            </span>
          </div>
        </td>
      )
    } else if (element?.reserva?.estado?.nombre === "Ocupado") {
      return (
        <td key={element.fecha} className={`hover:shadow-lg ${(element.reserva !== null) ? 'hover:bg-red-200' : 'hover:bg-primaryOpacity'}
        ${(element?.reserva !== null) ? 'bg-red-100' : ''}`} onClick={() => {
            setIdHabitacion(habitacion.id);
            setRegistro(element);
            setHabitacion(habitacion);
            setEditar(true);
            setReservar(true);
          }}>
          <div className='flex flex-row justify-between mx-2'>
            <span className="text-xs font-extrabold text-red-800 cursor-pointer">{element?.reserva?.estado?.nombre}
            </span>
           <div className='flex flex-row'>
           <LockClosedIcon className="h-6 w-6 text-red-800" />
            {
            Number(element?.reserva?.saldo) > 0 ?
            <span className='tooltip'>
            <CurrencyDollarIcon className="h-6 w-6 text-red-800 ml-1" />
            <span className="tooltiptext">saldo pendiente</span>
          </span> : ''
          }
           </div>
          </div>
          <div className="flex flex-col items-start justify-start pl-2">

            <span className="items-center text-xs font-medium text-red-500 cursor-pointer">
              {element?.reserva?.cliente?.nombre} {element?.reserva?.cliente?.apellido}
            </span>
            <span className="items-center text-xs font-medium text-red-500 cursor-pointer">
              Telefono: {element?.reserva?.cliente?.celular}
            </span>
          </div>
        </td>
      )
    }
  }

  return (
    (cargando) ?
      <div className='mt-20'>
        <Spinner />
      </div> :
      <div className="grid grid-cols-12 m-3 border shadow-lg altura-calendar">
        <div className='col-span-12 overflow-x-auto'>
          <table className="table w-full border border-gray-200">
            <thead className="sticky top-0 z-30 bg-white shadow">
              <tr className='divide-y divide-x'>
                <th className={`py-3 px-8 text-center font-sans text-primaryFont text-xs column-fixed
              bg-gray-100 border-collapse border border-gray-200`}>
                  <p className='w-20 font-sans text-sm text-center'>Habitacion</p>
                  <button
                    className="bg-primary tooltip rounded-full p-1"
                    type="button"
                    onClick={() => {
                      setFiltrar(true);
                    }}
                  >
                    <FilterIcon className="h-4 w-4 text-white cursor-pointer" />
                    <span className="tooltiptext">Filtrar reservas</span>
                  </button>
                </th>
                {
                  reservaciones?.fechas?.map((item, index) => {
                    return <th key={'res-date-' + index} className={`py-2 px-16 text-center text-primaryFont`}>
                      <p className='w-20 font-sans text-sm'>{item?.diaTexto} </p>
                      <p className='w-20 font-normal text-xs'>{moment(item?.fecha).format("DD-MM-YYYY")}</p>
                    </th>
                  })
                }
              </tr>
            </thead>
            <tbody className="divide-y divide-x divide-gray-200">
              {
                reservaciones?.habitaciones?.map((item) => (
                  <tr className='divide-y divide-x h-16 hover:bg-gray-100 cursor-pointer' key={'res-room-' + item.id}>
                    <td className='text-center text-gray-700 column-fixed bg-gray-100 border-collapse border border-gray-200 text-sm z-20'>
                      <p className='text-sm font-extrabold'>{item.nombre}</p>
                      <p className='text-xs text-gray-500'>{item.detalle}</p>
                    </td>
                    {
                      item?.fechas?.map((element) => (
                        tipoEstado(element, item)
                      ))
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        {reservar && (
          <ModalLarge
            title={editar ? `Reservacion para ${registro.reserva.cliente.nombre} ${registro.reserva.cliente.apellido}` : `Reservacion para habitacion ${habitacion.nombre}`}
            open={reservar}
            closeModal={() => { }}
            cerrar={() => { setReservar(false) }}
            children={
              <Reservar
                registro={registro}
                idHabitacion={idHabitacion}
                editar={editar}
                setRefetch={setRefetch}
                setReservar={setReservar}
                reservaOld={reservaOld}
              />
            }
          />
        )}
        {filtrar && (
          <ModalSmall
            title='Filtrar por fecha'
            open={filtrar}
            closeModal={() => { }}
            cerrar={() => { setFiltrar(false) }}
            children={
              <Filtros
                setFiltrar={setFiltrar}
                setFiltros={setFiltros}
              />
            }
          />
        )}
      </div>

  )
}
