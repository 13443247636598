import { useSelector } from 'react-redux';
import AppRouter from "../../app/router";
import MenuHotel from './menu_hotel';
import MenuRestaurante from './menu_restaurante';
import MenuVacio from './menu_vacio';

export default function NavBar() {
  const menuActive = useSelector((state) => state?.menuActiveReducer?.data);

  const tipoMenu = () =>{
    if(menuActive === "hotel"){
      return <MenuHotel/>
    } else if(menuActive === "restaurante") {
      return <MenuRestaurante />
    } else{
      return <MenuVacio />
    }
  }

  return (
    <div>
      {tipoMenu()}
      <AppRouter />
    </div>
  )
}