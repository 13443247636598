import { types } from "../../types/types";

export const statusReducer = (state = {}, action) => {
    switch (action.type) {
        case types.checkStatus:
            return {
                data: action.payload,
            }
    
        default:
            return state;
    }
}