/* eslint-disable react-hooks/exhaustive-deps */
import { React,useEffect,useState } from "react";
import { Formik } from "formik";
import { customGet, customPost } from "../../../utils/services";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/Spinner";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import toast from 'react-hot-toast';
import * as Yup from "yup";
import Select from "../../../components/common/Select";

const validaciones = Yup.object().shape({
    monto: Yup.string().required("nombre es requerido"),
    tipo_pago: Yup.string().required("tipo de pago es requerido")
});

export const AplicarPagos = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [tipoPagos,setTipoPagos] = useState([]);

    useEffect(() => {
        customGet(undefined, `reservacion/cliente/pagos/create`, token).then((data) => {
          setTipoPagos(data.tipoPago)
        });
    }, []);

    return (
        <Formik
            initialValues={{
                monto: "",
                tipo_pago:"",
                cliente_id: props.cliente,
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                await customPost(values, 'reservacion/cliente/pagos/store', token)
                    .then(async (data) => {
                        if (data?.status >= 200 && data?.status <= 210) {
                            toast.success(data?.data?.msg);
                            props?.setRefetch(true);
                            props?.setPagos(false);
                            setTimeout(() => {
                                props?.setRefetch(false);
                            }, 300);
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status >= 400 && error.response.status <= 450) {
                            toast.error(error.response.data.error);
                        } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                        }
                    });
            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <div className="mx-5">
                    <Input
                        type="number"
                        name="monto"
                        value={values.monto}
                        onChange={handleChange}
                        validacion={true}
                        label="Monto"
                    />
                    <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 mb-3 mt-3">
                        <Select
                            name="tipo_pago"
                            value={values.tipo_pago}
                            onChange={handleChange}
                            validacion={true}
                            label="Tipo de pago"
                            valueInit=""
                            selectInit="seleccionar tipo de pago"
                            list={
                                tipoPagos?.map((item, index) => {
                                    return (
                                    <option value={item?.id} key={item?.nombre + index}>{item?.nombre}</option>
                                    );
                                })
                            }
                        />
                        </div>
                    <Button 
                        text="Guardar"
                        onClick={handleSubmit}
                        className="mb-4"
                    />
                    {isSubmitting && <Spinner />}
                </div>
            )}
        </Formik>
    );
};
