import Table from "../../../components/common/Table";
import Label from "../../../components/common/Label";
import moment from "moment";

export const Detalle = (props) => {
  const totalProductos = (productos) => {
    let total = 0;
    productos.forEach((element) => {
      total += (Number(element?.precio) * Number(element?.cantidad) );
    });

    return total.toFixed(2);
  };

  return (
    <div className="grid grid-cols-12 gap-2">
      <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 ">
        <div className="bg-white border rounded-md hover:shadow-lg">
          <div className=" border-gray-200 p-0">
            <div className="sm:divide-y sm:divide-gray-200 ">
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-sm font-medium text-gray-500">
                  Habitacion
                </div>
                <div className="text-sm text-gray-900 sm:col-span-2">
                  {props?.registro?.habitacion}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-sm font-medium text-gray-500">
                  Mesa
                </div>
                <div className="text-sm text-gray-900 sm:col-span-2">
                  {props?.registro?.mesa}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-sm font-medium text-gray-500">
                  Observacion
                </div>
                <div className="text-sm text-gray-900 sm:col-span-2">
                  {props?.registro?.observacion}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-sm font-medium text-gray-500">
                  Vendedor
                </div>
                <div className="text-sm text-gray-900 sm:col-span-2">
                  {props?.registro?.vendedor}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-sm font-medium text-gray-500">
                  Estado
                </div>
                <div className="text-sm text-gray-900 sm:col-span-2">
                  {props?.registro?.estado?.nombre}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-sm font-medium text-gray-500">
                  Fecha
                </div>
                <div className="text-sm text-gray-900 sm:col-span-2">
                  {moment(props?.registro?.created_at).format(
                    "DD-MM-YYYY HH:mm:ss"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-6 border rounded-lg hover:shadow-lg p-3">
        <Label text="Listado de orden" />
        <Table
          headers={["Nombre","Cantidad", "Precio unitario","Total"]}
          body={props?.registro?.detalle.map((item, index) => (
            <tr key={index}>
              <td className="px-2 py-2 text-xs text-primaryFont">
                {item?.menu?.nombre}
              </td>
              <td className="px-2 py-2 text-xs text-primaryFont">
                {item?.cantidad}
              </td>
              <td className="px-2 py-2 text-xs text-primaryFont">
                {item?.precio}
              </td>
              <td className="px-2 py-2 text-xs text-primaryFont">
                Q{(item?.precio * Number(item?.cantidad)).toFixed(2)}
              </td>
            </tr>
          ))}
        />
        <div className="mt-3">
          <label className="bg-green-100 text-green-600 p-2 rounded-lg font-bold">
            Total: Q{totalProductos(props?.registro?.detalle)}
          </label>
        </div>
      </div>
    </div>
  );
};
