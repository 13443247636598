import Spinner from "./Spinner";

export default function BotonesConfirmar(props) {
  return (
    <div className="py-3 text-center">
      <button
        className="inline-flex w-5/12 m-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
            rounded-md text-white bg-red-400 hover:bg-red-600 focus:outline-none focus:ring-2 
            focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
        onClick={props.cancelar}
      >
        No
      </button>
      <button
        className="inline-flex w-5/12 m-2  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
            rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 
            focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
        onClick={props.aceptar}
      >
        Si
      </button>
      <br />
      {
        (props.isloading) && <Spinner />
      }
    </div>
  );
}
