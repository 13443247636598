/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { Formik } from "formik";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";

export const Filtros = (props) => {
    return (
        <Formik
            initialValues={{
                fecha_inicio: "",
                fecha_fin: "",
            }}
            onSubmit={async (values) => {
                props.setFiltros({
                    fecha_inicio: values.fecha_inicio,
                    fecha_final: values.fecha_fin
                });
                props.setFiltrar(false);
            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <div className="mx-5">
                    <Input
                        type="date"
                        name="fecha_inicio"
                        value={values.fecha_inicio}
                        onChange={handleChange}
                        className="mb-2"
                        label="Fecha inicio"
                    />
                    <Input
                        type="date"
                        name="fecha_fin"
                        value={values.fecha_fin}
                        onChange={handleChange}
                        label="Fecha fin"
                    />

                    <div className="py-3 text-right">
                        <Button
                            type="submit"
                            onClick={handleSubmit}
                            text="Filtrar"
                        />
                    </div>
                </div>
            )}
        </Formik>
    );
};
