import React from 'react';
import {keyframes} from 'styled-components';
import styled from 'styled-components';

const borderDarker = '#DEE4E7';
const colorSecondary = '#003580';

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

const Spin = styled.div`
  height: ${props => props.objective && '100px'};
  position: ${props => props.objective && 'relative'};
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainLoader = styled.div`
  position: relative;
  left: ${props => props.left};
  right: ${props => props.right};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  animation: ${rotate} 0.75s linear infinite;
  border: 2px solid ${borderDarker};
  border-top-color: ${colorSecondary};
  border-right-color: ${colorSecondary};
  border-radius: 100%;  
`;

function Spinner({
  objective = false,
  size = 35,
  top = null,
  bottom = 'auto',
  left = null,
  right = 'auto'
}) {
  return (
    <Spin objective={objective}>
      <MainLoader
        size={size}
        top={top}
        bottom={bottom}
        right={right}
        left={left}
      />
    </Spin>
  );
}

export default Spinner;