import moment from "moment";

export const Detalle = (props) => {
    return (
        <div className="bg-white border rounded-md">
            <div className=" border-gray-200 p-0">
                <div className="sm:divide-y sm:divide-gray-200 ">
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Descripcion
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.descripcion}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Monto
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                        {props?.registro?.monto} 
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Categoria
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                        {props?.registro?.categoria?.nombre} 
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Sub Categoria
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                        {props?.registro?.sub_categoria?.nombre} 
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Fecha creacion
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                        {moment(props?.registro?.created_at).format('DD-MM-YYYY HH:MM:ss')} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
