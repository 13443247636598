import { types } from "../types/types";

export const departamentosReducer = (state = {}, action) => {
    switch (action.type) {
        case types.departamentos:
            return {
                data: action.payload,
            }
        default:
            return state;
    }
}

