import Table from "../../../components/common/Table";
import Label from "../../../components/common/Label";
import moment from "moment";

export const Detalle = (props) => {
    return (
        <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6">
            <div className="flex flex-row justify-center bg-blue-50 rounded-lg mb-2">
                <img src={props.registro.imagen} alt="" className="rounded-lg mb-1 w-40"/>
                </div>
                <div className="bg-white border rounded-md  hover:shadow-lg">
                    <div className=" border-gray-200 p-0">
                        <div className="sm:divide-y sm:divide-gray-200 ">
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Nombre
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    {props?.registro?.nombre}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Categoria
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    {props?.registro?.categoria?.nombre}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Descripcion
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    {props?.registro?.descripcion}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Precio
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    Q{props?.registro?.precio}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Fecha creacion
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    {moment(props?.registro?.created_at).format("DD-MM-YYYY HH:mm:ss")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-6 border rounded-lg hover:shadow-lg p-3">
                <Label text="Listado de productos" />
                <Table
                    headers={[
                        'Cantidad',
                        'Producto',
                        'Unidad de medida',
                        'Presentacion',
                    ]}
                    body={
                        (props?.registro?.producto_directo === 1) ? 
                        <tr>
                            <td className="px-2 py-2 text-xs text-primaryFont">
                                {1}
                            </td>
                            <td className="px-2 text-xs text-primaryFont">
                                {props?.registro?.producto?.descripcion}
                            </td>
                            <td className="px-2 text-xs text-primaryFont">
                                {props?.registro?.producto?.unidad?.nombre}
                            </td>
                            <td className="px-2 text-xs text-primaryFont">
                                {props?.registro?.producto?.presentacion}
                            </td>
                        </tr>
                        :
                        props?.registro?.insumos.map((item, index) => (
                        <tr
                            key={index}
                        >
                            <td className="px-2 py-2 text-xs text-primaryFont">
                                {item?.cantidad}
                            </td>
                            <td className="px-2 text-xs text-primaryFont">
                                {item?.producto?.descripcion}
                            </td>
                            <td className="px-2 text-xs text-primaryFont">
                                {item?.producto?.unidad?.nombre}
                            </td>
                            <td className="px-2 text-xs text-primaryFont">
                                {item?.producto?.presentacion}
                            </td>
                        </tr>
                    )) 
                }
                />
            </div>
        </div>
    );
};
