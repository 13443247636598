/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState } from "react";
import { Formik } from "formik";
import { customPost, customPut } from "../../utils/services";
import { useSelector } from "react-redux";
import Select from "../../components/common/Select";
import Spinner from "../../components/common/Spinner";
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  descripcion: Yup.string()
    .required("descripcion es requerida")
    .max(255, "Máximo 100 caracteres"),
  monto: Yup.string().required('monto es requerido'),
  tipo_pago_id: Yup.string().required('tipo_pago es requerida'),
  categoria_id: Yup.string().required('categoria es requerida'),
  sub_categoria_id: Yup.string().required('sub categoria es requerida')
});

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [subCategorias, setSubCategorias] = useState(() => {
    if (props.editar) {
      return props.catalogos.filter(x => x.id === Number(props.value.categoria_id))[0].sub_categoria
    } else {
      return [];
    }
  });

  return (
    <Formik
      initialValues={{
        descripcion: props.editar ? props.value.descripcion : "",
        monto: props.editar ? props.value.monto : "",
        categoria_id: props.editar ? props.value.categoria_id : "",
        sub_categoria_id: props.editar ? props.value.sub_categoria_id : "",
        tipo_pago_id: props.editar ? props.value.tipo_pago_id : "",
        restaurante: props.restaurante,
        hotel: props.hotel,
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        if (props.editar) {
          await customPut(props.value.id, values, 'gastos', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setEdit(false);
                props.setCrear(false);
                props.setRefetch(true);
                setTimeout(() => {
                  props.setRefetch(false);
                }, 300);
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        } else {
          await customPost(values, 'gastos', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setCrear(false);
                props.setRefetch(true);
                setTimeout(() => {
                  props.setRefetch(false);
                }, 300);
                resetForm({});
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        }
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <div className="mx-5">
          <div className="mb-3">
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-12">
                <Input
                  type="text"
                  name="descripcion"
                  value={values.descripcion}
                  onChange={handleChange}
                  validacion={true}
                  label="Descripcion"
                />
              </div>
              <div className="col-span-12">
                <Input
                  type="number"
                  name="monto"
                  value={values.monto}
                  onChange={handleChange}
                  validacion={true}
                  label="Monto"
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Select
              name="tipo_pago_id"
              value={values.tipo_pago_id}
              onChange={handleChange}
              validacion={true}
              label="Metodo de Pago"
              valueInit=""
              selectInit="seleccionar metodo de pago"
              list={
                props.tipoPagos?.map((item, index) => {
                  return (
                    <>
                      {(item.id != 5 && item.id != 4) &&
                        <option value={item?.id} key={item?.nombre + index}>{item?.nombre}</option>
                      }
                    </>
                  );
                })
              }
            />
          </div>
          <div className="mb-3">
            <Select
              name="categoria_id"
              value={values.categoria_id}
              onChange={(e) => {
                setSubCategorias(props.catalogos.filter(x => x.id === Number(e.target.value))[0].sub_categoria);
                handleChange(e);
              }}
              validacion={true}
              label="Categoria"
              valueInit=""
              selectInit="seleccionar categoria"
              list={
                props.catalogos && props.catalogos?.map((item, index) => {
                  return (
                    <option value={item?.id} key={item?.nombre + index}>{item?.nombre}</option>
                  );
                })
              }
            />
          </div>
          <div className="mb-3">
            <Select
              name="sub_categoria_id"
              value={values.sub_categoria_id}
              onChange={handleChange}
              validacion={true}
              label="Sub Categoria"
              valueInit=""
              selectInit="seleccionar sub categoria"
              list={
                subCategorias?.map((item, index) => {
                  return (
                    <option value={item?.id} key={item?.nombre + index}>{item?.nombre}</option>
                  );
                })
              }
            />
          </div>

          <div className="py-3 text-right">
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={(isSubmitting || props.mostrar)}
              text={props.editar ? "Actualizar" : "Guardar"}
            />
          </div>
          {isSubmitting && <Spinner />}
        </div>
      )}
    </Formik>
  );
};
