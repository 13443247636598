/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customGet,customPost } from "../../../utils/services";
import { DetalleOrden } from "./detalle-orden";
import Spinner from "../../../components/common/Spinner";
import CustomTable from "../../../components/common/CustomTable";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ModalMedium from "../../../components/common/Modal_medium";

const tableColumns = [
  { title: "Orden #", value: "id", tipo: "text" },
  { title: "Mesa", value: "mesa", tipo: "text" },
  { title: "Habitacion", value: "habitacion", tipo: "text" },
  { title: "Observacion", value: "observacion", tipo: "text" },
  { title: "Estado", value: "estado.nombre", tipo: "text" },
  { title: "Fecha creacion", value: "created_at", tipo: "datetime" },
];

export const TableRestauranteOrdenes = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [order, setOrder] = useState("");
  const [detalle, setDetalle] = useState(false);
  const [registro, setRegistro] = useState(false);
  const [tipoPagos, setTipoPagos] = useState([]);

  const handleVer = (registro) => {
    setDetalle(true);
    setRegistro(registro);
  };

  useEffect(() => {
    setTableLoading(true);
    customGet(urlFetch,`ventas?estado=1`, token).then((data) => {
      setDataTable({
        data: data
      });
      setTableLoading(false);
    })
  }, [urlFetch ]);

  useEffect(() => {
    customPost(undefined, "catalogos/res-tipo-pagos", token).then((data) => {
      setTipoPagos(data.data);
    });
  }, []);


  const pages = [
    { name: "Listado de ordenes", href: "/", current: false },
  ];

  return (
    <div>
      <div className="flex flex-row justify-between items-end mx-5 mt-2">
        <BreadCrumb navegacion={pages} />
        <div className="flex flex-row justify-center items-end ">
        </div>
      </div>
      <div className="shadow border border-gray-300 rounded-lg overflow-x-auto mx-5 mt-2">
        <CustomTable
          dataTable={dataTable}
          tableColumns={tableColumns}
          handleVer={handleVer}
          fetchPage={setUrlFech}
          pagination={false}
          showPagar={false}
          showEditar={false}
          showEliminar={false}
          showVer={true}
          setOrder={setOrder}
          order={order}
          permiso="reserva"
          scroll={true}
        />
        {tableLoading && (
          <div className="mt-10 mb-10">
            <Spinner />
          </div>
        )}

        {detalle && (
          <ModalMedium
            title={`Detalle de orden`}
            open={detalle}
            closeModal={() => { }}
            cerrar={() => {
              setDetalle(false);
            }}
            children={
              <DetalleOrden 
                registro={registro}
                token={token}
                tipoPagos={tipoPagos}
              />
            }
          />
        )}
      </div>
    </div>
  );
};
