/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { Formik } from "formik";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";

export const Filtros = (props) => {
    return (
        <Formik
            initialValues={{
                fecha: "",
            }}
            onSubmit={async (values) => {
                window.location.href = `${window.location.origin}/calendario?fecha=${values.fecha}`
                props.setFiltros(values.fecha);
                props.setFiltrar(false);
            }}
        >
            {({ values, handleChange, handleSubmit }) => (
                <div className="mx-5">
                    <Input
                        type="date"
                        name="fecha"
                        value={values.fecha}
                        onChange={handleChange}
                        className="mb-2"
                        label="Fecha"
                    />
                    <div className="py-3 text-right">
                        <Button
                            type="submit"
                            onClick={handleSubmit}
                            text="Filtrar"
                        />
                    </div>
                </div>
            )}
        </Formik>
    );
};
