/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { Formik } from "formik";
import { customPut } from "../../../utils/services";
import Spinner from "../../../components/common/Spinner";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import toast from "react-hot-toast";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  //observacion: Yup.string().required("nombre es requerido"),
  //mesa: Yup.string().required("descripcion es requerida"),
  //habitacion: Yup.string().required("precio es requerido"),
});

export const EditarOrden = (props) => {

  return (
    <Formik
      initialValues={{
        observacion: props.registro.observacion,
        mesa: props.registro.mesa,
        habitacion: props.registro.habitacion,
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {

        await customPut(props.registro.id, values, "ventas", props.token)
          .then(async (data) => {
            if (data?.status >= 200 && data?.status <= 210) {
              toast.success(data?.data?.msg);
              props.setRefetch(true);
              props.setEditar(false);

              setTimeout(() => {
                props.setRefetch(false);
              }, 600);
            }
          })
          .catch(function (error) {
            if (error.response.status >= 400 && error.response.status <= 450) {
              toast.error(error.response.data.error);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
            }
          });
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <div className="grid grid-cols-12 gap-2 p-3">
          <div className="col-span-12">
            <Input
              type="text"
              name="observacion"
              value={values.observacion}
              onChange={handleChange}
              validacion={true}
              label="Observacion"
            />
          </div>
          <div className="col-span-12">
            <Input
              type="text"
              name="mesa"
              value={values.mesa}
              onChange={handleChange}
              validacion={true}
              label="Mesa"
            />
          </div>
          <div className="col-span-12">
            <Input
              type="text"
              name="habitacion"
              value={values.habitacion}
              onChange={handleChange}
              validacion={true}
              label="Habitacion"
            />
          </div>
          <div className="col-span-12">
            {isSubmitting ? (
              <Spinner />
            ) : (
              <Button text="Actualizar" onClick={handleSubmit} />
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};
