/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { Formik } from "formik";
import { putComentario } from "../../../utils/services";
import TextArea from "../../../components/common/TextArea";
import Button from "../../../components/common/Button";
import toast from "react-hot-toast";
import Spinner from "../../../components/common/Spinner";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    comentario: Yup.string().required('comentario es requerido'),
});

export const AddComentario = (props) => {
    return (
        <Formik
            validationSchema={validaciones}
            initialValues={{
                comentario: props?.registro?.comentarios,
            }}
            onSubmit={async (values) => {
                await putComentario(props.registro.id, values, `reservacion`, props.token)
                    .then(async (data) => {
                        if (data?.status >= 200 && data?.status <= 210) {
                            toast.success(data?.data?.msg);
                            props.setRefetch(true);
                            setTimeout(() => {
                                props.setRefetch(false);
                                props.setComentario(false);
                            }, 400);
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status >= 400 && error.response.status <= 450) {
                            toast.error(error.response.data.error);
                        } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                        }
                    });
            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <div>
                    <TextArea
                        label="Comentario"
                        name="comentario"
                        value={values.comentario}
                        onChange={handleChange}
                        validacion={true}
                    />
                    <Button
                        className="mt-2"
                        text="Guardar"
                        type="button"
                        onClick={handleSubmit}
                    />
                    {
                        isSubmitting &&
                        <div className="mt-4">
                            <Spinner />
                        </div>
                    }
                </div>
            )}
        </Formik>
    );
};
