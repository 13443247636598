import axios from 'axios';

const {
    REACT_APP_API_URL
} = process.env;

export const login = async (values) => {
    try {
        let body = {
            email: values.email,
            password: values.password
        }

        let bodyResult = axios({
            method: 'post',
            url: `${REACT_APP_API_URL}/login`,
            data: body
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const healthCheck = async(token) => {
    try {
        let result = {};
        await axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/check-status`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }).then(data => {
            result = data;
        }).catch(err => {
            result = {
                data: {
                    success: false
                }
            }
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};
