/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { Formik } from "formik";
import { customPost, customPut } from "../../../utils/services";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/Spinner";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import Select from "../../../components/common/Select";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  codigo: Yup.string().required("codigo es requerido"),
  precio: Yup.string().required("precio es requerido"),
  descripcion: Yup.string().required("descripcion es requerida"),
  existencia_minima: Yup.string().required("existencia minima es requerida"),
  unidad_id: Yup.string().required("unidad es requerida"),
  marca_id: Yup.string().required("marca es requerida"),
  categoria_id: Yup.string().required("categoria es requerida"),
  presentacion: Yup.string().required("presentacion es requerida"),
  cantidad_presentacion: Yup.string().required("cantidad presentacion es requerida"),
});

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);

  return (
    <Formik
      initialValues={{
        codigo: props.editar ? props.value.codigo : "",
        precio: props.editar ? props.value.precio : "",
        descripcion: props.editar ? props.value.descripcion : "",
        existencia_minima: props.editar ? props.value.existencia_minima : "",
        unidad_id: props.editar ? props.value.unidad_id : "",
        marca_id: props.editar ? props.value.marca_id : "",
        categoria_id: props.editar ? props.value.categoria_id : "",
        estado: props.editar ? props.value.estado : true,
        presentacion: props.editar ? props.value.presentacion : "",
        cantidad_presentacion: props.editar ? props.value.cantidad_presentacion : ""
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        if (props.editar) {
          await customPut(props.value.id, values, 'productos', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setEdit(false);
                props.setCrear(false);
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        } else {
          await customPost(values, 'productos', token)
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success(data?.data?.msg);
                props.setCrear(false);
                resetForm({});
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        }
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <div className="mx-5">
          <div className="mb-3">
            <Input
              type="text"
              name="codigo"
              value={values.codigo}
              onChange={handleChange}
              validacion={true}
              label="Codigo"
            />
          </div>
          <div className="mb-3">
            <Input
              type="text"
              name="descripcion"
              value={values.descripcion}
              onChange={handleChange}
              validacion={true}
              label="Descripcion"
            />
          </div>
          <div className="mb-3">
            <Input
              type="text"
              name="precio"
              value={values.precio}
              onChange={handleChange}
              validacion={true}
              label="Precio"
            />
          </div>
          <div className="mb-3">
            <Input
              type="text"
              name="presentacion"
              value={values.presentacion}
              onChange={handleChange}
              validacion={true}
              label="Presentacion"
            />
          </div>
          <div className="mb-3">
            <Input
              type="text"
              name="cantidad_presentacion"
              value={values.cantidad_presentacion}
              onChange={handleChange}
              validacion={true}
              label="Cantidad presentacion"
            />
          </div>
          <div className="mb-3">
            <Input
              type="number"
              name="existencia_minima"
              value={values.existencia_minima}
              onChange={handleChange}
              validacion={true}
              label="Existencia minima"
            />
          </div>
          <div className="mb-3">
            <Select
              name="unidad_id"
              value={values.unidad_id}
              onChange={handleChange}
              validacion={true}
              label="Unidad de medida"
              valueInit=""
              selectInit="seleccionar unidad"
              list={
                props?.catalogos?.unidades?.map((item) => {
                  return (
                    <option value={item.id} key={item.nombre}>{item.nombre}</option>
                  );
                })
              }
            />
          </div>
          <div className="mb-3">
            <Select
              name="marca_id"
              value={values.marca_id}
              onChange={handleChange}
              validacion={true}
              label="Marca"
              valueInit=""
              selectInit="seleccionar marca"
              list={
                props?.catalogos?.marcas?.map((item) => {
                  return (
                    <option value={item.id} key={item.nombre}>{item.nombre}</option>
                  );
                })
              }
            />
          </div>
          <div className="mb-3">
            <Select
              name="categoria_id"
              value={values.categoria_id}
              onChange={handleChange}
              validacion={true}
              label="Categoria"
              valueInit=""
              selectInit="seleccionar categoria"
              list={
                props?.catalogos?.categorias?.map((item) => {
                  return (
                    <option value={item.id} key={item.nombre}>{item.nombre}</option>
                  );
                })
              }
            />
          </div>
          <div className="py-3 text-right">
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={(isSubmitting || props.mostrar)}
              text={props.editar ? "Actualizar" : "Guardar"}
            />
          </div>
          {isSubmitting && <Spinner />}
        </div>
      )}
    </Formik>
  );
};
