import moment from "moment";
import Table from "../../../../components/common/Table";
export const Detalle = (props) => {
    return (
        <div>
            <div className="bg-white border rounded-md mb-2">
                <div className=" border-gray-200 p-0">
                    <div className="sm:divide-y sm:divide-gray-200 ">
                        <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                            <div className="text-xs font-medium text-gray-500">
                                Nombre
                            </div>
                            <div className="text-xs text-gray-900 sm:col-span-2">
                                {props?.registro?.nombre} {props?.registro?.apellido}
                            </div>
                        </div>
                        <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                            <div className="text-xs font-medium text-gray-500">
                                DPI
                            </div>
                            <div className="text-xs text-gray-900 sm:col-span-2">
                                {props?.registro?.dpi}
                            </div>
                        </div>
                        <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                            <div className="text-xs font-medium text-gray-500">
                                Celular
                            </div>
                            <div className="text-xs text-gray-900 sm:col-span-2">
                                {props?.registro?.celular}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Table
                headers={[
                    'Habitacion',
                    'Estado',
                    'Entrada',
                    'Salida',
                    'Saldo'
                ]}
                body= {props?.registro?.reservaciones?.map((item,index) => (
                    <tr
                      key={index}
                    >
                      <td className="px-2 text-xs text-primaryFont">
                          {item?.habitacion?.nombre}
                      </td>
                      <td className="px-2 text-xs text-primaryFont">
                          {item?.estado?.nombre}
                      </td>
                      <td className="px-2 text-xs text-primaryFont">
                          {moment(item?.entrada).format("DD-MM-YYYY")}
                      </td>
                      <td className="px-2 text-xs text-primaryFont">
                      {moment(item?.salida).format("DD-MM-YYYY")}
                      </td>
                      <td className="px-2 text-xs text-primaryFont">
                          Q{item?.saldo}
                      </td>
                    </tr>
                  ))}
            />
        </div>

    );
};
