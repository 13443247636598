/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customDelete, customPost } from "../../../../utils/services";
import { Detalle } from "./detalle";
import { Filtros } from "./filtrar";
import ModalSmall from "../../../../components/common/Modal";
import Spinner from "../../../../components/common/Spinner";
import CustomTable from "../../../../components/common/CustomTable";
import Button from "../../../../components/common/Button";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import BotonesConfirmar from "../../../../components/common/BotonesConfirmar";
import toast from 'react-hot-toast';

const tableColumns = [
  { title: "id", value: "id", tipo: "text" },
  {
    title: "Cliente",
    value: null,
    values: [
      "nombre",
      "apellido",
    ],
    tipo: "text"
  },
  { title: "Celular", value: "celular", tipo: "text" },
  { title: "Correo", value: "correo", tipo: "text" },
  { title: "Fecha creacion", value: "created_at", tipo: "datetime" },
];

export const Reservaciones = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [filtrar, setFiltrar] = useState(false);
  const [filtros,setFiltros] = useState("");
  const [order, setOrder] = useState("");
  const [detalle,setDetalle] = useState(false);
  const [eliminar,setEliminar] = useState(false);
  const [registro, setRegistro] = useState(false);

  const handleVer = (registro) => {
    setDetalle(true);
    setRegistro(registro);
  };

  useEffect(() => {
    setTableLoading(true);
    customPost(filtros, `reservacion/cliente/todas`, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    })
  }, [urlFetch,filtros,eliminar]);

  const pages = [
    { name: "Listado de reservaciones", href: "/", current: false },
  ];

  return (
    <div>
      <div className="flex flex-row justify-between items-end mx-5 mt-2">
        <BreadCrumb navegacion={pages} />
        <div className="flex flex-row justify-center items-end ">
           <Button
            text="Filtrar"
            type="button"
            onClick={() => {
              setFiltrar(true);
            }}
          />
        </div>
      </div>
      <div className="shadow border border-gray-300 rounded-lg overflow-x-auto mx-5 mt-2">
        <CustomTable
          dataTable={dataTable}
          tableColumns={tableColumns}
          handleVer={handleVer}
          fetchPage={setUrlFech}
          pagination={false}
          showVer={true}
          showEditar={false}
          showEliminar={false}
          setOrder={setOrder}
          order={order}
          permiso="reserva"
          scroll={true}
        />
        {tableLoading && (
          <div className="mt-10 mb-10">
            <Spinner />
          </div>
        )}
        {filtrar && (
          <ModalSmall
            title="Filtrar"
            open={filtrar}
            closeModal={() => { }}
            cerrar={() => {
              setFiltrar(false);
            }}
            children={
             <Filtros setFiltrar={setFiltrar} setFiltros={setFiltros} />
            }
          />
        )}
         {detalle && (
          <ModalSmall
            title={`Detalle de reservaciones`}
            open={detalle}
            closeModal={() => { }}
            cerrar={() => {
              setDetalle(false);
            }}
            children={
              <Detalle registro={registro}/>
            }
          />
        )}
         {eliminar && (
          <ModalSmall
            title={`Eliminar registro ${registro.nombre} ${registro.apellido}`}
            open={eliminar}
            closeModal={() => { }}
            cerrar={() => {
              setEliminar(false);
            }}
            children={
             <BotonesConfirmar
              aceptar={async()=>{
                await customDelete(registro.id,'clientes', token)
                .then(async(data) => {
                  if (data?.status >= 200 && data?.status <= 210) {
                    toast.success(data?.data?.msg);
                    setEliminar(false);
                  }
                })
                .catch(function (error) {
                  if (error.response.status >= 400 && error.response.status <= 450) {
                    toast.error(error.response.data.error);
                  } else if (error.response.status >= 500) {
                    toast.error("Error interno comuniquese con el administrador");
                  }
                });
              }}
              cancelar={()=>{
                setEliminar(false);
              }}
             />
            }
          />
        )}
      </div>
    </div>
  );
};
