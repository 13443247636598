/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customGet, customDelete } from "../../../utils/services";
import { Form } from "./form";
import { Detalle } from "./detalle";
import { FormEditar } from "./editar";
import ModalLarge from "../../../components/common/ModalLarge";
import ModalSmall from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import CustomTable from "../../../components/common/CustomTable";
import Button from "../../../components/common/Button";
import BreadCrumb from "../../../components/common/BreadCrumb";
import BotonesConfirmar from "../../../components/common/BotonesConfirmar";
import toast from 'react-hot-toast';
import WithPermission from "../../../app/WithPermission";
import Input from "../../../components/common/Input";

const tableColumns = [
  { title: "Menu", value: "nombre", tipo: "text" },
  { title: "Descripcion", value: "descripcion", tipo: "text" },
  { title: "Precio", value: "precio", tipo: "text" },
  { title: "Categoria", value: "categoria.nombre", tipo: "text" },
  { title: "Fecha creacion", value: "created_at", tipo: "datetime" },
];

export const TableRestauranteMenus = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [refetch, setRefetch] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [catalogosCrear, setCatalogosCrear] = useState({});
  const [order, setOrder] = useState("");
  const [crear, setCrear] = useState(false);
  const [edit, setEdit] = useState(false);
  const [detalle, setDetalle] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  const [registro, setRegistro] = useState({});

  const handleEliminar = (registro) => {
    setEliminar(true);
    setRegistro(registro);
  };

  const handleEditar = (registro) => {
    setRegistro(registro);
    setEdit(true);
  };

  const handleVer = (registro) => {
    setDetalle(true);
    setRegistro(registro);
  };

  useEffect(() => {
    setTableLoading(true);
    customGet(urlFetch, 'menus', token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    })
  }, [urlFetch, crear, eliminar, refetch]);

  useEffect(() => {
    customGet(urlFetch, 'menus/create', token).then((data) => {
      setCatalogosCrear(data);
    })
  }, []);

  const pages = [
    { name: "Listado de menus", href: "/", current: false },
  ];

  const [state, setState] = useState({
    busqueda: "",
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  const KeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      setTableLoading(true);
      customGet(urlFetch, `menus?buscar=${state.busqueda}`, token).then((data) => {
        setDataTable(data);
        setTableLoading(false);
      });
    }
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-end sm:flex mx-5 mt-2">
        <BreadCrumb navegacion={pages} />
        <div>
          <div className="flex flex-row">
            <Input
              name="busqueda"
              className="w-48 mr-3"
              placeholder="buscar"
              onChange={onChange}
              onKeyDown={KeyDownHandler}
            />
            <WithPermission permiso="menu-create">
              <Button
                text="Crear"
                type="button"
                onClick={() => {
                  setCrear(true);
                }}
              />
            </WithPermission>
          </div>

        </div>
      </div>
      <div className="shadow border border-gray-300 rounded-lg overflow-x-auto mx-5 mt-2">
        <CustomTable
          dataTable={dataTable}
          tableColumns={tableColumns}
          handleEditar={handleEditar}
          handleEliminar={handleEliminar}
          handleVer={handleVer}
          fetchPage={setUrlFech}
          pagination={true}
          showVer={true}
          showEditar={true}
          showEliminar={false}
          setOrder={setOrder}
          order={order}
          permiso="menu"
        />
        {tableLoading && (
          <div className="mt-10 mb-10">
            <Spinner />
          </div>
        )}
        {crear && (
          <ModalLarge
            title={"Crear menu"}
            open={crear}
            closeModal={() => { }}
            cerrar={() => {
              setCrear(false);
            }}
            children={
              <Form
                setCrear={setCrear}
                catalogos={catalogosCrear}
                value={registro}
              />
            }
          />
        )}

        {edit && (
          <ModalLarge
            title={"Editar menu"}
            open={edit}
            closeModal={() => { }}
            cerrar={() => {
              setEdit(false);
              setRefetch(true);

              setTimeout(() => {
                setRefetch(false);
              }, 800);
            }}
            children={
              <FormEditar
                catalogos={catalogosCrear}
                registro={registro}
                editar={edit}
                setEdit={setEdit}
                setRefetch={setRefetch}
              />
            }
          />
        )}

        {detalle && (
          <ModalLarge
            title={`Detalle del menu`}
            open={detalle}
            closeModal={() => { }}
            cerrar={() => {
              setDetalle(false);
            }}
            children={
              <Detalle registro={registro} />
            }
          />
        )}
        {eliminar && (
          <ModalSmall
            title={`Eliminar menu ${registro.nombre}`}
            open={eliminar}
            closeModal={() => { }}
            cerrar={() => {
              setEliminar(false);
            }}
            children={
              <BotonesConfirmar
                aceptar={async () => {
                  await customDelete(registro.id, 'menus', token)
                    .then(async (data) => {
                      if (data?.status >= 200 && data?.status <= 210) {
                        toast.success(data?.data?.msg);
                        setEliminar(false);
                      }
                    })
                    .catch(function (error) {
                      if (error.response.status >= 400 && error.response.status <= 450) {
                        toast.error(error.response.data.error);
                      } else if (error.response.status >= 500) {
                        toast.error("Error interno comuniquese con el administrador");
                      }
                    });
                }}
                cancelar={() => {
                  setEliminar(false);
                }}
              />
            }
          />
        )}
      </div>
    </div>
  );
};
