import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'

const Paginacion = (props) => {
    const claseBtn = "border-t-2 border-transparent pt-4 inline-flex items-center text-sm font-medium text-primaryFont hover:text-gray-700 hover:border-gray-300";
    const classPage = "cursor-pointer border-transparent text-primaryFont hover:text-primaryFont hover:border-gray-300 border-t-2 pt-4 px-1 mx-1 inline-flex items-center text-sm font-medium";
    const classCPage = "border-maivi-primaryLightBlue text-maivi-primaryLightBlue border-t-2 pt-4 px-1 mx-1 inline-flex items-center text-sm font-medium cursor-pointer";
    let currentPage = 0;

    const Paginas = () => {
        let url = "";
        let arrayPage = [];
        let urlN = "";
        let initFor = 1;
        let endFor = 10;

        if (props.next) {
            url = props.next;
            urlN = new URL(url)
            const params = new URLSearchParams(urlN.search)
            currentPage = params.get('page') - 1;
        }

        else if (props.prev) {
            url = props.prev;
            urlN = new URL(url)
            const params = new URLSearchParams(urlN.search)
            currentPage = params.get('page') === null ? 2 : params.get('page');
        }

        let paginas = props.count / 10;
        let paginasInt = parseInt(paginas);

        if (paginas > paginasInt) {
            paginasInt += 1;
        }

        if (currentPage > 4) {
            initFor = currentPage - 3;
        }

        if ((currentPage + 4) < paginasInt) {
            endFor = currentPage + 3;
        }

        if ((currentPage + 4) >= paginasInt) {
            endFor = paginasInt;
        }

        if (paginasInt < 10) {
            endFor = paginasInt;
        }

        if (props.count > 10) {
            
            for (let index = initFor; index <= endFor; index++) {
                arrayPage.push({
                    href: `${urlN.origin}${urlN.pathname}?page=${index}&${props.params}`,
                    text: index
                });
            }
        }

        return arrayPage;
    }

    const firstPage = () => {
        let pagina = 1;
        let url = "";
        let urlN = "";
        let paginaActual = 0;

        if (props.next) {
            url = props.next;
            urlN = new URL(url)
            const params = new URLSearchParams(urlN.search)
            paginaActual = params.get('page') - 1;
        }

        else if (props.prev) {
            url = props.prev;
            urlN = new URL(url)
            const params = new URLSearchParams(urlN.search)
            paginaActual = (params.get('page') ? params.get('page') : 1) + 1;
        }

        if (paginaActual > 4) {
            pagina = `${urlN.origin}${urlN.pathname}?page=1`
        }

        return pagina;
    }

    const lastPage = () => {
        let pagina = 1;
        let url = "";
        let urlN = "";
        let paginaActual = 0;


        if (props.next) {
            url = props.next;
            urlN = new URL(url)
            const params = new URLSearchParams(urlN.search)
            paginaActual = params.get('page') - 1;
        }

        else if (props.prev) {
            url = props.prev;
            urlN = new URL(url)
            const params = new URLSearchParams(urlN.search)
            paginaActual = (params.get('page') ? params.get('page') : 1) + 1;
        }

        let paginas = props.count / 10;
        let paginasInt = parseInt(paginas);

        if (paginas > paginasInt) {
            paginasInt += 1;
        }

        if ((paginaActual + 4) < paginasInt) {
            pagina = `${urlN.origin}${urlN.pathname}?page=${paginasInt}`
        }

        return pagina;
    }

    const countPage = () => {
        let paginas = props.count / 10;
        let paginasInt = parseInt(paginas);

        if (paginas > paginasInt) {
            paginasInt += 1;
        }

        return paginasInt;
    }

    return (
        <>
            <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 pb-4 ml-5">
                <div className="-mt-px w-0 flex-1 flex">
                    {props.prev ?
                        <span style={{ cursor: "pointer" }} className={`pr-1 ${claseBtn}`} onClick={() => props.fetchPage(props.prev)}>
                            <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-primaryFont" aria-hidden="true" />
                            Anterior
                        </span>
                        :
                        <span></span>
                    }
                </div>
                <div className="hidden md:-mt-px md:flex">
                    {
                        firstPage() !== 1 &&
                        <i className={classPage} onClick={() => props.fetchPage(firstPage())}>1</i>
                    }

                    {
                        firstPage() !== 1 &&
                        <i className={classPage}>...</i>
                    }

                    {Paginas().map(page =>
                        currentPage === page.text ?
                            <i key={page.href} className={classCPage} aria-current="page" onClick={() => props.fetchPage(page.href)}> {page.text} </i>
                            :
                            <i key={page.href} className={classPage} onClick={() => props.fetchPage(page.href)}> {page.text} </i>

                    )}
                    {
                        lastPage() !== 1 &&
                        <i className={classPage}>...</i>
                    }

                    {
                        lastPage() !== 1 &&
                        <i className={classPage} onClick={() => props.fetchPage(lastPage())}>{countPage()}</i>
                    }


                </div>
                <div className="-mt-px w-0 flex-1 flex justify-end mr-5">
                    {props.next ?
                        <span style={{ cursor: "pointer" }} className={`pr-1 ${claseBtn}`} onClick={() => props.fetchPage(props.next)}>
                            Siguiente
                            <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-primaryFont" aria-hidden="true" />
                        </span>
                        :
                        <span></span>
                    }
                </div>
            </nav>
            <div>
                <p className="text-sm text-primaryFont text-center mb-2">
                    Mostrando del <span className="font-medium"> {(((currentPage === 0 ? 1 : currentPage) * 10) - 10) + 1} </span> a <span className="font-medium">{((currentPage === 0 ? 1 : currentPage) * 10) > props.count ? props.count : ((currentPage === 0 ? 1 : currentPage) * 10)}</span> de{' '}
                    <span className="font-medium">{props.count}</span> resultados
                </p>
            </div>
        </>
    )
}

export default Paginacion;