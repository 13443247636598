import { React, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { customPost } from "../../../utils/services";
import { Barras } from "../charts/barras";
import NumberFormat from "react-number-format";
import Label from "../../../components/common/Label";
import Table from "../../../components/common/Table";

export const ReporteCancelaciones = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [cargandoPorYear, setCargandoPorYear] = useState(false);
  const [cargandoPorMes, setCargandoPorMes] = useState(false);
  const [cargandoPorDia, setCargandoPorDia] = useState(false);
  const [reservacionesYear, setReservacionesPorYear] = useState([]);
  const [ventasPorMes, setVentasPorMes] = useState([]);
  const [ventasPorDia, setVentasPorDia] = useState([]);
  const [datos, setDatos] = useState([]);
  const [labels, setLabels] = useState([]);

  const getReservacionesPorYear = () => {
    setCargandoPorYear(true);
    customPost({}, `reportes/reservas-canceladas/anio`, token).then(
      (data) => {
        setReservacionesPorYear(data.data);
        setCargandoPorYear(false);
      }
    );
  };

  const getReservacionesPorMes = async (year) => {
    setCargandoPorMes(true);
    await customPost(
      {},
      `reportes/reservas-canceladas/mes?anio=${year}`,
      token
    ).then((data) => {
      setVentasPorMes(data.data);
      setCargandoPorMes(false);

    });
  };

  const getReservacionesPordia = async (mes, year) => {
    setCargandoPorDia(true);
    await customPost(
      {},
      `reportes/reservas-canceladas/dia?mes=${mes}&anio=${year}`,
      token
    ).then((data) => {
      setVentasPorDia(data.data);
      setCargandoPorDia(false);

      let reg = [];
      let reg2 = [];
      data?.data?.forEach((element, index) => {
        if (index <= 20) {
          reg.push(Number(element.total));
          reg2.push(`${element?.dia} / ${nombreMes(element.mes)} / ${element.anio}`);
        }
      });

      setDatos(reg);
      setLabels(reg2);
    });
  };

  useState(()=>{
    getReservacionesPorYear();
  },[])

  const nombreMes = (mes) => {
    let nombre = "";

    if (mes === 1) {
      nombre = "Enero";
    } else if (mes === 2) {
      nombre = "Febrero";
    } else if (mes === 3) {
      nombre = "Marzo";
    } else if (mes === 4) {
      nombre = "Abril";
    } else if (mes === 5) {
      nombre = "Mayo";
    } else if (mes === 6) {
      nombre = "Junio";
    } else if (mes === 7) {
      nombre = "Julio";
    } else if (mes === 8) {
      nombre = "Agosto";
    } else if (mes === 9) {
      nombre = "Septiembre";
    } else if (mes === 10) {
      nombre = "Octubre";
    } else if (mes === 11) {
      nombre = "Noviembre";
    } else if (mes === 12) {
      nombre = "Diciembre";
    }

    return nombre;
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Total",
        data: datos,
        backgroundColor: [
          "#ff6384",
          "#ff9f40",
          "#F4D03F",
          "#1E8449",
          "#2ECC71",
          "#4bc0c0",
          "#36a2eb",
          "#9966ff",
          "#9C27B0",
          "#ABB2B9",
        ],
        borderColor: [
          "#ffff",
          "#ffff",
          "#ffff",
          "#ffff",
          "#ffff",
          "#ffff",
          "#ffff",
          "#ffff",
          "#ffff",
          "#ffff",
        ],
      },
    ],
  };

  return (
    <Formik
      initialValues={{
        asesor: "",
      }}
      onSubmit={async (values) => {}}
    >
      {({ values }) => (
        <div className="grid grid-cols-12 gap-2 mt-3 mx-2 xl:mx-4 lg:mx-4">

          <div className="col-span-12 xl:col-span-5 lg:col-span-5 border rounded-lg hover:shadow-md p-4">
            <div className="flex flex-row justify-center items-center text-primaryFont">
              <span className="">REPORTE DE CANCELACIONES</span>
            </div>
            <hr className="mb-3" />
            <div className="mt-3">
              <Label text="Cancelaciones por año" />
              <Table
                cargando={cargandoPorYear}
                headers={["Año", "Total"]}
                body={reservacionesYear?.map((item, index) => (
                  <tr
                    key={`ventas-${index}`}
                    id={`ventas-${index}`}
                    className="hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                        let row;
                        reservacionesYear.forEach((element,indice) => {
                          let row = document.getElementById(`ventas-${indice}`);
                          row.classList.remove("bg-green-100");
                        });
                        
                        row = document.getElementById(`ventas-${index}`);
                        row.classList.add("bg-green-100");
                      getReservacionesPorMes(item.anio);
                    }}
                  >
                    <td className="px-2 py-2 text-xs text-primaryFont">
                      {item?.anio}
                    </td>
                    <td className="px-2 py-2 text-xs text-primaryFont">
                      <NumberFormat
                        value={item?.total}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Q"}
                      />
                    </td>
                  </tr>
                ))}
              />
            </div>
            <div className="grid grid-cols-12 gap-2 mt-3">
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6">
                <Label text="Cancelaciones por mes" />
                <div className=" h-96 overflow-y-auto">
                <Table
                  headers={["Mes", "Total"]}
                  cargando={cargandoPorMes}
                  body={ventasPorMes?.map((item, index) => (
                    <tr
                      key={`mes-${index}`}
                      id={`mes-${index}`}
                      className="hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        let row;
                        ventasPorMes.forEach((element,indice) => {
                          let row = document.getElementById(`mes-${indice}`);
                          row.classList.remove("bg-green-100");
                        });
                        
                        row = document.getElementById(`mes-${index}`);
                        row.classList.add("bg-green-100");
                        getReservacionesPordia(item.mes, item.anio);
                      }}
                    >
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {nombreMes(Number(item?.mes))} / {item.anio}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        <NumberFormat
                          value={item?.total}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Q"}
                        />
                      </td>
                    </tr>
                  ))}
                />
                </div>
               
              </div>
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6">
                <Label text="Cancelaciones por dia" />
                <div className=" h-96 overflow-y-auto">
                  <Table
                    cargando={cargandoPorDia}
                    headers={["Dia", "Total"]}
                    body={ventasPorDia?.map((item, index) => (
                      <tr
                        key={`orden-${index}`}
                        id={`orden-${index}`}
                        className="hover:bg-gray-100 cursor-pointer"
                      >
                        <td className="px-2 py-2 text-xs text-primaryFont">
                          {item?.dia} / {nombreMes(item.mes)} / {item?.anio}
                        </td>
                        <td className="px-2 py-2 text-xs text-primaryFont">
                          <NumberFormat
                            value={item?.total}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Q"}
                          />
                        </td>
                      </tr>
                    ))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 xl:col-span-7 lg:col-span-7 border rounded-lg hover:shadow-md p-4">
          <div className="flex flex-row justify-center items-center text-primaryFont">
            <span className="text-center mx-3 text-xs xl:text-base lg:text-base md:text-sm">{`Total de cancelaciones por dia`}</span>
            </div>
            <hr className="mb-3" />
            <div className="h-96 xl:h-4/5 lg:h-4/5 w-full">
              <Barras data={data} />
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
