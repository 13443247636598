export const Detalle = (props) => {
    return (
        <div className="bg-white border rounded-md">
            <div className=" border-gray-200 p-0">
                <div className="sm:divide-y sm:divide-gray-200 ">
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Codigo
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.codigo}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Descripcion
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.descripcion}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Marca
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.marca.nombre}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Unidad
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.unidad.nombre}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Existencia
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.existencia}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Precio
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.precio}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Fecha creacion
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.created_at}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
