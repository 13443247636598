/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customGet,customDelete } from "../../../../utils/services";
import { Form } from "./Form";
import { Detalle } from "./detalle";
import WithPermission from '../../../../app/WithPermission';
import ModalSmall from "../../../../components/common/Modal";
import Spinner from "../../../../components/common/Spinner";
import CustomTable from "../../../../components/common/CustomTable";
import Button from "../../../../components/common/Button";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import BotonesConfirmar from "../../../../components/common/BotonesConfirmar";
import toast from 'react-hot-toast';
import Input from "../../../../components/common/Input";

const tableColumns = [
  { title: "id", value: "id", tipo: "text" },
  { title: "Nombre", value: "nombre", tipo: "text" },
  { title: "Apellido", value: "apellido", tipo: "text" },
  { title: "Celular", value: "celular", tipo: "text" },
  { title: "Fecha creacion", value: "created_at", tipo: "datetime" },
];

export const TableClientes = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [catalogosCrear, setCatalogosCrear] = useState({});
  const [order, setOrder] = useState("");
  const [crear, setCrear] = useState(false);
  const [edit, setEdit] = useState(false);
  const [detalle,setDetalle] = useState(false);
  const [eliminar,setEliminar] = useState(false);
  const [registro, setRegistro] = useState(false);
  const [refetch,setRefetch] = useState(false);
  const permiso ="customer";

  const [state, setState] = useState({
    busqueda:"",
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  const handleEliminar = (registro) => {
    setEliminar(true);
    setRegistro(registro);
  };

  const handleEditar = (registro) => {
    setEdit(true);
    setCrear(true);
    setRegistro(registro);
  };

  const handleVer = (registro) => {
    setDetalle(true);
    setRegistro(registro);
  };

  useEffect(() => {
    setTableLoading(true);
    customGet(urlFetch, `clientes`, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  }, [urlFetch,refetch,eliminar]);

  useEffect(() => {
    customGet(urlFetch, 'clientes/create', token).then((data) => {
      setCatalogosCrear(data);
    });
  }, []);

  const KeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      setTableLoading(true);
    customGet(urlFetch, `clientes?buscar=${state.busqueda}`, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
    }
  }

  const pages = [
    { name: "Listado de clientes", href: "/", current: false },
  ];

  return (
    <div>
      <div className="flex flex-row justify-between items-end sm:flex mx-5 mt-2">
        <BreadCrumb navegacion={pages} />
        <div className="flex flex-row">
          <Input 
            name="busqueda"
            className="w-48 mr-3"
            placeholder="buscar"
            onChange={onChange}
            onKeyDown={KeyDownHandler}
          />
          <WithPermission permiso="customer-create">
            <Button
                text="Crear"
                type="button"
                onClick={() => {
                  setCrear(true);
                }}
              />
          </WithPermission>
        </div>
      </div>
      <div className="shadow border border-gray-300 rounded-lg overflow-x-auto mx-5 mt-2">
        <CustomTable
          dataTable={dataTable}
          tableColumns={tableColumns}
          handleEditar={handleEditar}
          handleEliminar={handleEliminar}
          handleVer={handleVer}
          fetchPage={setUrlFech}
          pagination={true}
          showVer={true}
          showEditar={true}
          showEliminar={true}
          setOrder={setOrder}
          order={order}
          permiso={permiso}
        />
        {tableLoading && (
          <div className="mt-10 mb-10">
            <Spinner />
          </div>
        )}
        {crear && (
          <ModalSmall
            title={edit ? "Editar cliente" : "Crer cliente"}
            open={crear}
            closeModal={() => { }}
            cerrar={() => {
              setCrear(false);
              setEdit(false);
            }}
            children={
              <Form
                setCrear={setCrear}
                catalogos={catalogosCrear}
                value={registro}
                editar={edit}
                setEdit={setEdit}
                setRefetch={setRefetch}
              />
            }
          />
        )}
         {detalle && (
          <ModalSmall
            title={`Detalle del cliente`}
            open={detalle}
            closeModal={() => { }}
            cerrar={() => {
              setDetalle(false);
            }}
            children={
              <Detalle registro={registro}/>
            }
          />
        )}
         {eliminar && (
          <ModalSmall
            title={`Eliminar registro ${registro.nombre} ${registro.apellido}`}
            open={eliminar}
            closeModal={() => { }}
            cerrar={() => {
              setEliminar(false);
            }}
            children={
             <BotonesConfirmar
              aceptar={async()=>{
                await customDelete(registro.id,'clientes', token)
                .then(async(data) => {
                  if (data?.status >= 200 && data?.status <= 210) {
                    toast.success(data?.data?.msg);
                    setEliminar(false);
                  }
                })
                .catch(function (error) {
                  if (error.response.status >= 400 && error.response.status <= 450) {
                    toast.error(error.response.data.error);
                  } else if (error.response.status >= 500) {
                    toast.error("Error interno comuniquese con el administrador");
                  }
                });
              }}
              cancelar={()=>{
                setEliminar(false);
              }}
             />
            }
          />
        )}
      </div>
    </div>
  );
};
