import { React, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { customPost } from "../../../utils/services";
import { Barras } from "../charts/barras";
import Table from "../../../components/common/Table";
import { backgroundColor, borderColor, nombreMes } from "../../../utils/utils";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import Select from "../../../components/common/Select";

const mesesAnio = [
  { name: "Enero", id: 1 },
  { name: "Febrero", id: 2 },
  { name: "Marzo", id: 3 },
  { name: "Abril", id: 4 },
  { name: "Mayo", id: 5 },
  { name: "Junio", id: 6 },
  { name: "Julio", id: 7 },
  { name: "Agosto", id: 8 },
  { name: "Septiembre", id: 9 },
  { name: "Octubre", id: 10 },
  { name: "Noviembre", id: 11 },
  { name: "Diciembre", id: 12 }
];

export const ReportePorcentajeOcupacional = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [cargandoData, setCargandoData] = useState(false);
  const [datos, setDatos] = useState([]);
  const [labels, setLabels] = useState([]);
  const [graficaTitulo, setGraficaTitulo] = useState([]);
  const [dataPorcentajes, setDataPorcentajes] = useState([]);
  const [dataPorcentajesDia, setDataPorcentajesDia] = useState([]);
  const [dataPorcentajesDiaPerson, setDataPorcentajesDiaPerson] = useState([]);
  const [dataOcupacionPerson, setDataOcupacionPerson] = useState([]);

  const getPorcentajesData = async (mes, anio) => {
    setCargandoData(true);
    await customPost(
      { mes, anio },
      `reportes/porcentaje-ocupacional`,
      token
    ).then((data) => {
      setCargandoData(false);

      let reg = [];
      let reg2 = [];
      data?.data?.data.forEach(element => {
        reg.push(Number(element.ocupacion));
        reg2.push(`${element.diaTexto}-${element.dia}`);
      });

      let dataDia = [

      ];
      let dias = { lunes: '', martes: '', miercoles: '', jueves: '', viernes: '', sabado: '', domingo: '' };
      let diasClear = { lunes: '', martes: '', miercoles: '', jueves: '', viernes: '', sabado: '', domingo: '' };

      let diasPerson = [
        { dia: 'Lunes', per1: 0, per2: 0, per3: 0, per4: 0 },
        { dia: 'Martes', per1: 0, per2: 0, per3: 0, per4: 0 },
        { dia: 'Miercoles', per1: 0, per2: 0, per3: 0, per4: 0 },
        { dia: 'Jueves', per1: 0, per2: 0, per3: 0, per4: 0 },
        { dia: 'Viernes', per1: 0, per2: 0, per3: 0, per4: 0 },
        { dia: 'Sabado', per1: 0, per2: 0, per3: 0, per4: 0 },
        { dia: 'Domingo', per1: 0, per2: 0, per3: 0, per4: 0 },
      ];

      let dataPerson = [
        { personas: 1, descripcion: '1 Persona', ocupacion: 0 },
        { personas: 2, descripcion: '2 Personas', ocupacion: 0 },
        { personas: 3, descripcion: '3 Personas', ocupacion: 0 },
        { personas: 4, descripcion: '4 Personas', ocupacion: 0 }
      ];

      const dataOrden = data.data?.data.sort((a, b) => a.dia > b.dia);

      diasPerson.forEach((dia, index) => {
        dataOrden.forEach(element => {
          if (dia.dia === element.diaTexto) {
            diasPerson[index].per1 += element.per1;
            diasPerson[index].per2 += element.per2;
            diasPerson[index].per3 += element.per3;
            diasPerson[index].per4 += element.per4;
          }
        });
      })
      setDataPorcentajesDiaPerson(diasPerson);

      dataPerson.forEach((person, index) => {
        dataOrden.forEach(element => {
          if (person.personas === 1) { dataPerson[index].ocupacion += element.per1 }
          if (person.personas === 2) { dataPerson[index].ocupacion += element.per2 }
          if (person.personas === 3) { dataPerson[index].ocupacion += element.per3 }
          if (person.personas === 4) { dataPerson[index].ocupacion += element.per4 }
        });
      })
      setDataOcupacionPerson(dataPerson);

      let ocupacionTotal = 0;
      dataOrden.forEach(element => {
        ocupacionTotal += element.ocupacion;
        if (element.diaTexto === 'Lunes') { dias.lunes = element.ocupacion }
        if (element.diaTexto === 'Martes') { dias.martes = element.ocupacion }
        if (element.diaTexto === 'Miercoles') { dias.miercoles = element.ocupacion }
        if (element.diaTexto === 'Jueves') { dias.jueves = element.ocupacion }
        if (element.diaTexto === 'Viernes') { dias.viernes = element.ocupacion }
        if (element.diaTexto === 'Sabado') { dias.sabado = element.ocupacion }
        if (element.diaTexto === 'Domingo') {
          dias.domingo = element.ocupacion;
          dataDia.push(dias);
          dias = { ...diasClear };
        }
      });

      if (dias.lunes || dias.martes || dias.miercoles || dias.jueves || dias.viernes || dias.sabado || dias.domingo) {
        dataDia.push(dias);
      }

      setDataPorcentajesDia(dataDia);

      setDatos(reg);
      setLabels(reg2);
      setGraficaTitulo(`Datos de - ${nombreMes(parseInt(mes))} de ${anio} -`);
      console.log(mes);
      setDataPorcentajes({
        titulo: `Datos de - ${nombreMes(parseInt(mes))} de ${anio} -`,
        data: dataOrden,
        metrica: data.data.metrica,
        ocupacionTotal: ocupacionTotal
      });
    });
  };

  useState(() => {
    let date = new Date();
    getPorcentajesData((date.getMonth()), date.getFullYear());
  }, [])
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Total",
        data: datos,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      },
    ],
  };

  return (
    <Formik
      initialValues={{
        mes: (new Date().getMonth()),
        anio: new Date().getFullYear()
      }}
      onSubmit={async (values) => {
        getPorcentajesData(values.mes, values.anio);
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <div className="grid grid-cols-12 gap-2 mt-3 mx-2 xl:mx-4 lg:mx-4">
          <div className="col-span-12 xl:col-span-12 lg:col-span-12 border rounded-lg hover:shadow-md p-4 flex">
            <div className="px-5 mt-5 mb-5">
              <Input
                type="number"
                name="anio"
                value={values.anio}
                onChange={handleChange}
                validacion={true}
                label="Año"
              />
            </div>
            <div className="px-5 mt-5 mb-5">
              <Select
                label="Mes"
                value={values.mes}
                onChange={(e) => { setFieldValue("mes", e.target.value); }}
                list={
                  mesesAnio.map((m, key) => <option key={`${key}-mesanio`} value={m.id}>{m.name}</option>)
                }
              />
            </div>

            <div className="px-5 mt-5 mb-5" style={{ marginTop: "43px" }}>
              <Button text="Enviar" onClick={handleSubmit} type="submit" />
            </div>
          </div>
          <div className="col-span-12 xl:col-span-5 lg:col-span-5 border rounded-lg hover:shadow-md p-4">
            <div className="flex flex-row justify-center items-center text-primaryFont">
              <span className="">{dataPorcentajes.titulo}</span>
            </div>
            <hr className="mb-3" />
            <div className="mt-3">
              {dataPorcentajes?.data &&
                <Table
                  cargando={cargandoData}
                  headers={["", "Dia", "1 persona", "2 personas", '3 personas', '4 personas', 'Total']}
                  body={dataPorcentajes.data?.map((item, index) => (
                    <tr
                      key={`porcent-${index}`}
                      id={`porcent-${index}`}
                      className="hover:bg-gray-100 cursor-pointer"
                    >
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item?.diaTexto}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item?.dia}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.per1}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.per2}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.per3}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.per4}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.ocupacion}
                      </td>
                    </tr>
                  ))}
                />
              }
            </div>
            <div className="grid grid-cols-12 gap-2 mt-3">

            </div>
          </div>
          <div className="col-span-12 xl:col-span-7 lg:col-span-7 border rounded-lg hover:shadow-md p-4">
            <div>
              <br />
              {dataPorcentajes.data &&
                <Table
                  cargando={cargandoData}
                  headers={["Lunes", "Martes", "Miercoles", "Jueves", 'Viernes', 'Sabado', 'Domingo']}
                  body={dataPorcentajesDia.map((item, index) => (
                    <tr
                      key={`porcent-${index}`}
                      id={`porcent-${index}`}
                      className="hover:bg-gray-100 cursor-pointer"
                    >
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item?.lunes}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.martes}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.miercoles}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.jueves}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.viernes}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.sabado}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item?.domingo}
                      </td>
                    </tr>
                  ))}
                />
              }
              <br />
              {dataPorcentajes?.data &&
                <Table
                  cargando={cargandoData}
                  headers={["Lunes", "Martes", "Miercoles", "Jueves", 'Viernes', 'Sabado', 'Domingo']}
                  body={dataPorcentajesDia.map((item, index) => (
                    <tr
                      key={`porcent-${index}`}
                      id={`porcent-${index}`}
                      className="hover:bg-gray-100 cursor-pointer"
                    >
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.lunes === '' ? '' : ((item.lunes / dataPorcentajes.metrica) * 100).toFixed(2) + ' %'}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.martes === '' ? '' : ((item.martes / dataPorcentajes.metrica) * 100).toFixed(2) + ' %'}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.miercoles === '' ? '' : ((item.miercoles / dataPorcentajes.metrica) * 100).toFixed(2) + ' %'}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.jueves === '' ? '' : ((item.jueves / dataPorcentajes.metrica) * 100).toFixed(2) + ' %'}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.viernes === '' ? '' : ((item.viernes / dataPorcentajes.metrica) * 100).toFixed(2) + ' %'}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.sabado === '' ? '' : ((item.sabado / dataPorcentajes.metrica) * 100).toFixed(2) + ' %'}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.domingo === '' ? '' : ((item.domingo / dataPorcentajes.metrica) * 100).toFixed(2) + ' %'}
                      </td>
                    </tr>
                  ))}
                />
              }

              <br />
              {dataPorcentajes?.data &&
                <Table
                  cargando={cargandoData}
                  headers={["Dia", "1 Persona", "2 Personas", "3 Personas", '4 Personas']}
                  body={dataPorcentajesDiaPerson.map((item, index) => (
                    <tr
                      key={`porcent-${index}`}
                      id={`porcent-${index}`}
                      className="hover:bg-gray-100 cursor-pointer"
                    >
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.dia}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {((item.per1 * 100) / (item.per1 + item.per2 + item.per3 + item.per4)).toFixed(2)} %
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {((item.per2 * 100) / (item.per1 + item.per2 + item.per3 + item.per4)).toFixed(2)} %
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {((item.per3 * 100) / (item.per1 + item.per2 + item.per3 + item.per4)).toFixed(2)} %
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {((item.per4 * 100) / (item.per1 + item.per2 + item.per3 + item.per4)).toFixed(2)} %
                      </td>
                    </tr>
                  ))}
                />
              }
              <br />
              {dataPorcentajes?.data &&
                <Table
                  cargando={cargandoData}
                  headers={[" ", "Ocupacion", "Porcentaje"]}
                  body={dataOcupacionPerson.map((item, index) => (
                    <tr
                      key={`porcent-${index}`}
                      id={`porcent-${index}`}
                      className="hover:bg-gray-100 cursor-pointer"
                    >
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.descripcion}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {item.ocupacion}
                      </td>
                      <td className="px-2 py-2 text-xs text-primaryFont">
                        {((item.ocupacion * 100) / dataPorcentajes.ocupacionTotal).toFixed(2)} %
                      </td>
                    </tr>
                  ))}
                />
              }

            </div>
          </div>

          <div className="col-span-12 xl:col-span-12 lg:col-span-12 border rounded-lg hover:shadow-md p-4">
            <div className="flex flex-row justify-center items-center text-primaryFont">
              <span className="text-center mx-3 text-xs xl:text-base lg:text-base md:text-sm">{graficaTitulo}</span>
            </div>
            <hr className="mb-3" />
            <div className="h-96  w-full">
              <Barras data={data} />
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
