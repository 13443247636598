import { useState } from "react";
import { TrashIcon } from "@heroicons/react/solid";
import { EyeIcon,CurrencyDollarIcon } from "@heroicons/react/solid";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { SelectorIcon } from "@heroicons/react/solid";
import { PencilAltIcon } from "@heroicons/react/solid";
import WithPermission from "../../app/WithPermission";
import Paginacion from "./Paginacion";
import moment from "moment";

const CustomTable = (props) => {
  const btnOpCls =
    "inline-flex items-center p-1 border border-transparent rounded-md text-gray-50 hover:text-white  focus:ring-primary";
  const thTableCls =
    "px-2 py-2 text-left text-sm font-medium text-primaryFont tracking-wider";
  const spanBool =
    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full ";
  const spanGreen = "bg-green-100 text-green-800";
  const spanRed = "bg-red-100 text-red-800";

  const [registrosSeleccionados, setRegistrosSeleccionados] = useState([])

  let next = props.dataTable?.next_page_url;
  let previous = props.dataTable?.prev_page_url;
  let count = props.dataTable?.total;

  const setOrder = (order) => {
    if (order === props.order) {
      props.setOrder(`-${order}`);
    }
    else if (`-${order}` === props.order) {
      props.setOrder(`${order}`);
    } else {
      props.setOrder(`${order}`);
    }
  }

  const compomentTD = (index, data, column) => {

    const dataValue = () => {
      if (column.value !== null) {
        let val = column.value.split('.')
        let retVal = data;
        let firt = 0;

        val.forEach(ele => {
          retVal = firt === 0 ? (data && data[ele]) : (retVal && retVal[ele]);
          firt = 1;
        });
        return (retVal?.length >= 40) ? retVal.substring(0, 40) + '...' : retVal;
      }

      if (column.values !== undefined) {
        let valRetorno = '';

        column.values.forEach(value => {
          let val = value.split('.');
          let retVal = data;
          let firt = 0;

          val.forEach(ele => {
            retVal = firt === 0 ? (data && data[ele]) : (retVal && retVal[ele]);
            firt = 1;
          });
          let valNew = (retVal?.length >= 40) ? retVal.substring(0, 40) + '...' : retVal;
          if (retVal !== null) {
            valRetorno = `${valRetorno} ${valNew}`;
          }
        })
        return valRetorno;
      }
    }

    if (column.tipo === "datetime") {
      return (
        <td key={`${index}-${dataValue()}-TD`} className="px-2 py-2 whitespace-nowrap text-xs text-primary">
          <span>{(dataValue() === undefined || dataValue() === null) ? '' : moment(dataValue()).format("DD-MM-YYYY HH:mm:ss")}</span>
        </td>
      );
    }

    if (column.tipo === "date") {
      return (
        <td key={`${index}-${dataValue()}-TD`} className="px-2 py-2 whitespace-nowrap text-xs text-primary">
          <span>{(dataValue() === undefined || dataValue() === null) ? '' : moment(dataValue()).format("DD-MM-YYYY")}</span>
        </td>
      );
    } else {
      return (
        <td key={`${index}-${dataValue()}-TD`} className="px-2 py-2 whitespace-nowrap text-xs text-primary">
          {column.tipo === "boolean" ? (
            <span
              className={`${spanBool} ${dataValue() ? spanGreen : spanRed
                }`}
            >
              {column.text[dataValue()]}
            </span>
          ) : (
            dataValue()
          )}
        </td>
      );
    }

  };

  const customSort = (th) => {
    if (th.ordenar === props.order) {
      return <ChevronUpIcon className="h-6 w-6 text-primaryFont hover:text-primaryLightBlue cursor-pointer" onClick={() => setOrder(th.ordenar)} />
    }
    if (`-${th.ordenar}` === props.order) {
      return <ChevronDownIcon className="h-6 w-6 text-primaryFont hover:text-primaryLightBlue cursor-pointer" onClick={() => setOrder(th.ordenar)} />
    }

    return <SelectorIcon className="h-6 w-6 text-primaryFont hover:text-primaryLightBlue cursor-pointer" onClick={() => setOrder(th.ordenar)} />
  }

  return (
    <div className={props.scroll ? 'altura-table' : ''}>
      <table className="min-w-full divide-y divide-gray-10">
        <thead className="bg-gray-5">
          <tr>
            {props?.select &&
              <th scope="col" className={thTableCls}>
                Seleccionar
              </th>
            }
            {props.tableColumns.map((th) => (
              <th key={`TH${th.title}`} scope="col" className={`${thTableCls}`}>
                <span className="flex">
                  {th.title}
                  {th.ordenar !== undefined &&
                    customSort(th)
                  }
                </span>
              </th>
            ))}
            <th scope="col" className={thTableCls}>
              Acciones
            </th>
          </tr>
        </thead>
        <tbody className="bg-secondaryWhite divide-y divide-gray-10">
          {props?.dataTable?.data?.map((registro, index) => (
            <tr key={`${index}-TR-${registro.id}`} className={`hover:bg-gray-100`}>
              {props?.select &&
                <td className="px-6 py-2 whitespace-nowrap text-xs text-primaryFont">
                  <input
                    name="checkTable"
                    type="checkbox"
                    className="bg-primaryLightBlue h-5 w-5 rounded mr-2"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setRegistrosSeleccionados([
                          ...registrosSeleccionados,
                          registro,
                        ]);
                      } else {
                        let registros = registrosSeleccionados.filter(
                          (x) => x.id !== registro.id
                        );
                        setRegistrosSeleccionados(registros);
                      }

                    }}
                    onBlur={() => {
                      props.registrosSeleccionados(registrosSeleccionados);
                    }}
                  />
                </td>
              }
              {props.tableColumns.map((td, index) => compomentTD(index, registro, td))}
              <td className="px-3 py-1.2 whitespace-nowrap text-xs text-secondaryRed">

                {(props.showVer !== false) &&
                  <WithPermission permiso={`${props.permiso}-list`}>
                    <button
                      type="button"
                      onClick={() => {
                        props.handleVer(registro);
                      }}
                      className={`hover:bg-secondary ${btnOpCls} tooltip`}
                    >
                      <EyeIcon className="h-6 w-6 text-primaryFont hover:text-white" />
                      <span className="tooltiptext">Ver detalle</span>
                    </button>
                  </WithPermission>
                }

                {
                  (props.showEditar !== false) &&
                  <WithPermission permiso={`${props.permiso}-edit`}>
                    <button
                      type="button"
                      className={`hover:bg-primary ${btnOpCls} tooltip`}
                      onClick={() => {
                        props.handleEditar(registro);
                      }}
                    >
                      <PencilAltIcon className="h-6 w-6 text-primaryFont hover:text-white" />
                      <span className="tooltiptext">Editar</span>
                    </button>
                  </WithPermission>
                }

                {(props.showEliminar !== false) &&
                  <WithPermission permiso={`${props.permiso}-delete`}>
                    <button
                      type="button"
                      onClick={() => {
                        props.handleEliminar(registro);
                      }}
                      className={`hover:bg-red-200 ${btnOpCls} tooltip`}
                    >
                      <TrashIcon className="h-6 w-6  text-red-600 hover:text-red-600" />
                      <span className="tooltiptext">Eliminar</span>
                    </button>
                  </WithPermission>
                }

                {(props.showPagar === true) &&
                    <button
                      type="button"
                      onClick={() => {
                        props.handlePagar(registro);
                      }}
                      className={`hover:bg-secondary ${btnOpCls} tooltip`}
                    >
                      <CurrencyDollarIcon className="h-6 w-6 text-primaryFont hover:text-white" />
                      <span className="tooltiptext">Pagar</span>
                    </button>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {props.pagination && (
        <Paginacion
          next={next}
          prev={previous}
          count={count}
          fetchPage={props.fetchPage}
          params={props.params}
        />
      )}
    </div>
  );
};

export default CustomTable;
