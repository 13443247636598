/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customPost } from "../../../../utils/services";
import { Detalle } from "./detalle";
import ModalLarge from "../../../../components/common/ModalLarge";

import Spinner from "../../../../components/common/Spinner";
import CustomTable from "../../../../components/common/CustomTable";
import BreadCrumb from "../../../../components/common/BreadCrumb";

const tableColumns = [
  { title: "id", value: "id", tipo: "text" },
  { 
    title: "Cliente", 
    value: null, 
    values: [
      "nombre", 
      "apellido", 
    ], 
    tipo: "text" 
  },
  { title: "Celular", value: "celular", tipo: "text" },
  { title: "Correo", value: "correo", tipo: "text" },
  { title: "Nit", value: "nit", tipo: "text" },
  { title: "Empresa", value: "empresa.nombre", tipo: "text" },
];

export const TableReservaciones = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [order, setOrder] = useState("");
  const [detalle,setDetalle] = useState(false);
  const [registro, setRegistro] = useState(false);

  const handleVer = (registro) => {
    setDetalle(true);
    setRegistro(registro);
  };

  useEffect(() => {
    setTableLoading(true);
    customPost(urlFetch, 'reservacion/cliente/hoy', token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    })
  }, [urlFetch]);

  const pages = [
    { name: "Listado de reservaciones", href: "/", current: false },
  ];

  return (
    <div>
      <div className="flex flex-row justify-between items-end sm:flex mx-5 mt-2">
        <BreadCrumb navegacion={pages} />
      </div>
      <div className="shadow border border-gray-300 rounded-lg overflow-x-auto mx-5 mt-2">
        <CustomTable
          dataTable={dataTable}
          tableColumns={tableColumns}
          handleVer={handleVer}
          fetchPage={setUrlFech}
          pagination={true}
          showVer={true}
          showEditar={false}
          showEliminar={false}
          setOrder={setOrder}
          order={order}
          permiso="reserva"
        />
        {tableLoading && (
          <div className="mt-10 mb-10">
            <Spinner />
          </div>
        )}
      
         {detalle && (
          <ModalLarge
            title={`Detalle de reservacion`}
            open={detalle}
            closeModal={() => { }}
            cerrar={() => {
              setDetalle(false);
            }}
            children={
              <Detalle 
                registro={registro} 
                token={token}
              />
            }
          />
        )}
        
      </div>
    </div>
  );
};
