import Alert from "./Alert";
import Spinner from "./Spinner";

export default function Table(props) {
    return (
        <div className={`${props.classTable}`} >
            {
                (props.cargando) ?
                <Spinner /> :
                (props?.body?.length === 0) ?
                <Alert type="info" mensaje={props.empty || "No existen datos registrados"} /> :
                <table className={`w-full border  ${props.className}`}>
                    <thead className={`bg-gray-50 rounded-md ${props?.classHeader}`}>
                        <tr>
                            {
                                props?.headers?.map((item) => {
                                    return <th key={item} className={`px-1 py-1 text-left font-medium text-primaryFont text-xs ${props.classHeader}`}>{item}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-maivi-gray-10 ">
                        {
                            props?.body
                        }
                    </tbody>
                </table>
            }
        
        </div>

    );
}
