import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import reducers from "../reducers";
import storage from "redux-persist/lib/storage";

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

const persistConfig = {
  key: "root",
  storage
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    ...reducers
  }),
  initialState
);

let not_use_redux_extension = false
not_use_redux_extension = true;

const store = not_use_redux_extension
  ? createStore(persistedReducer, compose(applyMiddleware(...middlewares)))
  : createStore(
      persistedReducer,
      compose(
        applyMiddleware(...middlewares),
        window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    );

let persistor = persistStore(store);


store.asynReducers = {};

if (module.hot) {
  module.hot.accept("../reducers", () => {
    import("../reducers").then(reducerModule => {
      const createReducers = reducerModule.default;
      const nextReducers = createReducers(store.asyncReducers);

      store.replaceReducer(nextReducers);
    });
  });
}

export { store, persistor };
