/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customGet } from "../../../utils/services";
import { Detalle } from "./detalle";
import ModalLarge from "../../../components/common/ModalLarge";
import Spinner from "../../../components/common/Spinner";
import CustomTable from "../../../components/common/CustomTable";
import BreadCrumb from "../../../components/common/BreadCrumb";

const tableColumns = [
  { title: "Habitacion", value: "habitacion", tipo: "text" },
  { title: "Mesa", value: "mesa", tipo: "text" },
  { title: "Observacion", value: "observacion", tipo: "text" },
  { title: "Vendedor", value: "vendedor", tipo: "text" },
  { title: "Estado", value: "estado.nombre", tipo: "text" },
  { title: "Fecha creacion", value: "created_at", tipo: "datetime" },
];

export const TableOrdenesDelDia = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [order, setOrder] = useState("");
  const [detalle,setDetalle] = useState(false);
  const [registro, setRegistro] = useState(false);

  const handleVer = (registro) => {
    setDetalle(true);
    setRegistro(registro);
  };

  useEffect(() => {
    setTableLoading(true);
    customGet(urlFetch, 'ventas?dia=true', token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    })
  }, [urlFetch]);

  const pages = [
    { name: "Listado de ordenes del dia", href: "/", current: false },
  ];

  return (
    <div>
      <div className="flex flex-row justify-between items-end sm:flex mx-5 mt-2">
        <BreadCrumb navegacion={pages} />
      </div>
      <div className="shadow border border-gray-300 rounded-lg overflow-x-auto mx-5 mt-2">
        <CustomTable
          dataTable={dataTable}
          tableColumns={tableColumns} 
          handleVer={handleVer}
          fetchPage={setUrlFech}
          pagination={true}
          showVer={true}
          showEditar={false}
          showEliminar={false}
          setOrder={setOrder}
          order={order}
          permiso="compra"
        />
        {tableLoading && (
          <div className="mt-10 mb-10">
            <Spinner />
          </div>
        )}
         {detalle && (
          <ModalLarge
            title={`Detalle de la orden`}
            open={detalle}
            closeModal={() => { }}
            cerrar={() => {
              setDetalle(false);
            }}
            children={
              <Detalle registro={registro}/>
            }
          />
        )}
      </div>
    </div>
  );
};
