/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customGet } from "../../../../utils/services";
import { ReservasPendientes } from "./reservas";
import Spinner from "../../../../components/common/Spinner";
import CustomTable from "../../../../components/common/CustomTable";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import ModalLarge from "../../../../components/common/ModalLarge";

const tableColumns = [
  { title: "id", value: "id", tipo: "text" },
  {
    title: "Cliente",
    value: null,
    values: [
      "nombre",
      "apellido",
    ],
    tipo: "text"
  },
  { title: "Celular", value: "celular", tipo: "text" },
  { title: "Correo", value: "correo", tipo: "text" },
  { title: "Fecha creacion", value: "created_at", tipo: "datetime" },
];

export const ReservacionesPendientes = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [order, setOrder] = useState("");
  const [detalle, setDetalle] = useState(false);
  const [registro, setRegistro] = useState(false);
  const [tipoPagos,setTipoPagos] = useState([]);

  const handlePagar = (registro) => {
    setDetalle(true);
    setRegistro(registro);
  };

  useEffect(() => {
    setTableLoading(true);
    customGet(urlFetch,`reservacion/cliente/pendientes`, token).then((data) => {
      setDataTable({
        data: data
      });
      setTableLoading(false);
    })
  }, [urlFetch ]);

  useEffect(() => {
    customGet(undefined, `reservacion/cliente/pagos/create`, token).then((data) => {
      setTipoPagos(data.tipoPago)
    });
  }, []);

  const pages = [
    { name: "Reservaciones pendientes de pago", href: "/", current: false },
  ];

  return (
    <div>
      <div className="flex flex-row justify-between items-end mx-5 mt-2">
        <BreadCrumb navegacion={pages} />
        <div className="flex flex-row justify-center items-end ">
        </div>
      </div>
      <div className="shadow border border-gray-300 rounded-lg overflow-x-auto mx-5 mt-2">
        <CustomTable
          dataTable={dataTable}
          tableColumns={tableColumns}
          handlePagar={handlePagar}
          fetchPage={setUrlFech}
          pagination={false}
          showPagar={true}
          showEditar={false}
          showEliminar={false}
          showVer={false}
          setOrder={setOrder}
          order={order}
          permiso="reserva"
          scroll={true}
        />
        {tableLoading && (
          <div className="mt-10 mb-10">
            <Spinner />
          </div>
        )}

        {detalle && (
          <ModalLarge
            title={`Detalle de reservaciones`}
            open={detalle}
            closeModal={() => { }}
            cerrar={() => {
              setDetalle(false);
            }}
            children={
              <ReservasPendientes 
                registro={registro}
                token={token}
                tipoPagos={tipoPagos}
              />
            }
          />
        )}
      </div>
    </div>
  );
};
