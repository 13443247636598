/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customDelete, customGet, customPut } from "../../../utils/services";
import { Disclosure } from "@headlessui/react";
import { PagosOrden } from "./pagos";
import { EditarOrden } from "./editar";
import { AgregarMenu } from "./agregarMenu";
import ModalSmall from "../../../components/common/Modal";
import ModalMedium from "../../../components/common/Modal_medium";
import Table from "../../../components/common/Table";
import Alert from "../../../components/common/Alert";
import CheckBox from "../../../components/common/Check";
import BotonesConfirmar from "../../../components/common/BotonesConfirmar";
import toast from "react-hot-toast";
import moment from "moment";
import WithPermission from "../../../app/WithPermission";
import {
  CashIcon,
  ChevronUpIcon,
  PencilAltIcon,
  PrinterIcon,
  TrashIcon,
  XCircleIcon,
  PlusIcon,
  ClipboardCheckIcon
} from "@heroicons/react/solid";
import Input from "../../../components/common/Input";
import ButtonCancel from "../../../components/common/ButtonCancel";

export const DetalleOrden = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [orden, setOrden] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [registro, setRegistro] = useState("");
  const [cancelarOrden, setCancelarOrden] = useState(false);
  const [modalPagos, setModalPagos] = useState(false);
  const [editar, setEditar] = useState(false);
  const [agregarMenu, setAgregarMenu] = useState(false);
  const [impresionMultiple, setImpresionMultiple] = useState(false);
  const [impresionOrdenesMultiples, setImpresionOrdenesMultiples] = useState(
    []
  );

  const { REACT_APP_API_URL } = process.env;

  useEffect(() => {
    customGet(undefined, `ventas/${props.registro?.id}`, token).then((data) => {
      for (let reg of data.detalle) {
        reg.cargando = false;
      }

      setOrden(data);
      setImpresionOrdenesMultiples(data.detalle);
    });
  }, [refetch]);

  const eliminarPlato = (id) => {
    customDelete(id, "ventas/detalle/delete", token).then(() => {
      toast.success("plato eliminado con exito");
      setRefetch(true);

      setTimeout(() => {
        setRefetch(false);
      }, 1000);
    });
  };

  const delivered = (id) => {
    customPut(id, {}, "ventas/detalle/delivered", token).then(() => {
      toast.success("orden actualizada con exito");
      setRefetch(true);

      let element = document.getElementById(id);
      element.classList.remove("hidden");
      setTimeout(() => {
        setRefetch(false);
      }, 800);
    });
  };

  const totalOrden = (detalle) => {
    let total = 0;
    detalle?.forEach((element) => {
      total += Number(element?.precio) * element?.cantidad;
    });

    return total.toFixed(2);
  };

  const updateData = (rowIndex, columnID, value) => {
    setImpresionOrdenesMultiples((oldData) =>
      oldData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...oldData[rowIndex],
            [columnID]: value,
          };
        }
        return row;
      })
    );
  };

  return (
    <div className="grid grid-cols-12 gap-3 m-1 altura-menus xl:border-2 xl:p-3 lg:border-2 lg:p-3">
      <div className="col-span-12">
        <span className="ml-2 mt-2 inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
          Orden #{orden.id}
        </span>
        <div className="flex flex-row justify-between p-2">
          <div>
            <span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
              {orden.estado?.nombre}
            </span>
            {orden.habitacion && (
              <span className="mx-1 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                {`Habitacion ${orden.habitacion}`}
              </span>
            )}
            {orden.mesa && (
              <span className="mx-1 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                {`Mesa ${orden.mesa}`}
              </span>
            )}
          </div>
          <div>
            <span className="mx-1 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
              {moment(orden.created_at).format("HH:MM A")}
            </span>
          </div>
        </div>
        {orden.observacion && (
          <div className="mb-1 mx-2">
            <Alert type="info" mensaje={orden.observacion} />
          </div>
        )}
        <div className=" mx-2 rounded-lg bg-white">
          <Disclosure defaultOpen>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className="flex w-full justify-between rounded-lg bg-blue-50 px-3 py-3 text-left
                  text-sm font-medium text-primaryFont hover:bg-primaryOpacity"
                >
                  <span>Detalle de orden</span>
                  <ChevronUpIcon
                    className={`${open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-primary`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="pt-1 pb-1 text-sm text-gray-500">
                  {impresionMultiple ? (
                    <Table
                      headers={["", "Menu", "Cantidad", "Total"]}
                      body={impresionOrdenesMultiples?.map((item, index) => (
                        <tr
                          className="hover:bg-slate-100"
                          key={item?.menu?.nombre + index}
                        >
                          <td className="px-2 text-xs text-primaryFont">
                            <CheckBox
                              onChange={(e) => {
                                impresionOrdenesMultiples[index].checked = e.target.checked;
                                updateData(
                                  index,
                                  "checked",
                                  e.target.checked
                                );
                              }}
                            />
                          </td>
                          <td className="px-2 text-xs text-primaryFont">
                            {item?.menu?.nombre}
                          </td>
                          <td className="px-2 py-1.5 text-xs text-primaryFont">
                            <Input
                              value={item?.cantidad}
                              type="number"
                              minimo={1}
                              maximo={orden.detalle[index]?.cantidad}
                              onChange={(e) => {
                                if (
                                  Number(e.target.value) >
                                  orden.detalle[index].cantidad
                                ) {
                                  toast.error(
                                    "La cantidad no puede ser mayor a la de la orden"
                                  );
                                } else {
                                  updateData(
                                    index,
                                    "cantidad",
                                    Number(e.target.value)
                                  );
                                }
                              }}
                            />
                          </td>
                          <td className="px-2 text-xs text-primaryFont">
                            Q{Number(item?.menu?.precio) * item?.cantidad}
                          </td>
                        </tr>
                      ))}
                    />
                  ) : (
                    <Table
                      headers={["Menu", "Cantidad", "Total", ""]}
                      body={orden?.detalle?.map((item, index) => (
                        <tr
                          className="hover:bg-slate-100"
                          key={item?.menu?.nombre + index}
                        >
                          <td className="px-2 text-xs text-primaryFont">
                            {item?.menu?.nombre}
                          </td>
                          <td className="px-2 py-1.5 text-xs text-primaryFont">
                            {item?.cantidad}
                          </td>
                          <td className="px-2 text-xs text-primaryFont">
                            Q{Number(item?.menu?.precio) * item?.cantidad}
                          </td>
                          <td className="px-2 py-1.5 text-xs text-primaryFont flex flex-row">
                            <div id={item.id}>
                              <CheckBox
                                checked={item.delivered_at ? true : false}
                                onChange={(e) => {
                                  item.cargando = true;
                                  if (e.target.checked) {
                                    let element = document.getElementById(
                                      item.id
                                    );
                                    element.classList.add("hidden");
                                    delivered(item.id);
                                  }
                                }}
                              />
                            </div>
                            <WithPermission permiso={`orden-detalle-eliminar`}>
                              <button
                                type="button"
                                onClick={() => {
                                  eliminarPlato(item.id);
                                }}
                                className={`hover:bg-red-200 hover:rounded-lg p-1 tooltip`}
                              >
                                <TrashIcon className="h-6 w-6  text-red-600 hover:text-red-600" />
                                <span className="tooltiptext">Eliminar</span>
                              </button>
                            </WithPermission>
                          </td>
                        </tr>
                      ))}
                    />
                  )}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
        <div className="flex flex-col xl:flex-row justify-between items-center text-center px-3 mb-3 mt-2">
          <span className="bg-blue-100 text-primary font-bold p-1 rounded-lg px-2 mb-2">
            Total: Q{totalOrden(orden.detalle)}
          </span>
          <div className="flex flex-row mb-2">
            <div
              className="tooltip-orden p-1 border bg-blue-100 w-9 mx-1 rounded-2xl flex justify-center"
              onClick={() => {
                setRegistro(orden);
                setAgregarMenu(true);
              }}
            >
              <PlusIcon className="text-primary  h-6 w-6" aria-hidden="true" />
              <span className="tooltiptext">Agregar menu</span>
            </div>
            <div
              className="tooltip-orden p-1 border bg-blue-100 w-9 mx-1 rounded-2xl flex justify-center"
              onClick={() => {
                setRegistro(orden);
                setEditar(true);
              }}
            >
              <PencilAltIcon
                className="text-primary  h-6 w-6"
                aria-hidden="true"
              />
              <span className="tooltiptext">Editar</span>
            </div>
            <div
              className="tooltip-orden p-1 border bg-blue-100 w-9 mx-1 rounded-2xl flex justify-center"
              onClick={() => {
                setModalPagos(true);
                setRegistro(orden);
              }}
            >
              <CashIcon className="text-primary  h-6 w-6" aria-hidden="true" />
              <span className="tooltiptext">Pagar</span>
            </div>
            <div
              className="tooltip-orden p-1 border bg-blue-100 w-9 mx-1 rounded-2xl flex justify-center"
              onClick={() => {
                const ids = [];
                const qty = [];

                for (const item of impresionOrdenesMultiples) {
                  if (item.checked) {
                    ids.push(item.id);
                    qty.push(item.cantidad);
                  }
                }

                if (ids.length === 0 && impresionMultiple) {
                  toast.error("Debe seleccionar al menos un menu");
                } else {
                  impresionMultiple ?
                    window.open(
                      `${REACT_APP_API_URL}/imprimir-orden/${orden.id}/detalle?ids=${ids.toString()}&qty=${qty.toString()}`,
                      "_blank"
                    ) :

                    window.open(
                      `${REACT_APP_API_URL}/imprimir-orden/${orden.id}`,
                      "_blank"
                    )
                }
              }}
            >
              <PrinterIcon
                className="text-primary  h-6 w-6"
                aria-hidden="true"
              />
              <span className="tooltiptext">Imprimir</span>
            </div>

            {impresionMultiple ? (
              <ButtonCancel
                text="Cancelar"
                onClick={() => {
                  setImpresionMultiple(!impresionMultiple);
                }}
              />
            ) : (

              <div
                className="tooltip-orden p-1 border bg-blue-100 w-9 mx-1 rounded-2xl flex justify-center"
                onClick={() => { setImpresionMultiple(!impresionMultiple); }}
              >
                <ClipboardCheckIcon
                  className="text-primary  h-6 w-6"
                  aria-hidden="true"
                />
                <span className="tooltiptext">Seleccionar Items</span>
              </div>
            )}

            <WithPermission permiso={`orden-cancelar`}>
              <div
                className="tooltip-orden p-1 border bg-red-100 w-9 mx-1 rounded-2xl flex justify-center"
                onClick={() => {
                  setCancelarOrden(true);
                  setRegistro(orden);
                }}
              >
                <XCircleIcon
                  className="text-red-600  h-6 w-6"
                  aria-hidden="true"
                />
                <span className="tooltiptext">Cancelar</span>
              </div>
            </WithPermission>
          </div>
        </div>
      </div>

      {cancelarOrden && (
        <ModalSmall
          title={`Esta seguro que desae anular la orden # ${registro.id}`}
          open={cancelarOrden}
          closeModal={() => { }}
          cerrar={() => {
            setCancelarOrden(false);
          }}
          children={
            <div>
              <BotonesConfirmar
                aceptar={() => {
                  customDelete(registro.id, `ventas`, token).then(() => {
                    toast.success("orden cancelada con exito");
                    setRefetch(true);
                    setTimeout(() => {
                      setRefetch(false);
                      setCancelarOrden(false);
                    }, 1000);
                  });
                }}
                cancelar={() => {
                  setCancelarOrden(false);
                }}
              />
            </div>
          }
        />
      )}

      {modalPagos && (
        <ModalMedium
          title={`Pagos orden # ${registro.id}`}
          open={modalPagos}
          closeModal={() => { }}
          cerrar={() => {
            setModalPagos(false);
          }}
          children={
            <PagosOrden
              token={token}
              tipoPagos={props.tipoPagos}
              registro={registro}
              setRefetch={setRefetch}
              setModalPagos={setModalPagos}
            />
          }
        />
      )}

      {editar && (
        <ModalSmall
          title={`Editar orden #${registro.id}`}
          open={editar}
          closeModal={() => { }}
          cerrar={() => {
            setEditar(false);
          }}
          children={
            <EditarOrden
              token={token}
              registro={registro}
              setRefetch={setRefetch}
              setEditar={setEditar}
            />
          }
        />
      )}

      {agregarMenu && (
        <ModalSmall
          title={`Agregar menu a orden #${registro.id}`}
          open={agregarMenu}
          closeModal={() => { }}
          cerrar={() => {
            setAgregarMenu(false);
          }}
          children={
            <AgregarMenu
              token={token}
              registro={registro}
              setRefetch={setRefetch}
              setAgregarMenu={setAgregarMenu}
            />
          }
        />
      )}
    </div>
  );
};
