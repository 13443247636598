import Contenedor from '../../components/common/Contenedor';


export const ModuloReportesBi = () => {
  return (
    <Contenedor title="Modulo de reportes bi">
      <iframe title="HaciendaDelPedregal" width="100%" height="800px" style={{height: "75vh"}} src="https://app.powerbi.com/view?r=eyJrIjoiYzNiMmM5ODYtMTNhNy00NjQ0LWFhZWMtNTYwOTlkNDc0YjRjIiwidCI6IjA3NWU0ZTdlLTQyZTUtNDA2ZC1iOGM2LTEyNmIzMGI5MjM0NyJ9" frameborder="0" allowFullScreen="true"></iframe>
    </Contenedor>
  )
}
