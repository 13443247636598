import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const customGet = async (urlPage, endpoint, token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/${endpoint}` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const customGetServicios = async (urlPage, endpoint, token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/${endpoint}` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const customPost = async(body,endpoint, token) => {
    try {
        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/${endpoint}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const customPut = async(id,body,endpoint, token) => {
    try {
        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/${endpoint}/${id}`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const customDelete = async(id,endpoint, token) => {
    try {
        let result = axios({
            method: 'DELETE',
            url: `${REACT_APP_API_URL}/${endpoint}/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putComentario = async(id,body,endpoint, token) => {
    try {
        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/${endpoint}/${id}/comentarios`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};