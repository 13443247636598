import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import Spinner from "../../../components/common/Spinner";
import toast from "react-hot-toast";
import { store } from "../../../app/redux/store/store";
import { saveLogin } from "../../../app/redux/actions/login";
import { menuActive } from "../../../app/redux/actions/menu";
import { saveMunicipios } from "../../../app/redux/actions/municipios";
import { saveDepartamentos } from "../../../app/redux/actions/departamentos";
import { login } from "./services";
import { Formik } from "formik";
import { useState } from "react";
import { customGetServicios } from "../../../utils/services";
import { IconHotel } from "../../../components/common/icons";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  email: Yup.string()
    .required("email es requerido"),
  password: Yup.string().required("contraseña es requerido")
});

export default function Login() {
  const [cargando,setCargando] = useState(false);
  return (
    <div className="min-h-screen flex flex-col justify-center rounded-md bg-secondary">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          setCargando(true);
          login(values).then(async(data) => {
            if (data.status >= 200 && data.status <= 210) {
              store.dispatch(saveLogin({ ...data.data}));

              await customGetServicios(undefined,'municipios',data.data.token).then((mun)=>{
                store.dispatch(saveMunicipios({...mun}));
              });

              await customGetServicios(undefined,'departamentos',data.data.token).then((dep)=>{
                store.dispatch(saveDepartamentos({...dep}));
              });
              
              toast.success("ingreso exitoso");
              setCargando(false);

              if(data.data.userData.module_hotel === 1 && data.data.userData.module_restaurante === 1){
                window.location.reload();
              } else if(data.data.userData.module_hotel === 1 && data.data.userData.module_restaurante === 0){
                store.dispatch(menuActive('hotel'));
                window.location.href ='/hotel';
              }

              else if(data.data.userData.module_hotel === 0 && data.data.userData.module_restaurante === 1){
                store.dispatch(menuActive('restaurante'));
                window.location.href = '/restaurante';
              }
              
            }
          }).catch(function (error) {
            if (error.response.status >= 400 && error.response.status <= 450) {
              toast.error(error.response.data.error);
            }
            if (error.response.status >= 500) {
              toast.error("Ocurrio un error comuniquese con el administrador");
            }

            setCargando(false);
          });
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-row justify-center items-center">
              <IconHotel className="mt-6 text-center w-14 h-14 xl:h-20 xl:w-20 text-white hover:text-white " />
            </div>

            <div className="mx-auto">
              <h2 className="mt-3 text-center text-xl lg:text-3xl xl:text-3xl font-extrabold text-white">Hacienda del pedregal</h2>
            </div>
            <div className="p-3 xl:p-0 lg:p-0 md:p-0 max-w-md mt-2 mx-auto  hover:shadow-2xl">
              <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10 space-y-4">
                <div className="mt-1">
                  <Input
                    id="email"
                    label="Correo"
                    name="email"
                    type="email"
                    validacion={true}
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-1">
                  <Input
                    id="password"
                    label="Contraseña"
                    name="password"
                    type="password"
                    value={values.password}
                    validacion={true}
                    onChange={handleChange}
                  />
                </div>
                <Button
                    type="submit"
                    text="Iniciar sesion"
                    onClick={handleSubmit}
                  />
                {cargando && <Spinner />}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}