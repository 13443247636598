/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { customGet, customPut } from "../../../../utils/services";
import { useSelector } from "react-redux";
import { TrashIcon } from "@heroicons/react/outline";
import Table from "../../../../components/common/Table";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Spinner from "../../../../components/common/Spinner";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    name: Yup.string()
        .required("nombre requerido")
        .max(255, "Máximo 100 caracteres"),
});

export const Editar = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const animatedComponents = makeAnimated();
    const [rolSeleccionado, setRolSeleccionado] = useState([]);
    const [permisos, setPermisos] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [refetch, setRefetch] = useState(false);
    let listadoRoles = [];

    useEffect(() => {
        setCargando(true);
        customGet(undefined, `roles/${props.registro.id}`, token).then((data) => {
            setPermisos(data?.permissions);
            setCargando(false);
        });
    }, [refetch]);

    props.catalogos.permission?.forEach((item) => {
        let reg = {
            value: item.id,
            label: item?.name,
        };
        listadoRoles.push(reg);
    });

    const changeSelectRoles = (e) => {
        setRolSeleccionado(e.value);
    };

    const actualizar = async (values,rol) => {
        let arreglo = [];
        let existe = false;

        permisos.forEach(element => {
            arreglo.push(element.id);

            if (element.id === rolSeleccionado) {
                existe = true;
            }
        });

        arreglo.push(rol);
        values.permission = arreglo;

        if (existe) {
            toast.error("el rol seleccionado ya fue agregado");
        } else {
            await customPut(props.registro.id, values, 'roles', token)
                .then(async (data) => {
                    if (data?.status >= 200 && data?.status <= 210) {
                        toast.success(data?.data?.msg);
                        setRefetch(true);
                        setTimeout(() => {
                            setRefetch(false);
                        }, 300);
                    }
                })
                .catch(function (error) {
                    if (error.response.status >= 400 && error.response.status <= 450) {
                        toast.error(error.response.data.error);
                    } else if (error.response.status >= 500) {
                        toast.error("Error interno comuniquese con el administrador");
                    }
                });
        }
    }

    const eliminarRol = async (values,rol) => {
        let arreglo = [];

        permisos.filter(x => x.id !== rol).forEach(element => {
            arreglo.push(element.id);
        });

        values.permission = arreglo;

        await customPut(props.registro.id, values, 'roles', token)
                .then(async (data) => {
                    if (data?.status >= 200 && data?.status <= 210) {
                        toast.success(data?.data?.msg);
                        setRefetch(true);
                        setTimeout(() => {
                            setRefetch(false);
                        }, 300);
                    }
                })
                .catch(function (error) {
                    if (error.response.status >= 400 && error.response.status <= 450) {
                        toast.error(error.response.data.error);
                    } else if (error.response.status >= 500) {
                        toast.error("Error interno comuniquese con el administrador");
                    }
                });
    }

    return (
        <Formik
            initialValues={{
                name: props.registro.name,
                permission: []
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                actualizar(values,rolSeleccionado);
            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-6 border p-3 rounded-lg hover:shadow-lg">
                        <div className="mb-3">
                            <Input
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                validacion={true}
                                label="Nombre"
                            />
                        </div>
                        <Select
                            isMulti={false}
                            placeholder="Seleccionar"
                            components={animatedComponents}
                            name="servicios"
                            className="basic-multi-select text-sm w-full"
                            classNamePrefix="seleccione"
                            options={listadoRoles}
                            onChange={changeSelectRoles}
                            maxMenuHeight={240}
                        />
                        <div className="py-3 text-right">
                            <Button
                                type="submit"
                                onClick={handleSubmit}
                                disabled={(isSubmitting || props.mostrar)}
                                text={"Actualizar"}
                            />
                        </div>
                        {isSubmitting && <Spinner />}
                    </div>
                    <div className="col-span-6 border p-3 rounded-lg hover:shadow-lg">
                        <Table
                            classTable="h-96 overflow-auto"
                            cargando={cargando}
                            headers={[
                                'Permiso',
                                'Eliminar'
                            ]}
                            body={permisos?.map((item, index) => (
                                <tr key={`res-${item.id}-${index}`}
                                    className="hover:bg-gray-100 cursor-pointer"
                                >
                                    <td className="px-2 text-xs text-primaryFont">
                                        {item?.name}
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setRolSeleccionado(item.id);
                                                eliminarRol(values,item.id);
                                            }}
                                            className={`hover:bg-red-200  p-1 rounded-lg tooltip`}
                                        >
                                            <TrashIcon className="h-6 w-6  text-red-600 hover:text-red-600" />
                                            <span className="tooltiptext">Eliminar</span>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        />
                    </div>
                </div>
            )}
        </Formik>
    );
};
