export const nombreMes = (mes) => {
  let nombre = "";

  if (mes === 1) {
    nombre = "Enero";
  } else if (mes === 2) {
    nombre = "Febrero";
  } else if (mes === 3) {
    nombre = "Marzo";
  } else if (mes === 4) {
    nombre = "Abril";
  } else if (mes === 5) {
    nombre = "Mayo";
  } else if (mes === 6) {
    nombre = "Junio";
  } else if (mes === 7) {
    nombre = "Julio";
  } else if (mes === 8) {
    nombre = "Agosto";
  } else if (mes === 9) {
    nombre = "Septiembre";
  } else if (mes === 10) {
    nombre = "Octubre";
  } else if (mes === 11) {
    nombre = "Noviembre";
  } else if (mes === 12) {
    nombre = "Diciembre";
  }

  return nombre;
};

export const nombreDia = (year, mes, dia) => {
  let days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
  let d = new Date(`${year}-${mes}-${dia}`);
  return days[d.getDay()];
}
export const  backgroundColor = [
  "#ff6384",
  "#ff9f40",
  "#F4D03F",
  "#1E8449",
  "#2ECC71",
  "#4bc0c0",
  "#36a2eb",
  "#9966ff",
  "#9C27B0",
  "#ABB2B9",
];

export const borderColor = [
  "#ffff",
  "#ffff",
  "#ffff",
  "#ffff",
  "#ffff",
  "#ffff",
  "#ffff",
  "#ffff",
  "#ffff",
  "#ffff",
];