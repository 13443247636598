
import { store } from "../app/redux/store/store";
import { menuActive } from "../app/redux/actions/menu";
import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux";
import WithPermission from "../app/WithPermission";

export default function Home() {
    const history = useHistory();
    const moduleHotel = useSelector((state) => state.loginReducer.data.userData.module_hotel);
    const moduleRestaurante = useSelector((state) => state.loginReducer.data.userData.module_restaurante);

    return (
        <div className="bg-white md:grid md:place-items-center mt-10">
            <div className="flex flex-col xl:flex-row lg:flex-row md:flex-row justify-center items-center">
                {
                    (moduleHotel === 1) &&
                    <div className="w-72 mx-2 mb-3 border rounded-lg hover:shadow-lg flex justify-center cursor-pointer hover:bg-primary bg-primaryOpacity text-primaryFont hover:text-white" onClick={() => {
                        store.dispatch(menuActive('hotel'));
                        history.push(`/hotel`);
                    }}>
                        <h1 className="text-4xl p-10 font-extrabold text-maivi-primaryLightBlue tracking-tight">Hotel</h1>
                    </div>
                }
                {
                    (moduleRestaurante === 1) &&
                    <div className="w-72 mx-2  mb-3 border rounded-lg hover:shadow-lg flex justify-center cursor-pointer hover:bg-primary bg-primaryOpacity text-primaryFont hover:text-white" onClick={() => {
                        store.dispatch(menuActive('restaurante'));
                        history.push(`/restaurante`);
                    }}>
                        <h1 className="text-4xl p-10 font-extrabold text-maivi-primaryLightBlue tracking-tight">Restaurante</h1>
                    </div>
                }

                <WithPermission permiso="report-earnings">
                    <div className="w-72 mx-2  mb-3 border rounded-lg hover:shadow-lg flex justify-center cursor-pointer hover:bg-primary bg-primaryOpacity text-primaryFont hover:text-white" onClick={() => {
                        history.push(`/reportes-bi`);
                    }}>
                        <h1 className="text-4xl p-10 font-extrabold text-maivi-primaryLightBlue tracking-tight">Reportes</h1>
                    </div>
                </WithPermission>


            </div>
        </div>
    )
}