import { Link } from "react-router-dom";
import {
  ListIcons
} from '../../../components/common/icons';
import {
  ScaleIcon,
  NewspaperIcon
} from '@heroicons/react/outline';

const menu = [
  {
    name: "Categorias",
    href: "/restaurante/catalogos/categoria",
    icon: ListIcons,
    permiso : ""
  },
  {
    name: "Unidades medida",
    href: "/restaurante/catalogos/unidades-medida",
    icon: ScaleIcon,
    permiso : ""
  },
  {
    name: "Marcas",
    href: "/restaurante/catalogos/marcas",
    icon: NewspaperIcon,
    permiso : ""
  },

];

export const Menu = () => {
  return (
   <div className="">
      <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
        {menu.map((item, index) => (
          // <WithPermission permiso={item.permiso}>
            <Link key={`${index}-mis`} to={item.href}>
            <li className="col-span-1 flex shadow-lg rounded-md h-22">
              <div className="flex-shrink-0 flex items-center justify-center w-8 text-white text-sm font-medium rounded-l-md bg-primary"></div>
              <div className="h-24 flex-1 flex flex-col items-center justify-between border-t border-r border-b border-maivi-primaryLightBlue bg-white rounded-r-md whitespace-pre-line break-word hover:bg-primaryOpacity">
                <div className="flex flex-col items-center text-sm truncate my-auto">
                <item.icon className="h-6 w-6 xl:h-8 xl:w-8 lg:h-8 lg:w-8 text-primary" />
                  <p className="text-base xl:text-sm lg:text-base md:text-base sm:text-xs text-gray-900 text-center font-medium">
                    {item.name}
                  </p>
                </div>
              </div>
            </li>
          </Link>
          //</WithPermission>
        ))}
      </ul>
    </div>
  );
};
